<!--
 * @Author: Daokai Huo
 * @Date: 2021-10-19 19:28:06
 * @LastEditTime: 2021-11-01 19:59:29
 * @Description: 
-->
<template>
  <el-form
    ref="ykcForm"
    @validate="checkField"
    class="ykc-form"
    :model="model"
    v-bind="Object.assign({}, $props, $attrs)">
    <slot></slot>
  </el-form>
</template>
<script>
  export default {
    name: 'YkcForm',
    props: {
      // 表单数据对象
      model: Object,
      rules: Object,
      // 表单域标签的位置
      labelPosition: {
        type: String,
        default: 'top',
      },
      // 表单域标签的宽度
      labelWidth: String,
      // 是否禁用该表单内的所有组件
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    provide() {
      return {
        YkcForm: this,
      };
    },
    methods: {
      /**
       * 对整个表单进行校验的方法，参数为一个回调函数。
       */
      validate(callback) {
        this.$refs.ykcForm.validate(valid => {
          callback(valid);
        });
      },
      /**
       * 对部分表单字段进行校验的方法
       */
      validateField(prop, cb) {
        this.$refs.ykcForm.validateField(prop, cb);
      },
      /**
       * 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
       */
      resetFields() {
        this.$refs.ykcForm.resetFields();
      },
      /**
       * 移除表单项的校验结果。传入待移除的表单项的 prop 属性组成的数组，如不传则移除整个表单的校验结果
       */
      clearValidate(props) {
        this.$refs.ykcForm.clearValidate(props);
      },
      /**
       * 被校验的表单项 prop 值，校验是否通过，错误消息
       */
      checkField(prop, state, message) {
        this.checkprop = { prop, state, message };
        this.$emit('checkField', prop, state, message);
      },
    },
  };
</script>
