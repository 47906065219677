<!--
 * @Descripttion: icon组件
 * @Author: 汪佳彪
 * @date: 2021-10-09 10:37
-->
<template>
  <i :class="iconClass" @click="handleClick"></i>
</template>

<script>
  export default {
    name: 'YkcIcon',
    props: {
      name: {
        type: String,
      },
      // 前缀
      prefix: {
        type: String,
        default: 'ykc-icon-',
      },
    },
    computed: {
      iconClass() {
        const { prefix, name } = this;
        return name ? `${prefix}${name}` : '';
      },
    },
    methods: {
      /**
       * @description 点击事件处理
       */
      handleClick(event) {
        if (this.name) {
          this.$emit('click', event);
        }
      },
    },
  };
</script>

<style lang="scss">
  // @if $NODE_ENV == 'development' {
  //   @import './prod.css';
  // } @else {
  //   @import './prod.css';
  // }
  @import './prod.css';

  // [class*=' ykc-icon-'],
  // [class^='ykc-icon-'] {
  //   /* use !important to prevent issues with browser extensions that change fonts */
  //   font-family: 'ykc_font_family' !important;
  //   font-style: normal;
  //   font-variant: normal;
  //   text-transform: none;
  //   line-height: 1;
  //   vertical-align: baseline;
  //   display: inline-block;
  //   /* Better Font Rendering =========== */
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
  // }
</style>
