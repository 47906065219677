import { dict } from '../service/apis';
// import _dictionary from '../utils/dictionary';

// eslint-disable-next-line no-unused-vars
const getDicByKeys = (dictionary, keys) => {
  // const dic = {};
  // keys.forEach(key => {
  //   dic[key] = dictionary[key].map(o => {
  //     o.id = o.dictKey;
  //     o.name = o.dictValue;
  //     return o;
  //   });
  // });
  // return dic;
};
const actions = {
  /** 根据字典Key列表获取对应的数据字典数据 */
  getDictionaryByKeys(store, keys) {
    return new Promise(resolve => {
      // 过滤掉重复查询过的字典key
      // const keyList = keys.filter(key => !store.state.dictionary[key]);
      // if (keyList.length > 0) {
      //   // 根据未重复查询的字典key查询字典信息
      dict.getDictionaryByKeys(keys).then(res => {
        res.body.forEach(item => {
          store.commit('setDicByKey', { key: item.dictType, value: item.items });
        });
        resolve(getDicByKeys(store.state.dictionary, keys));
      });
      // } else {
      //   resolve(getDicByKeys(store.state.dictionary, keys));
      // }
    });
  },
  setDenyList({ commit }, payload) {
    commit('setDenyListCurrentStationIds', payload);
  },
};
export default actions;
