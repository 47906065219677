<!--
 * @Author: Daokai Huo
 * @Date: 2021-10-19 19:28:17
 * @LastEditTime: 2021-11-02 15:28:28
 * @Description: 
-->
<template>
  <div class="ykc-form-item">
    <el-form-item ref="ykcFormItem" v-bind="$props">
      <!-- 默认 slot-->
      <slot></slot>
      <template slot="label">
        <template v-if="isAskShow || labelTip">
          {{ label }}
          <span v-if="isAskShow" class="askShow" :title="isAskShow" @click="onAskClick">?</span>
          <span
            v-if="labelTip"
            class="label-tips-msg"
            :class="isAskShow && 'isAskShow'"
            v-text="labelTip"></span>
        </template>
        <template v-else>
          <slot name="label"></slot>
        </template>
      </template>
    </el-form-item>
    <p v-if="bottomTip" class="tips-msg" v-text="bottomTip"></p>
  </div>
</template>

<script>
  export default {
    name: 'YkcFormItem',
    props: {
      // 表单域 model 字段，在使用 validate 该属性是必填的
      prop: String,
      // 标签文本
      label: String,
      // 表单域标签的的宽度
      labelWidth: String,
      // 是否必填，如不设置，则会根据校验规则自动生成
      required: {
        type: Boolean,
        default: false,
      },
      // 表单验证规则
      rules: [Object, Array],
      // 表单域验证错误信息, 设置该值会使表单验证状态变为error，并显示该错误信息
      error: String,
      // 是否显示校验错误信息
      showMessage: {
        type: Boolean,
        default: true,
      },
      // 每一列表单下的文字，如温馨提示(文件上传使用底部提示)
      bottomTip: String,
      // 是否显示问号
      isAskShow: [String, Boolean],
      labelTip: String,
    },
    methods: {
      /**
       * 对该表单项进行重置，将其值重置为初始值并移除校验结果
       */
      resetField() {
        this.$refs.ykcFormItem.resetField();
      },
      /**
       * 移除该表单项的校验结果
       */
      clearValidate() {
        this.$refs.ykcFormItem.clearValidate();
      },
      /**
       * 点击问号图标
       */
      onAskClick() {
        this.$emit('askClick');
      },
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
