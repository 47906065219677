<template>
  <svg>
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
  export default {
    props: ['name'],
    computed: {
      iconName() {
        return `#icon-${this.name}`;
      },
    },
  };
</script>

<style lang="scss"></style>
