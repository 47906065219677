import Component, { getThemeInstances } from './src/main.vue';

export function setTheme(target, props) {
  const instances = getThemeInstances();
  const vm = instances.find(item => [item, item.target].includes(target));
  if (vm) {
    vm.setTheme(props);
  }
}

Component.install = function install(_Vue) {
  _Vue.component(Component.name, Component);
  _Vue.prototype.$setYkcTheme = setTheme;
};

export default Component;
