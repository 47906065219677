// import { version } from '../package.json';
import { Message } from 'element-ui';
// import YKcMessage from './message';
import { setTheme } from './theme';
import YkcNotification from './notification';
import YkcWarningTips from './warning-tips';

const requireWithCtx = require.context(
  './',
  true,
  // 匹配 ./xxx/index.js 或 ./xxx-yyy/index.js，但排除 ./template(xxx)/index.js
  /^\.\/(?!(((template)|(styles)|(utils)).*))(\w+(-\w+)*)\/index\.js$/
);

const paths = requireWithCtx.keys();

const componentsMap = {};

const componentsList = paths.map(path => {
  const currentComponent = requireWithCtx(path).default;
  // 函数式导出时，要对name特殊处理
  // 因为打包构建时，函数名会被改写
  const name = path
    // 提取目录名
    .match(/^\.\/(.*)\/index\.js$/)[1]
    // 羊肉串转驼峰
    .replace(/-[a-z]/g, s => s.replace(/-/, '').toUpperCase())
    // 首字母大写
    .replace(/\b(\w)/, s => s.toUpperCase());
  componentsMap[name] = currentComponent;
  return currentComponent;
});

const install = _Vue => {
  componentsList
    // 过滤掉函数导出
    .filter(item => typeof item !== 'function')
    .forEach(component => {
      _Vue.component(component.name, component);
    });
  // 主题操作
  _Vue.prototype.$setYkcTheme = setTheme;
  _Vue.prototype.$message = Message;
  _Vue.prototype.$notify = YkcNotification;
  _Vue.prototype.$tips = YkcWarningTips;
};

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install);
}

export default {
  ...componentsMap,
  install,
  // version,
};
