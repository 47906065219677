/**
 *@author huhongyun
 *@date 2021/10/
 *@component 左侧通知组件
 */
import Vue from 'vue';
import { Notification } from 'element-ui';
import { merge, isVNode } from '@/components/ykc-ui/notification/src/helper';

import {
  frameworkHeaderHeight,
  frameworkHeaderHeightNormal,
  sidebarWidth,
  sidebarWidthNormal,
} from './index.scss';

let instance;
const instances = [];
let seed = 1;
const RADIX = 10;
const OFFSET = 10;
const VERTICAL_OFFSET = 4;

/**
 * @param {Vue} instance - 当前的组件实例
 * @param {Object} opts - 用户传递的参数
 * @desc 生成按钮组
 * */
// eslint-disable-next-line no-shadow
const generateBtnGroup = (instance, opts) => {
  const btnGroup = document.createElement('div', {});
  btnGroup.setAttribute('class', 'ykc-notification-btn-group');
  const cancelBtn = document.createElement('div');
  cancelBtn.setAttribute('class', 'ykc-notification-btn-group-cancel');
  cancelBtn.innerText = '取消';
  cancelBtn.addEventListener('click', () => {
    if (typeof opts.onCancel === 'function') {
      opts.onCancel(instance.close);
      return;
    }
    instance.close();
  });
  const ensureBtn = document.createElement('div');
  ensureBtn.setAttribute('class', 'ykc-notification-btn-group-ensure');
  ensureBtn.innerText = '确认';
  ensureBtn.addEventListener('click', () => {
    if (typeof opts.onSuccess === 'function') {
      opts.onSuccess(instance.close);
      return;
    }
    instance.close();
  });
  btnGroup.appendChild(cancelBtn);
  btnGroup.appendChild(ensureBtn);
  const safeContent = instance.$el.querySelector('.el-notification__group');
  safeContent.appendChild(btnGroup);
};

const YkcNotification = opts => {
  if (Vue.prototype.$isServer) return;
  opts = merge({}, opts);
  const userOnClose = opts.onClose;
  const id = `notification_${seed++}`;
  opts.position = opts.position || 'top-left';
  // eslint-disable-next-line func-names
  opts.onClose = function () {
    YkcNotification.close(id, userOnClose);
  };
  const cw = document.body.clientWidth;
  opts.customClass = `${'ykc-notification ykc-notification-'}${opts.type}`;
  // 初始化默认偏移量
  if (!opts.offset) {
    if (cw > 1366) {
      opts.offset = parseInt(frameworkHeaderHeight, RADIX) + OFFSET;
    } else {
      opts.offset = parseInt(frameworkHeaderHeightNormal, RADIX) + OFFSET;
    }
  }
  instance = Notification(opts);
  // 改变必要的容器样式
  if (cw > 1366) {
    instance.$el.style.left = `${parseInt(sidebarWidth, RADIX) + OFFSET}px`;
  } else {
    instance.$el.style.left = `${parseInt(sidebarWidthNormal, RADIX) + OFFSET}PX`;
  }
  // 增加元素的高度,原偏移高度为 16
  const warningTips = document.body.querySelectorAll('.ykc-notification');
  instance.verticalOffset += VERTICAL_OFFSET * (warningTips.length - 1);

  // 如果要显示取消和确认按钮
  if (opts.showBtnGroup) {
    generateBtnGroup(instance, opts);
  }
  instances.push(instance);
  // eslint-disable-next-line consistent-return
  return instance;
};
['success', 'warning', 'info', 'error'].forEach(type => {
  YkcNotification[type] = options => {
    if (typeof options === 'string' || isVNode(options)) {
      options = {
        message: options,
      };
      // eslint-disable-next-line default-case
      switch (type) {
        case 'error':
          options.title = '错误';
          break;
        case 'info':
          options.title = '消息';
          break;
        case 'warning':
          options.title = '警告';
          break;
        case 'success':
          options.title = '成功';
          break;
      }
    }
    options.type = type;
    return YkcNotification(options);
  };
});
// eslint-disable-next-line func-names
YkcNotification.close = function (id, userOnClose) {
  let index = -1;
  // eslint-disable-next-line no-shadow
  const instance = instances.filter((instance, i) => {
    if (instance.id === id) {
      index = i;
      return true;
    }
    return false;
  })[0];
  if (!instance) return;

  if (typeof userOnClose === 'function') {
    userOnClose(instance);
  }
  instances.splice(index, 1);
};

// eslint-disable-next-line func-names
YkcNotification.closeAll = function () {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close();
  }
};
Vue.prototype.$notify = YkcNotification;
export default YkcNotification;
