<!--
 * @Author: Yanxia Wan
 * @Date: 2021-10-09
 * @Description: 多选框
-->
<template>
  <div class="ykc-checkbox">
    <!-- 全选 -->
    <el-checkbox
      class="all-check"
      v-if="isAllSelect"
      v-model="checkAll"
      :indeterminate="isIndeterminate"
      @change="handleCheckAllChange">
      全选
      <ykc-icon name="checked" class="icon-check show"></ykc-icon>
    </el-checkbox>

    <el-checkbox-group @change="checkboxChangeEvent" v-model="model" :min="min" :max="max">
      <el-checkbox
        v-for="(item, index) in data"
        :key="index"
        :label="getValue(item)"
        :disabled="item.disable"
        :style="{ width: getWidth(item, index) + 'px' }">
        {{ getLabel(item) }}
        <ykc-icon name="checked" class="icon-check show"></ykc-icon>
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
  export default {
    name: 'YkcCheckbox',
    data() {
      return {
        checkAll: false, // 全选状态
        isIndeterminate: false, // 全选按钮是否是半选状态
      };
    },
    props: {
      data: {
        // 数据源 [{id:1, value:'a'}]
        type: Array,
        default: () => [],
      },
      widthArr: {
        // 跟数据源对应一致。需要调整样式的 如数据源[{value:'1',id:'1'},{value:'2',id:'2'}]需要调整第二个的宽度为6个字符长度，则widthArr传入[null, 6]
        type: Array,
        default: () => [],
      },
      value: [], // value值
      max: Number, // 最大选择个数
      min: Number, // 最小选择个数
      isAllSelect: {
        // 是否显示全选
        type: Boolean,
        default: false,
      },
      configSet: {
        // 配置项  数据源必须存在配置项的字段
        type: Object,
        default: () => ({
          label: 'name',
          value: 'id',
        }),
      },
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
    methods: {
      /**
       * 全选操作
       */
      handleCheckAllChange(val) {
        this.model = val ? this.data.map(item => item.value) : [];
        this.isIndeterminate = false;
      },
      /**
       * 是否为全选操作
       */
      checkboxChangeEvent(value) {
        if (this.isAllSelect) {
          const checkedCount = value.length;
          // 选中的数据和原数据是否相等（相等为true否则false）
          this.checkAll = checkedCount === this.data.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.data.length;
        }
        this.$emit('change', this.model);
      },
      /**
       * 获取label的配置
       */
      getLabel(item) {
        return item ? item[this.configSet.label] : '';
      },
      /**
       * 获取value的配置
       */
      getValue(item) {
        return item ? item[this.configSet.value] : '';
      },
      /**
       * 获取label的长度
       */
      getWidth(item, index) {
        const widthLen =
          this.widthArr && this.widthArr.length && this.widthArr[index]
            ? this.widthArr[index]
            : 4.5; // item[this.configSet.label].length
        return 14 + 8 + 15 * widthLen;
        // return 90;
      },
    },
  };
</script>

<style lang="scss">
  @import '~@/components/ykc-ui/styles/var.scss';
  .ykc-checkbox {
    .el-checkbox {
      font-weight: 400;
    }
    .el-checkbox__input {
      .el-checkbox__inner {
        border-radius: 4px;
        &::after {
          content: '';
          border: 0;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;
        }
      }

      &.is-checked {
        .el-checkbox__inner::after {
          position: absolute;
          top: -4px;
          left: -2px;
          width: 18px;
          height: 17px;
          border: 0;
          content: '';
        }
      }

      &.is-disabled {
        .el-checkbox__inner::after {
          content: '';
          border: 0;
        }
      }
    }
    // 全选样式
    .el-checkbox.all-check {
      margin: 5px 0 15px;
      //全选按钮（选中时的样式）
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: var(--theme-color-primary);
        border-color: var(--theme-color-primary);
      }
    }

    .el-checkbox {
      .el-checkbox__inner {
        background-color: $_FFFFFF;
        border: 1px solid $_D8D8D8;
      }

      .el-checkbox__label {
        color: $_4A4A4A;
        padding-left: 6px;
      }
    }

    // 选择的样式
    .is-checked {
      .el-checkbox__inner {
        background: $_FFFFFF;
      }
      .show {
        opacity: 1;
      }

      .el-checkbox__label {
        color: var(--theme-color-primary);
        padding-left: 6px;
      }
    }

    .is-disabled {
      cursor: not-allowed;
    }

    .icon-check {
      opacity: 0;
      position: absolute;
      top: 3px;
      left: 2px;
      z-index: 1;
    }
  }
  body[ykc-ui='soui'] {
    .ykc-checkbox {
      .el-checkbox {
        font-weight: 400;
      }
      .el-checkbox__input {
        .el-checkbox__inner {
          border-radius: 4px;
          &::after {
            content: '';
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
          }
        }

        &.is-checked {
          .el-checkbox__inner::after {
            position: absolute;
            top: -4px;
            left: -2px;
            width: 18px;
            height: 17px;
            border: 0;
            content: '';
          }
        }

        &.is-disabled {
          .el-checkbox__inner::after {
            content: '';
            border: 0;
          }
        }
      }
      // 全选样式
      .el-checkbox.all-check {
        margin: 5px 0 15px;
        //全选按钮（选中时的样式）
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: #00fff4;
          border-color: #00fff4;
        }
        .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
          background-color: #090a18;
        }
      }

      .el-checkbox {
        .el-checkbox__inner {
          background-color: #090a18;
          border: 1px solid $_FFFFFF;
        }

        .el-checkbox__label {
          color: $_FFFFFF;
          padding-left: 6px;
        }
      }

      // 选择的样式
      .is-checked {
        .el-checkbox__inner {
          background: #090a18;
        }
        .show {
          opacity: 1;
        }

        .el-checkbox__label {
          color: #00fff4;
          padding-left: 6px;
        }
      }

      .is-disabled {
        cursor: not-allowed;
      }

      .icon-check {
        opacity: 0;
        position: absolute;
        top: 3px;
        left: 2px;
        z-index: 1;
      }
    }
  }
</style>
