/*
 * @Author: Daokai Huo
 * @Date: 2021-09-29 11:22:54
 * @LastEditTime: 2021-09-29 11:29:18
 * @Description:
 */
import YkcSwitch from './src/ykc-switch.vue';

YkcSwitch.install = function install(_vue) {
  _vue.component(YkcSwitch.name, YkcSwitch);
};
export default YkcSwitch;
