<!--
 * @Descripttion: pagination
 * @Author: 汪佳彪
 * @date: 2021-10-20 17:25
-->
<template>
  <div class="ykc-pagination" :style="{ justifyContent }">
    <el-pagination
      :page-size="pageSize"
      :total="total"
      :pager-count="pagerCount"
      :current-page="currentPage"
      :layout="layout"
      :prev-text="prevText"
      :next-text="nextText"
      :disabled="disabled"
      :hide-on-single-page="hideOnSinglePage"
      @hook:created="elPaginationCreated"
      @current-change="handleCurrentChange"></el-pagination>
    <slot name="change-size">
      <div class="ykc-pagination-change-size">
        <span>每页</span>
        <el-input
          :value="userInput !== null ? userInput : pageSize"
          :min="minPageSizeValue"
          :max="maxPageSizeValue"
          type="number"
          class="el-pagination__editor is-in-pagination"
          :disabled="disabled"
          @keyup.native="handleKeyup"
          @input="handleInput"
          @change="handleChange"></el-input>
        <span>条</span>
      </div>
    </slot>
  </div>
</template>

<script>
  // 废弃的属性
  // small background page-count

  export default {
    name: 'YkcPagination',
    props: {
      /** 每页显示条目个数 */
      pageSize: {
        type: Number,
        default: 10,
      },
      /** 当前页数 */
      currentPage: {
        type: Number,
        default: 1,
      },
      /** 总条目数 */
      total: {
        type: Number,
        default: 0,
      },
      /** 页码按钮的数量，当总页数超过该值时会折叠 */
      pagerCount: {
        type: Number,
        default: 5,
        validator(val) {
          if (typeof val === 'number' && val >= 5 && val <= 21 && val % 2 === 1) return true;
          return false;
        },
      },
      /** 组件布局，子组件名用逗号分隔 */
      layout: {
        type: String,
        default: 'prev, pager, next, jumper, total',
      },
      /** 替代图标显示的上一页文字 */
      prevText: {
        type: String,
      },
      /** 替代图标显示的下一页文字 */
      nextText: {
        type: String,
      },
      /** 是否禁用 */
      disabled: {
        type: Boolean,
      },
      /** 只有一页时是否隐藏 */
      hideOnSinglePage: {
        type: Boolean,
      },
      /** 多语言翻译 */
      translate: {
        type: Function,
        default: (path, options) => {
          const key = path.replace('el.pagination.', '');
          const { total } = options || {};
          return {
            goto: '跳至',
            pagesize: '条/页',
            total: `共${total}条`,
            pageClassifier: '页',
          }[key];
        },
      },
      /** 对齐方式 */
      align: {
        type: String,
        default: 'right',
        validator(val) {
          return ['left', 'center', 'right'].includes(val);
        },
      },
      /** 每页最少条目数 */
      minPageSizeValue: {
        type: Number,
        default: 1,
        validator(val) {
          return Number(val) > 0;
        },
      },
      /** 每页最多条目数 */
      maxPageSizeValue: {
        type: Number,
        default: 999,
        validator(val) {
          return Number(val) > 0;
        },
      },
      /** 在el-pagination创建后，可重写其内部组件 */
      rewriteElPaginationComponents: Function,
    },
    data() {
      return {
        /** 多语言时，组件会mixin一个函数，el中函数名叫t */
        translateFunctionName: 't',
        userInput: null,
      };
    },
    computed: {
      justifyContent() {
        return {
          left: 'flex-start',
          right: 'flex-end',
          center: 'center',
        }[this.align];
      },
    },
    methods: {
      elPaginationCreated() {
        const { translateFunctionName, translate, rewriteElPaginationComponents } = this;
        const elPaginationVm = this.$children[0];
        if (rewriteElPaginationComponents) {
          rewriteElPaginationComponents(elPaginationVm);
          return;
        }
        // eslint-disable-next-line
        Object.values(elPaginationVm.$options.components).forEach(component => {
          if (
            component.mixins &&
            Array.isArray(component.mixins) &&
            component.mixins.some(
              mixin =>
                typeof mixin.methods === 'object' &&
                typeof mixin.methods[translateFunctionName] === 'function'
            )
          ) {
            component.mixins.push({
              methods: {
                [translateFunctionName]: translate,
              },
            });
          }
        });
      },
      // eslint-disable-next-line
      handleKeyup({ keyCode, target }) {
        // Chrome, Safari, Firefox triggers change event on Enter
        // Hack for IE: https://github.com/ElemeFE/element/issues/11710
        // Drop this method when we no longer supports IE
        if (keyCode === 13) {
          this.handleChange(target.value);
        }
      },
      handleInput(value) {
        this.userInput = value;
      },
      handleChange(value) {
        const {
          minPageSizeValue,
          maxPageSizeValue,
          $children: [elPaginationVm],
        } = this;
        let val = Number(value);
        if (val !== Number(elPaginationVm.internalPageSize)) {
          if (val <= minPageSizeValue) val = minPageSizeValue;
          if (val > maxPageSizeValue) val = maxPageSizeValue;
          this.userInput = null;
          elPaginationVm.internalPageSize = val;
          elPaginationVm.userChangePageSize = true;
          const currentPage = elPaginationVm.getValidCurrentPage(this.currentPage);
          this.$emit('update:currentPage', currentPage);
          this.$emit('update:pageSize', val);
          this.$emit('size-change', val);
        }
      },
      handleCurrentChange(val) {
        this.$emit('update:currentPage', val);
        this.$emit('current-change', val);
      },
    },
  };
</script>

<style lang="scss">
  @import '~@/components/ykc-ui/styles/var.scss';
  .item {
    height: 32px;
    min-width: 32px;
    background: $_FFFFFF;
    border: 1px solid $_E2E0E0;
    border-radius: 2px;
    margin: 0;
    padding: 0;
    color: $_4A4A4A;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .ykc-pagination {
    position: relative;
    display: flex;
    align-items: center;
    .el-pagination {
      padding: 0;
      display: flex;
      align-items: center;
      button.btn-prev {
        @extend .item;
        &:hover {
          color: $theme_primary;
          border: 1px solid $theme_primary;
        }
        &:active {
          color: $_FFFFFF;
          background-color: $theme_primary;
          border: 1px solid $theme_primary;
        }
      }
      button.btn-next {
        @extend .item;
        margin-left: 8px;
        &:hover {
          color: $theme_primary;
          border: 1px solid $theme_primary;
        }
        &:active {
          color: $_FFFFFF;
          background-color: $theme_primary;
          border: 1px solid $theme_primary;
        }
      }
      .el-pager li {
        @extend .item;
        margin-left: 8px;
        &:hover {
          color: $theme_primary;
          border: 1px solid $theme_primary;
        }
        &:active {
          color: $_FFFFFF;
          background-color: $theme_primary;
          border: 1px solid $theme_primary;
        }
        // 激活时的颜色
        &.active {
          color: $_FFFFFF;
          background-color: $theme_primary;
          border: 1px solid $theme_primary;
        }
        &.btn-quickprev,
        &.btn-quicknext {
          background: transparent;
          border: none;
          min-width: 0;
          width: auto;
        }
      }
      .el-pagination__jump {
        font-size: 12px;
        height: 32px;
        margin-left: 4px;
        .el-input {
          height: 100%;
          min-width: 32px;
          width: 32px;
          padding: 0;
          margin: 0 4px;
          input {
            @extend .item;
            width: 32px;
            &:focus {
              color: $theme_primary;
              border: 1px solid $theme_primary;
            }
          }
        }
      }
      .el-pagination__total {
        display: flex;
        align-items: center;
        height: 32px;
        margin: 0;
        margin-left: 8px;
      }
    }
    &-change-size {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      color: $_4A4A4A;
      margin-left: 8px;
      .el-input {
        height: 100%;
        min-width: 32px;
        width: 32px;
        padding: 0;
        margin: 0 4px;
        input {
          @extend .item;
          width: 32px;
          text-align: center;
          -moz-appearance: textfield;
          &:focus {
            color: $theme_primary;
            border: 1px solid $theme_primary;
          }
        }
      }
    }
  }

  // 运营商平台4.0 pagination组件 样式
  body[ykc-ui='soui'] {
    .ykc-pagination {
      .el-pagination {
        button.btn-prev,
        button.btn-next {
          background: rgba(255, 255, 255, 0.1);
          border: 0.5px solid rgba(226, 224, 224, 0.3);
          .el-icon {
            color: #fff;
          }
        }
        .el-pager li {
          background: rgba(255, 255, 255, 0.1);
          border: 0.5px solid rgba(226, 224, 224, 0.3);
          color: #fff;
        }
        .el-pager li.active {
          background-image: linear-gradient(
            179deg,
            rgba(14, 95, 255, 0.88) 0%,
            rgba(21, 94, 223, 0) 54%,
            rgba(14, 95, 255, 0.88) 100%
          );
          color: #00dbff;
          border: 0.5px solid #0e5fff;
          box-sizing: border-box;
        }
        .el-pager li.el-icon.more {
          border: none;
          background: transparent;
        }
        .el-pagination__jump,
        .el-pagination__total {
          color: #fff;
        }
      }
      .ykc-pagination-change-size {
        color: #fff;
      }
      .el-pagination .el-pagination__jump .el-input input,
      .ykc-pagination-change-size .el-pagination__editor .el-input__inner {
        background: rgba(255, 255, 255, 0.1);
        border: 0.5px solid rgba(226, 224, 224, 0.3);
        color: #ffffff;
      }
    }
  }
</style>
