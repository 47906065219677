<template>
  <el-select
    v-bind="attrs"
    v-model="model"
    remote
    :remote-method="remoteMethod"
    @change="change"
    @visible-change="onBlur">
    <el-option
      v-for="item in showList"
      :key="item[valueKey]"
      :label="item[labelKey]"
      :value="item[valueKey]"></el-option>
  </el-select>
</template>

<script>
  export default {
    name: 'YkcSelect',
    props: {
      value: {
        type: [Array, String],
      },
      data: {
        type: Array,
      },
      valueKey: {
        type: String,
      },
      labelKey: {
        type: String,
      },
      maxShowCount: {
        type: Number,
        default: 50,
      },
    },
    data() {
      return {
        model: this.value,
        queryStr: '',
      };
    },
    updated() {
      this.model = this.value;
    },
    computed: {
      attrs() {
        return { size: 'small', ...this.$attrs };
      },
      showList() {
        return (
          this.data
            ?.filter(o => {
              if (o[this.labelKey].includes(this.queryStr)) {
                return true;
              }
              if (Array.isArray(this.model) && this.model.includes(o[this.valueKey])) {
                return true;
              }
              if (typeof this.model === 'string' && this.model === o[this.valueKey]) {
                return true;
              }
              return false;
            })
            .filter((o, i) => {
              if (i < this.maxShowCount) {
                return true;
              }
              if (Array.isArray(this.model) && this.model.includes(o[this.valueKey])) {
                return true;
              }
              if (typeof this.model === 'string' && this.model === o[this.valueKey]) {
                return true;
              }
              return false;
            }) ?? []
        );
      },
    },
    created() {
      this.model = this.value;
    },
    methods: {
      onBlur() {
        this.queryStr = '';
      },
      remoteMethod(query) {
        this.queryStr = query;
      },
      change(val) {
        this.$emit('input', val);
      },
    },
  };
</script>

<style lang="scss" scoped></style>
