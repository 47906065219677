<!--
* @name: 富文本编辑器
* @Auther: yangwenjuan
* @Date: 2019/12/10
-->
<template>
  <div class="editor-box">
    <div :id="editorId" class="text-left"></div>
    <!-- <div id="editor-error" class="editor-error el-form-item__error" v-if="error">
      {{ errorMsg }}
    </div> -->
  </div>
</template>

<script>
  import WE from 'wangeditor';
  import { uploadUtils } from '@/service/apis';
  import regexpObj from '@/utils/regexp';

  export default {
    name: 'ykc-editor',
    props: {
      isValidate: {
        // 是否校验是否为空
        type: Boolean,
        default: false,
      },
      msgValidate: {
        // 校验为空时的提示
        type: String,
        default: '',
      },
      max: {
        // 最大字数
        type: Number,
        default: 200,
      },
      isHead: {
        // 是否配置标题大小
        type: Boolean,
        default: true,
      },
      isFontsize: {
        // 是否配置字体选择
        type: Boolean,
        default: true,
      },
      validateTip: {
        type: String,
        default: '',
      },
      validSpecialCharacter: {
        // 是否校验特殊字符
        type: Boolean,
        default: true,
      },
      disabled: {
        // 是否禁用
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        editorId: `editorElem-${Math.random().toString(36).substring(3, 8)}`, // 富文本编辑器组件id
        editor: '',
        error: false, // 是否有错误提示
        errorMsg: '', // 错误提示
      };
    },
    watch: {
      disabled() {
        this.setStatus();
      },
    },
    mounted() {
      this.seteditor();
    },
    methods: {
      /**
       * 初始化
       */
      seteditor() {
        this.editor = new WE(`#${this.editorId}`);
        this.editor.customConfig.menus = [
          this.isHead ? 'head' : '', // 标题
          'bold', // 粗体
          this.isFontsize ? 'fontSize' : '', // 字号
          'fontName', // 字体
          'italic', // 斜体
          'underline', // 下划线
          'strikeThrough', // 删除线
          'foreColor', // 文字颜色
          'backColor', // 背景颜色
          // 'link', // 插入链接
          'list', // 列表
          'justify', // 对齐方式
          'image', // 插入图片
          // 'table', // 表格
          'undo', // 撤销
          'redo', // 重复
        ];
        // 设置可上传本地图片
        const self = this;
        this.editor.customConfig.customUploadImg = (files, insert) => {
          self.sendImage(files[0], insert);
          // files 是 input 中选中的文件列表
          // insert 是获取图片 url 后，插入到编辑器的方法
          // 上传代码返回结果之后，将图片插入到编辑器中
        };
        this.editor.customConfig.uploadImgMaxSize = 2 * 1024 * 1024;
        this.editor.customConfig.customAlert = info => {
          self.$dialog.error(info);
        };
        this.editor.customConfig.onblur = () => {
          // 失去文本焦点
          // self.validate(html);
        };
        this.editor.customConfig.onchange = () => {
          // 失去文本焦点
          // if (self.error) {
          //   self.validate(html);
          // }
        };
        this.editor.customConfig.zIndex = 1;
        this.editor.create();
        this.editor.config.showLinkImg = false; // 去除网络图片上传功能
        this.setStatus();
      },
      /**
       * 上传图片
       */
      sendImage(file, insert) {
        const nameStr = file.name.lastIndexOf('.');
        const endName = file.name.substring(nameStr + 1);
        if (endName !== 'jpg' && endName !== 'jpeg' && endName !== 'png') {
          this.$dialog.error(
            `图片验证未通过: 【${file.name}】格式不正确,只能上传jpg/jpeg/png文件。`
          );
        } else {
          const formData = new FormData();
          formData.append('file', file);
          uploadUtils.uploadImg(formData).then(res => {
            insert(res);
          });
        }
      },
      /**
       * 字数校验
       */
      validate(html) {
        if (this.isValidate && (html === '<p><br></p>' || html === '')) {
          return new Error(this.msgValidate);
        }
        if (this.getText().length > this.max) {
          return new Error(`内容不能超过${this.max}个字符`);
        }
        if (html.length > 60000) {
          let pHtml = html.replace(/<style(([\s\S])*?)<\/style>/g, '');
          pHtml = pHtml.replace(/<!--[\w\W\r\n]*?-->/g, '');
          if (pHtml.length > 60000) {
            return new Error('样式过多或内容过长');
          }
        } else if (
          this.validSpecialCharacter &&
          !regexpObj.regexp.input.specialCharacters.regexp.test(this.getText())
        ) {
          if (this.validateTip.length > 0) {
            return new Error(`请输入正确的${this.validateTip}（不可输入#$%;"\\）`);
          }
          return new Error('不可输入#$%;"\\');
        }
        return true;
      },
      /**
       * 获取带格式文字
       */
      getHtml() {
        let html = this.editor.txt.html().replace(/<style(([\s\S])*?)<\/style>/g, '');
        html = html.replace(/<!--[\w\W\r\n]*?-->/g, '');
        const validMessage = this.validate(html);
        if (validMessage instanceof Error) {
          return validMessage;
        }
        return html;
      },
      /**
       * 设置内容
       */
      setHtml(html) {
        this.editor.txt.html(html);
      },
      /**
       * 获取纯文字
       */
      getText() {
        const test = this.editor.$textElem[0].innerText;
        return test.replace(/\s*/g, '');
      },
      /**
       * 设置错误信息
       * flag 是否显示错误信息
       * msg 错误信息内容
       */
      setError(flag, msg) {
        this.error = flag;
        this.errorMsg = msg;
      },
      setStatus() {
        if (this.disabled) {
          this.editor.$toolbarElem.css('display', 'none');
          this.editor.$textContainerElem.css('border-top', '1px solid #ccc');
          this.editor.$textElem.attr('contenteditable', false);
        } else {
          this.editor.$toolbarElem.css('display', 'flex');
          this.editor.$textContainerElem.css('border-top', 'none');
          this.editor.$textElem.attr('contenteditable', true);
        }
      },
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
