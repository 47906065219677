<!--
 * @Descripttion: header组件
 * @Author: 汪佳彪
 * @date: 2021-10-09 10:36
-->
<template>
  <el-header class="ykc-header" :class="[`ykc-header-${type}`]" :height="height">
    <slot>
      <div class="ykc-header-left">
        <slot name="map"></slot>
        <!-- <div class="prepend"><slot name="prepend"></slot></div> -->
        <div class="title">{{ title }}</div>
      </div>
      <div class="ykc-header-right">
        <slot name="download"></slot>
        <el-dropdown @command="handleCommand">
          <div class="ykc-header-username">
            <span>{{ username }}</span>
            <span class="triangle-bottom"></span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="change-password">修改密码</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <slot name="notice"></slot>
        <div class="ykc-header-logout" @click="handleLogout"></div>
      </div>
    </slot>
  </el-header>
</template>

<script>
  import windowResizeMixin from '@/components/ykc-ui/utils/window-resize-mixin';

  export default {
    name: 'YkcHeader',
    mixins: [windowResizeMixin],
    props: {
      // 左侧标题
      title: {
        type: String,
      },
      // 用户名
      username: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        expandHeight: '73px',
        collapseHeight: '54px',
        height: '73px',
      };
    },
    methods: {
      /**
       * @description 根据window resize计算大小
       */
      calculateSize(isExpanded) {
        this.height = isExpanded ? this.expandHeight : this.collapseHeight;
      },

      /**
       * @description 用户名下拉项点击
       */
      handleCommand(command) {
        this.$emit(command);
      },
      /**
       * @description 退出登录
       */
      handleLogout() {
        this.$emit('logout');
      },
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
