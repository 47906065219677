import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

Vue.use(Router);

// const paramsPrefix = 'YKCParams=';

const router = new Router({
  mode: 'history',
  routes,
  // parseQuery: str => {
  //   console.log('parseQuery', str);
  //   if (str) {
  //     const params = str.replace(new RegExp(`^${paramsPrefix}`), '');
  //     const queryStr = unescape(decodeURIComponent(params));
  //     try {
  //       const result = JSON.parse(queryStr);
  //       return result;
  //     } catch (e) {
  //       console.log(e);
  //       return {};
  //     }
  //   }
  //   return {};
  // },
  // stringifyQuery: query => {
  //   const keys = Object.keys(query);
  //   if (keys.length > 0) {
  //     const str = JSON.stringify(query);
  //     return `?${paramsPrefix}${encodeURIComponent(escape(str))}`;
  //   }
  //   return '';
  // },
});

// eslint-disable-next-line no-unused-vars
// router.beforeEach((to, from, next) => {
//   const token = localStorage.getItem('token') || '';
//   console.log(token, [from, to].map(item => item.path).join('->'));
//   next();
// });

router.onError(error => {
  console.log('router.onError', error, error.message);
  // 某个路由对应的异步组件由于更改了代码并发布到环境上了
  // 但前端还是老版本,且从未加载过该路由,然后在发布新版本后加载该路由时
  // 会触发此场景,并报错
  if (/Loading chunk .* failed/g.test(error.message)) {
    console.log('发布了新版本');
    window.location.reload();
  }
});

export default router;
