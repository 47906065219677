<!--
 * @Author: Daokai Huo
 * @Date: 2021-10-15 16:50:12
 * @LastEditTime: 2021-10-19 17:02:01
 * @Description: 
-->
<template>
  <div class="ykc-date-picker el-date-editor">
    <el-col :span="11">
      <el-date-picker
        class="ykc-date-picker"
        :popper-class="'ykc-date-picker'"
        v-model="model[0]"
        type="year"
        :editable="false"
        :value-format="setFormat"
        :format="setFormat"
        :picker-options="pickerOptionsStart"
        range-separator="-"
        :placeholder="placeholder[0]"></el-date-picker>
    </el-col>
    <el-col class="year-range-line" :span="2">-</el-col>
    <el-col :span="11">
      <el-date-picker
        class="ykc-date-picker"
        :popper-class="'ykc-date-picker'"
        v-model="model[1]"
        type="year"
        :editable="false"
        :value-format="setFormat"
        :format="setFormat"
        :picker-options="pickerOptionsEnd"
        range-separator="-"
        :placeholder="placeholder[1]"></el-date-picker>
    </el-col>
  </div>
</template>
<script>
  export default {
    name: 'YearRange',
    props: {
      // 获取的value值
      value: Array,
      // 限制日期: 默认不限制 ，limitBefore(限制今年之前日期不可选) limitAfter(限制今年之后日期不可选)
      limitDate: {
        type: String,
        default: '',
      },
      // 是否限制今年 需要配合 limitDate 使用
      limitToday: {
        type: Boolean,
        default: false,
      },
      format: {
        type: String,
        default: '',
      },
      // 选择时的占位内容
      placeholder: {
        type: Array,
        default: () => ['开始日期', '结束日期'],
      },
    },
    data() {
      return {
        setFormat: this.format || 'yyyy',
      };
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      /**
       * 限制开始日期计算
       */
      pickerOptionsStart() {
        console.log(this.model);
        return {
          disabledDate: date => this.checkDate(date, this.model[1], true),
        };
      },
      /**
       * 限制结束日期计算
       */
      pickerOptionsEnd() {
        return {
          disabledDate: date => this.checkDate(date, this.model[0]),
        };
      },
    },
    methods: {
      /**
       * 根据limitDate 校验日期规则
       */
      checkDate(date, val, t) {
        let state = false;
        if (this.limitDate && !state) {
          const nowDate = Date.now();
          const timesTamp = 86400000 * 365;
          const before = this.limitToday ? nowDate + timesTamp : nowDate;
          const after = this.limitToday ? nowDate - timesTamp : nowDate;
          // 今年之前不能选择
          if (this.limitDate === 'limitBefore') {
            state = date && date.valueOf() < before;
            // 禁止今年之后不能选择
          } else if (this.limitDate === 'limitAfter') {
            state = date && date.valueOf() > after;
          } else {
            // 根据传入时间限制， 传入时间之前及传入的时间不能选择
            /* eslint-disable */
            // 如果所传时间小于今年时间，今年之前的不能选，
            if (new Date(this.limitDate).valueOf() < nowDate) {
              state = date && date.valueOf() < nowDate;
              // 否则，传入时间及之前的不能选
            } else {
              state = date && date.valueOf() <= new Date(this.limitDate).valueOf();
            }
          }
        } else {
          // 前后日期选择校验
          const checkTime = new Date(val);
          const startGetTime = date.getTime();
          state = t ? startGetTime > checkTime : startGetTime < checkTime;
        }
        return state;
      },
    },
  };
</script>
<style lang="scss">
  @import './index.scss';
  .year-range-line {
    text-align: center;
    line-height: 30px;
  }
</style>
