import Vue from 'vue';
import * as SvgComponent from '@/components/Svg.vue';

Vue.component('svg-icon', SvgComponent);

const req = require.context('./svg', false, /\.svg$/);

const requireAll = ctx => ctx.keys().map(ctx);

requireAll(req);
