<!--
 *@author huhongyun
 *@date 2021/10/15
 *@component 反馈弹窗
-->

<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :class="`ykc-feedback-dialog ykc-feedback-dialog-${type}`"
    ref="feedbackDialog"
    :visible.sync="show"
    :fullscreen="fullscreen"
    :top="top"
    :modal="modal"
    :modal-append-to-body="modalAppendToBody"
    :append-to-body="appendToBody"
    :lock-scroll="lockScroll"
    :custom-class="localCustomClass"
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="closeOnPressEscape"
    :show-close="showClose"
    :center="center"
    :destroy-on-close="destroyOnClose"
    :before-close="beforeClose">
    <div class="ykc-feedback-dialog-content">
      <i v-if="showWarningIcon" class="el-icon-warning warning-icon"></i>
      <i v-if="showSuccessIcon" class="el-icon-success success-icon"></i>
      <i v-if="showErrorIcon" class="el-icon-warning error-icon"></i>
      <div v-if="showTitle" class="title">{{ title }}</div>
      <!--      <div v-if="desc" class="desc" :class="descClass">-->
      <!--        <slot name="desc">-->
      <!--          {{ desc }}-->
      <!--        </slot>-->
      <!--      </div>-->
      <template class="ykc-feedback-dialog-content">
        <el-scrollbar
          tag="div"
          wrap-class="feedback-dialog-scrollbar-wrapper"
          view-class="feedback-dialog-scrollbar-view-box">
          <div v-if="desc" class="desc" :class="descClass">
            <slot name="desc">
              {{ desc }}
            </slot>
          </div>
        </el-scrollbar>
      </template>
      <slot v-if="$slots.content" name="content"></slot>
    </div>
    <span v-if="showFooter" slot="footer" class="dialog-footer">
      <el-button
        v-if="showCancelButton"
        class="cancel-btn"
        :style="footerBtnStyle"
        @click="onCancel">
        {{ cancelBtnTxt || '取 消' }}
      </el-button>
      <el-button class="confirm-btn" type="primary" :style="footerBtnStyle" @click="onConfirm">
        {{ confirmBtnTxt || '确 认' }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    name: 'YkcFeedbackDialog',
    props: {
      // 是否为 js 版本
      isJSVersion: {
        type: Boolean,
        default: false,
      },
      // footer 按钮样式
      footerBtnStyle: [String, Object],
      // 反馈描述
      desc: [String, Object],
      // 标题
      title: {
        type: String,
        default: '默认标题',
      },
      // 是否展示弹窗
      show: Boolean,
      // multipleLine: Boolean,
      // 是否显示成功图标
      showSuccessIcon: {
        type: Boolean,
        default: false,
      },
      // 是否显示错误图标
      showErrorIcon: {
        type: Boolean,
        default: false,
      },
      // 是否显示标题·
      showTitle: {
        type: Boolean,
        default: true,
      },
      // 是否显示错误图标
      showWarningIcon: {
        type: Boolean,
        default: false,
      },
      // 是否展示 footer 部分, 默认展示
      showFooter: {
        type: Boolean,
        default: true,
      },
      // 显示取消按钮
      showCancelButton: {
        type: Boolean,
        default: true,
      },
      // 弹框类型
      type: {
        type: String,
        default: 'default',
        validator: val => ['default', 'warning', 'success', 'error'].includes(val),
      },
      //  取消文本
      cancelBtnTxt: String,
      // 确认文本
      confirmBtnTxt: String,
      // 点击取消的钩子函数
      beforeCancel: Function,
      // 点击确认调用的回调函数
      beforeConfirm: Function,
      //  Dialog CSS 中的 margin-top 值
      top: {
        type: String,
        default: '30vh',
      },
      // 是否需要遮罩层
      modal: {
        type: Boolean,
        default: true,
      },
      // 遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素
      modalAppendToBody: {
        type: Boolean,
        default: true,
      },
      // Dialog 自身是否插入至 body 元素上。嵌套的 Dialog 必须指定该属性并赋值为 true
      appendToBody: {
        type: Boolean,
        default: true,
      },
      // 是否在 Dialog 出现时将 body 滚动锁定
      lockScroll: {
        type: Boolean,
        default: true,
      },
      // Dialog 的自定义类名
      customClass: {
        type: String,
        default: '',
      },
      // 是否可以通过点击 modal 关闭 Dialog
      closeOnClickModal: {
        type: Boolean,
        default: false,
      },
      // 是否可以通过按下 ESC 关闭 Dialog
      closeOnPressEscape: {
        type: Boolean,
        default: false,
      },
      // 是否显示关闭按钮
      showClose: {
        type: Boolean,
        default: true,
      },
      // 是否对头部和底部采用居中布局
      center: {
        type: Boolean,
        default: false,
      },
      // 关闭时销毁 Dialog 中的元素
      destroyOnClose: {
        type: Boolean,
        default: false,
      },
      // 是否全屏展示
      fullscreen: {
        type: Boolean,
        default: false,
      },
      onCLose: Function,
    },
    computed: {
      /**
       * 这里是描述信息的动态样式类
       * */
      descClass() {
        const style = {};
        // 如果存在图标展示
        if (this.showWarningIcon || this.showSuccessIcon) {
          delete style['multiple-line'];
        }
        // 如果不显示标题 且 不是多行
        if (!this.showTitle) {
          style['one-line-center'] = true;
        }
        // 如果不显示标题，也不显示 footer ，单行的情况下
        if (!this.showTitle && !this.showFooter) {
          delete style['one-line-center'];
          style['min-line-center'] = true;
        }
        return style;
      },
      localCustomClass() {
        if (this.customClass) {
          return `ykc-dialog ykc-dialog-${this.type} ${this.customClass || ''}`;
        }
        return `ykc-dialog ykc-dialog-${this.type}`;
      },
    },
    watch: {
      show(val) {
        if (val) {
          this.$emit('update:show', true);
          this.$emit('open-dialog');
        } else {
          this.$emit('update:show', false);
          this.$emit('close-dialog');
        }
      },
    },
    methods: {
      beforeClose() {
        if (typeof this.onClose === 'function') {
          this.onClose(this.hide);
        } else {
          this.hide();
        }
      },
      /**
       * 手动销毁实例和DOM节点
       * */
      destroyInstance() {
        this.$destroy();
        if (this.$el && this.$el.parentNode) {
          this.$el.parentNode.removeChild(this.$el);
        }
      },
      /**
       * 隐藏弹出函数
       * */
      hide(cancel) {
        if (cancel !== false) {
          this.$emit('update:show', false);
          this.$emit('close-dialog');
          if (this.isJSVersion) {
            this.destroyInstance();
          }
        }
      },

      /**
       * 用户点击了取消，调用用户传入的取消钩子函数
       * */
      onCancel() {
        if (typeof this.beforeCancel === 'function') {
          this.beforeCancel(this.hide);
        } else {
          this.hide();
        }
      },
      /**
       * 用户点击了确认，直接调用用户传入的确认钩子函数
       * */
      onConfirm() {
        if (typeof this.beforeConfirm === 'function') {
          this.beforeConfirm(this.hide);
        } else {
          this.hide();
        }
      },
    },
    mounted() {},
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
