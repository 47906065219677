<!--
 * @Author: Daokai Huo
 * @Date: 2021-10-09 09:44:14
 * @LastEditTime: 2022-05-26 16:13:55
 * @Description: 文件上传
-->
<template>
  <div style="display: flex">
    <el-upload
      ref="upload"
      class="ykc-upload"
      action="/fakeupload"
      :data="data"
      :name="name"
      :limit="1"
      :auto-upload="false"
      :file-list="fileList"
      :accept="accept"
      :list-type="listType"
      :on-error="handlesError"
      :on-change="handleChange"
      :on-remove="handleRemove"
      :on-success="handlesSuccess"
      :before-upload="beforeUpload"
      :on-progress="handlesProgress"
      :class="fileList.length && `uploaded-${listType}`">
      <template v-if="type === 'img'">
        <div class="camera-wrap">
          <i class="icon-camera ykc-icon-camera"></i>
          <span>{{ text || defText }}</span>
        </div>
      </template>
      <template v-else>
        <ykc-button slot="trigger">{{ text || defText }}</ykc-button>
        <a class="link" v-if="temhref && !fileList.length" :href="temhref">{{ temText }}</a>
      </template>
      <!-- 图片预览 -->
      <div v-if="type === 'img'" slot="file" slot-scope="{ file }" class="box-wrap">
        <img class="box-wrap-img" :src="file.url" :alt="file.name" @click="handlePreview(file)" />
        <i class="el-icon-close" @click="handleRemove(file)"></i>
      </div>
    </el-upload>
    <a
      class="link file-link"
      v-if="type !== 'img' && fileList.length && fileList[0].url"
      :href="fileList[0].url">
      下载
    </a>
    <!-- dialog 临时使用 -->
    <el-dialog append-to-body class="upload-dialog" :visible.sync="visible">
      <img :src="imageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
  export default {
    name: 'YkcUpload',
    props: {
      // 上传的文件字段名
      name: String,
      // 上传时附带的额外参数
      data: Object,
      // 默认文件大小
      size: {
        type: Number,
        default: 1,
      },
      // 文件类型 img / files / pdf / xlsx
      type: {
        type: String,
        default: 'img',
      },
      // 默认文件
      file: Array,
      // 默认文字
      text: String,
      // 校验提示信息
      checkInfo: {
        type: Object,
        default: () => ({
          typeMess: '文件格式不正确，请重新上传',
          sizeMess: '文件大小不超过',
        }),
      },
      checkFileType: {
        type: Boolean,
        default: true,
      },
      // 下载模板地址
      temhref: String,
      temText: {
        type: String,
        default: '用户导入模板.xlsx',
      },
      preview: {
        type: Boolean,
        default: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        // 1048576字节等于1M
        defSize: 1048576,
        // 文件类型
        // d0cf11e0 是 xlsx xls 的文件头
        // 89504e47 是 png 的文件头
        // ffd8ffe0 ffd8ffe1 ffd8ffe8是 jpg 的文件头
        // 47494638 是 gif 的文件头
        // 25504446 是 pdf 的文件头
        // 3c3f786d 是 doc 的文件头
        // 504b0304 是 docx 的文件头
        types: {
          img: {
            jpg: ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffe8'],
            jpeg: ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffe8'],
            png: '89504e47',
            gif: '47494638',
          },
          files: {
            pdf: '25504446',
            doc: '3c3f786d', // wr d0cf11e0
            docx: '504b0304',
            xlsx: 'd0cf11e0',
            xls: 'd0cf11e0', // wr d0cf11e0
            zip: '504b',
          },
          pdf: {
            pdf: '25504446',
            wrpdf: '504b0304',
          },
          xlsx: {
            xlsx: 'd0cf11e0', // 微软创建xlsx,docx 504b0304
            xls: 'd0cf11e0',
            wrxlsx: '504b0304',
          },
          p12: {
            p12: '',
          },
          pem: {
            pem: '',
          },
          zip: {
            zip: '',
          },
          apk: {
            apk: '',
          },
        },
        visible: false,
        imageUrl: '',
        fileType: this.type === 'img',
        fileList:
          this.file ||
          [
            // {
            //   name: '1597827436112.png',
            //   url: 'https://wtayvyzzmyanm9vx-car-test.oss-cn-hangzhou.aliyuncs.com/1597827436112.png',
            // },
          ],
        // 初始校验提示
        initMess: '',
      };
    },
    watch: {
      file: {
        handler(file) {
          this.fileList = file;
        },
      },
    },
    computed: {
      /**
       * 默认允许选择的文件
       */
      accept() {
        const keysArr = Object.keys(this.types[this.type]).map(item => `.${item}`);
        return keysArr.join(',');
      },
      /**
       * 展示上传文件的样式
       */
      listType() {
        return this.fileType ? 'picture' : 'file';
      },
      /**
       * 默认文字
       */
      defText() {
        return this.fileType ? '上传图片' : '上传附件';
      },
    },
    inject: {
      ykcForm: {
        from: 'YkcForm',
        default: () => {},
      },
    },
    methods: {
      /**
       * 文件状态改变时的钩子
       */
      async handleChange(file, fileList) {
        let check = true;
        if (!this.autoUpload) {
          await this.beforeUpload(file.raw)
            .then(() => {
              this.fileList.push(file);
            })
            .catch(() => {
              check = false;
              // this.$refs.upload.clearFiles();
              this.handleRemove(file, fileList);
            });
        }
        this.$emit('handleChange', file, fileList, check, this.text);
      },
      /**
       * 处理文件二进制数据
       */
      getFileType: (file, _vue) => {
        // 截取上传文件名尾缀
        const lastIndex = file.name.lastIndexOf('.');
        const endName = file.name.substring(lastIndex + 1);
        if (endName === 'exe') {
          _vue.fileList = [];
        }
        if (['p12', 'pem', 'zip', 'apk'].includes(_vue.type)) {
          return new Promise(resolve => {
            if (_vue.type === 'p12') {
              resolve(endName === 'p12');
            } else if (_vue.type === 'pem') {
              resolve(endName === 'pem');
            } else if (_vue.type === 'zip') {
              resolve(endName === 'zip');
            } else if (_vue.type === 'apk') {
              resolve(endName === 'apk');
            }
          });
        }
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        return new Promise((resolve, reject) => {
          reader.onload = () => {
            try {
              let buffer = [...new Uint8Array(reader.result)];
              // 保留文件的前四位并转为16进制
              buffer = buffer.splice(0, 4).map(item => item.toString(16).padStart(2, '0'));
              console.log('-> buffer', buffer);
              // 根据16进制对比选择文件是否存在
              const include = Object.values(_vue.types[_vue.type]).flat().includes(buffer.join(''));
              // 文件尾缀
              const includeName = Object.keys(_vue.types[_vue.type]).includes(endName);
              // console.log('buffer--1', buffer.join(''), 'include', include, includeName);
              if (_vue.checkFileType) {
                resolve(include && includeName);
              } else {
                resolve(true);
              }
            } catch (e) {
              // 读取文件头出错 默认不是合法文件类型
              reject();
            }
          };
        });
      },
      /**
       * 上传文件之前的钩子，参数为上传的文件,返回 Promise 且被 reject，则停止上传。
       */
      beforeUpload(file) {
        return this.getFileType(file, this).then(fileType => {
          const fileSize = file.size <= this.size * this.defSize;
          const { typeMess, sizeMess } = this.checkInfo;
          // const { prop = '' } = (this.ykcForm && this.ykcForm.checkprop) || {};
          // const { required = false } = prop && this.ykcForm.rules && this.ykcForm.rules[prop][0];
          // 校验上传类型
          console.log('fileType+++++', fileType, fileSize);
          if (!fileType) {
            // eslint-disable-next-line no-unused-expressions
            !this.required &&
              this.$tips({
                showClose: true,
                message: typeMess,
                type: 'warning',
              });
            // eslint-disable-next-line no-unused-expressions
            this.required && this.$emit('checkMessage', typeMess);
            // if (prop && required) {
            //   this.checkMessage(typeMess, prop);
            // } else {
            //   this.$tips({
            //     showClose: true,
            //     message: typeMess,
            //     type: 'warning',
            //   });
            // }
          }

          // 校验上传文件大小
          if (!fileSize && fileType) {
            const mess = `${sizeMess}${this.size}MB!`;
            // if (prop && required) {
            //   this.checkMessage(mess, prop);
            // } else {
            //   this.$tips({
            //     showClose: true,
            //     message: mess,
            //     type: 'warning',
            //   });
            // }
            // eslint-disable-next-line no-unused-expressions
            console.log('this.required', this.required);
            !this.required &&
              this.$tips({
                showClose: true,
                message: mess,
                type: 'warning',
              });
            // eslint-disable-next-line no-unused-expressions
            this.required && this.$emit('checkMessage', mess);
          }

          if (fileType && fileSize) {
            this.$emit('checkMessage', '');
          }
          // return fileType && fileSize;
          return new Promise((resolve, reject) => {
            if (fileType && fileSize) {
              resolve(true);
            }
            reject();
          });
        });
      },
      /**
       * 校验提示
       */
      checkMessage(mes, prop) {
        const checkField = this.ykcForm && this.ykcForm.rules[prop][0];
        if (!this.initMess) this.initMess = checkField.message;
        checkField.message = mes;
        console.log('checkField', checkField);
        // 恢复默认提示语
        setTimeout(() => {
          checkField.message = this.initMess;
        }, 200);
        return false;
      },
      /**
       * 移除文件时的钩子
       */
      handleRemove(file, fileList) {
        this.fileList = this.fileList.filter(item => item.uid !== file.uid);
        this.$emit('handleRemove', file, fileList, this.text);
      },
      handlePreview(file) {
        if (!this.preview) return;
        this.imageUrl = file.url;
        this.visible = true;
        console.log(file);
      },
      /**
       * 文件上传成功时的钩子
       */
      handlesSuccess(response, file, fileList) {
        if (this.autoUpload) {
          this.fileList.push(file);
        }
        this.$emit('handlesSuccess', response, file, fileList);
      },
      /**
       * 上传失败的钩子
       */
      handlesError(err, file, fileList) {
        console.log('handlesSuccess', err, file, fileList);
        this.$emit('handleError', file, fileList);
      },
      /**
       * 文件上传时的钩子
       */
      handlesProgress(event, file, fileList) {
        console.log('handlesProgress', event, file, fileList);
        this.$emit('handleProgress', event, file, fileList);
      },
      /**
       * 手动上传
       */
      handleSubmit() {
        console.log('this.fileList', this.fileList);
        this.$refs.upload.submit();
      },
    },
  };
</script>
<style lang="scss">
  @import './index.scss';
  .upload-dialog {
    .el-dialog {
      box-shadow: none;
      background: 0;
      text-align: center;
      width: 35%;
      .el-dialog__header {
        display: none;
      }
      .el-dialog__body {
        padding: 0;
        img {
          max-width: 100%;
        }
      }
    }
  }
</style>
