<template>
  <div class="ykc-ztree border" :class="rootClass">
    <div v-if="!isOnlyShow && !isShowCheck" class="tree-top">
      <!-- 树标题 -->
      <div v-if="title" class="tree-select-all">
        <span>{{ title }}</span>
      </div>
      <!-- 搜索 -->
      <div class="tree-search-bar">
        <!-- 全选 -->
        <span
          v-if="selectAll"
          class="all-check"
          :class="{ on: allCheckShow }"
          @click="handleCheckAll">
          <span class="all-check-select"></span>
          <span>{{ allCheckShow ? '取消全选' : '全选' }}</span>
        </span>
        <div v-if="fuzzySearchUse" class="tree-search">
          <input
            ref="searchInput"
            type="text"
            value
            :placeholder="placeholder"
            @keydown="keyDown" />
          <a class="search-btn" @click="handleSearch">{{ searchText }}</a>
        </div>
      </div>
    </div>
    <div class="tree-main-area">
      <!-- 搜索时候的loading -->
      <div v-show="searchLoad" class="ztree-load">
        <div class="loader loading-img"></div>
      </div>
      <div class="tree-main">
        <!-- 搜索后没有匹配数据-显示-暂无数据 -->
        <div v-show="isEmpty" class="tree-no-data">
          <span class="tree-no-data-icon"></span>
          <div class="text">
            {{ emptyText }}
            <a v-if="setData.zNodes && setData.zNodes.length > 0" @click="searchBack">返回</a>
          </div>
        </div>
        <!-- 树 -->
        <ul
          v-show="treeVisible"
          :id="id"
          class="ztree"
          :class="{ 'style-special': styleSpecial }"></ul>
      </div>
    </div>
  </div>
</template>

<script>
  import jQuery from 'jquery';
  import './ztree.all';
  import isEmpty from 'lodash/isEmpty';
  import cloneDeep from 'lodash/cloneDeep';
  import indexOf from 'lodash/indexOf';
  import findIndex from 'lodash/findIndex';

  const Helper = {
    isEmpty,
    cloneDeep,
    indexOf,
    findIndex,
  };

  let id = 0;

  const generateId = () => id++;

  export default {
    name: 'YkcZtree',
    props: {
      emptyText: {
        type: String,
        default: '暂无数据',
      },
      searchText: {
        type: String,
        default: '搜索',
      },
      // 树的配置项数据
      setData: {
        type: Object,
        default: () => ({
          zNodes: [], // 树的数据
          selectData: [], // 树选择的数据
        }),
      },
      // ztree的整体setting配置
      setting: {
        type: Object,
        default: () => ({}),
      },
      // 是否仅仅展示树，无checkbox 会覆盖setting
      isOnlyShow: {
        type: Boolean,
        default: false,
      },
      // zTree 节点数据中保存 对应属性 对应名称。 如 {name: 'stationName', children: 'children'} 即name用stationName代替
      nodeKeyObj: {
        type: Object,
        default: () => ({}),
      },
      // 是否显示全选
      selectAll: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
        default: '',
      },
      // 是否启用搜索
      fuzzySearchUse: {
        type: Boolean,
        default: true,
      },
      // 搜索对应的key
      nameKey: {
        type: String,
        default: 'name',
      },
      // 是否精确搜索（默认否（模糊搜索））
      isAccurate: {
        type: Boolean,
        default: false,
      },
      // 是否启用特殊样式 (一级节点加蓝底,每个节点加分隔线,所有的)
      styleSpecial: {
        type: Boolean,
        default: true,
      },
      // 用于权限树的特殊处理，为true时，部分权限树如果同列有查看（即funButtonCode 为see）,则默认勾选上查看
      relatoinView: {
        type: Boolean,
        default: false,
      },
      // 单选是否取消 为false不给取消
      isRadioCanCancel: {
        type: Boolean,
        default: false,
      },
      // 对于父子不关联的树 是否默认关联子节点
      isRelateChild: {
        type: Boolean,
        default: false,
      },
      // 勾选默认置顶
      // checkNeedTop: {
      //     type: Boolean,
      //     default: false
      // }
      // 是否只展示并且展示选择的节点
      isShowCheck: {
        type: Boolean,
        default: false,
      },
    },
    created() {
      this.id = generateId();
    },
    data() {
      return {
        treeSetData: {}, // 处理后的tree的配置数据
        children: 'subNodes', // 配置里子节点的key
        allCheckShow: false, // 全选是否勾选的状态
        zNodes: [], // 处理后的树的数据
        keywords: '',
        zTree: null, // 当前树
        // 搜索相关
        // search的load显示与否
        searchLoad: false,
        // 搜索的延时器
        timeoutId: null,
        // 最后一次的搜索条件
        lastKeyword: '',
        // 是否高亮
        isHighLight: true,
        /* eslint-disable */
        rexMeta: new RegExp('[\\[\\]\\\\^\\$\\.\\|\\?\\*\\+\\(\\)]', 'gi'),
        // 匹配搜索的node
        showNodes: [],
        // 需要显示的node
        showList: [],
        // 需要隐藏的node
        hideList: [],
        // 精确搜索的数量
        isAccurateSum: 0,
        // 勾选置顶的树需要记录原始顺序
        treeDefIds: [],
      };
    },
    computed: {
      rootClass() {
        const { fuzzySearchUse, title, isOnlyShow, isShowCheck, setting } = this;
        return {
          'tree-top-search': fuzzySearchUse && !title,
          'tree-top-short': !fuzzySearchUse,
          'tree-top-no': !fuzzySearchUse && !title,
          'only-show': isOnlyShow || isShowCheck,
          'no-relation':
            setting &&
            setting.check &&
            setting.check.chkboxType &&
            !setting.check.chkboxType.Y &&
            !setting.check.chkboxType.N,
        };
      },
      placeholder() {
        return !this.isAccurate ? '请输入关键字进行过滤' : '请输入所有内容的完整信息进行匹配';
      },
      isEmpty() {
        const { setData, showNodes, isAccurate, isAccurateSum, keywords } = this;
        return (
          !setData.zNodes ||
          (setData.zNodes && setData.zNodes.length === 0) ||
          (((!showNodes.length && !isAccurate) || (!isAccurateSum && isAccurate)) && !!keywords)
        );
      },
      treeVisible() {
        const { showNodes, isAccurate, keywords, isAccurateSum } = this;
        return (
          (showNodes.length && !isAccurate && keywords) ||
          (isAccurateSum && isAccurate && keywords) ||
          !keywords
        );
      },
    },
    watch: {
      setData: {
        handler(newVal) {
          console.log('setData', newVal);
          this.init();
        },
        immediate: false, // 首次绑定是否执行handle
        deep: true, // 是否深度监听
      },
    },
    methods: {
      /**
       * 数据初始化
       */
      dealTreeSetting() {
        this.treeSetData = Object.assign(
          {
            data: {
              keep: {
                leaf: false, // zTree 的节点叶子节点属性锁，是否始终保持 isParent = false 如果设置为 true，则所有 isParent = false 的节点，都无法添加子节点。
                parent: false, // zTree 的节点父节点属性锁，是否始终保持 isParent = true 如果设置为 true，则所有 isParent = true 的节点，即使该节点的子节点被全部删除或移走，依旧保持父节点状态。
              },
              key: {
                // zTree 节点数据中保存 对应属性 对应名称。
                checked: 'checked',
                children: 'subNodes',
                isParent: 'isParent',
                isHidden: 'isHidden',
                name: 'name',
                title: '', // zTree 节点数据保存节点提示信息的属性名称
                url: 'url', // zTree 节点数据保存节点链接的目标 URL 的属性名称。
              },
              simpleData: {
                enable: false, //  true使用Array数据模式 / false为使用tree数据模式
                idKey: 'id', // 节点数据中保存唯一标识的属性名称 [setting.data.simpleData.enable = true 时生效]
                pIdKey: 'parentId', // 节点数据中保存其父节点唯一标识的属性名称。[setting.data.simpleData.enable = true 时生效]
                rootPId: null, // 用于修正根节点父节点数据，即 pIdKey 指定的属性值。[setting.data.simpleData.enable = true 时生效]
              },
            },
            check: {
              autoCheckTrigger: false, // 设置自动关联勾选时是否触发 beforeCheck / onCheck 事件回调函数
              chkboxType: { Y: 'ps', N: 'ps' }, // 勾选 checkbox 对于父子节点的关联关系 即勾选操作是否要影响父子节点
              chkStyle: 'checkbox', // 勾选框类型 setting.check.enable = true 时生效
              enable: true, // 设置 zTree 的节点上是否显示 checkbox / radio
              nocheckInherit: false, // 当父节点设置 nocheck = true 时，设置子节点是否自动继承 nocheck = true 。
              chkDisabledInherit: false, // 当父节点设置 chkDisabled = true 时，设置子节点是否自动继承 chkDisabled = true 。
              radioType: 'level', // radio 的分组范围
            },
            view: {
              dblClickExpand: false, // 取消双击展开
              showLine: false, // 不显示节点之间的连线。
              showIcon: false, // 不显示小图标
              nameIsHTML: true, // 允许name支持html
              selectedMulti: false, // 不允许同时选择多个节点
            },
          },
          this.setting,
          {
            callback: {
              onClick: this.onClick, // 节点被点击的事件回调函数
              onCheck: this.onCheck, // 捕获 checkbox / radio 被勾选 或 取消勾选的事件回调函数
            },
          },
          this.isOnlyShow || this.isShowCheck
            ? {
                // 是否是展示树
                check: { enable: false },
              }
            : {},
          !Helper.isEmpty(this.nodeKeyObj)
            ? {
                // node对应数据替换
                data: { key: this.nodeKeyObj },
              }
            : {}
        );
        // 获取children的对应key
        this.childrenKey = this.treeSetData.data.key.children;
        // 设置树的基础数据
        this.zNodes =
          (this.isOnlyShow ||
          !this.setData.zNodes ||
          (this.setData.zNodes && this.setData.zNodes.length === 0)
            ? this.setData.zNodes || []
            : this.filterZNodes(this.setData.zNodes, this.setData.selectData)) || []; // 树基础数据
        this.$emit('hideLoading'); // 处理完毕去除遮罩
      },
      /**
       * 把树结构的数据转化成array
       */
      getTreeToArray(treeArray) {
        this.arr = [];
        if (treeArray && treeArray.length) {
          if (typeof treeArray[0] === 'object') {
            treeArray.forEach(item => {
              this.getTreeToArrayEach(item);
            });
          } else {
            this.arr = Helper.cloneDeep(treeArray);
          }
        }

        return this.arr;
      },
      /**
       * 把树结构的数据转化成array（用于递归）
       */
      getTreeToArrayEach(node) {
        if (node[this.childrenKey]) {
          node[this.childrenKey].forEach(item => this.getTreeToArrayEach(item));
        }
        node[this.childrenKey] = null;
        // let str = node.id + ":" + node.parentId
        let str = node.id;
        this.arr.push(str);
        // this.arr.push(node)
      },
      /**
       * 将初始化的树数据和已经选择的树数据融合
       */
      filterZNodes(treeNodes, selectNode) {
        const selectArray = this.getTreeToArray(selectNode);
        return treeNodes.map(node => this.filterZNode(node, selectArray));
      },
      /**
       *  用于遍历树数据的递归，（判断节点是否选择，选择的把checked置为true）
       */
      filterZNode(node, selectArray) {
        if (node[this.childrenKey] && node[this.childrenKey].length) {
          node[this.childrenKey].map(node1 => this.filterZNode(node1, selectArray));
        } else {
          node[this.childrenKey] = null;
        }
        // 如果是仅仅展示的时候，则需隐藏没有选择的节点
        if (this.isShowCheck) {
          // 子节点不在选择节点中 需要隐藏
          if (!node[this.childrenKey] && Helper.indexOf(selectArray, node.id) === -1) {
            node.isHidden = true;
          }
          // 父节点下所有子节点都隐藏了，则隐藏该父节点
          if (
            node[this.childrenKey] &&
            Helper.findIndex(node[this.childrenKey], item => !item.isHidden) === -1
          ) {
            node.isHidden = true;
          }
        } else {
          // 如果是正常勾选
          if (!node[this.childrenKey] && Helper.indexOf(selectArray, node.id) > -1) {
            node.checked = true;
          }
          // 如果是多选的树,并且影响父级的话，默认勾选上上层节点
          if (
            node[this.childrenKey] &&
            !node.checked &&
            Helper.findIndex(node[this.childrenKey], item => item.checked) > -1 &&
            this.treeSetData.check.chkStyle === 'checkbox' &&
            this.treeSetData.check.chkboxType.Y.indexOf('p') > -1 &&
            this.treeSetData.check.chkboxType.N.indexOf('p') > -1
          ) {
            node.checked = true;
          }
          // 如果是多选的树,并且不影响父级的话，如果有选择的值就勾选选择的值
          if (
            !node.checked &&
            Helper.indexOf(selectArray, node.id) > -1 &&
            this.treeSetData.check.chkStyle === 'checkbox' &&
            this.treeSetData.check.chkboxType.Y.indexOf('p') === -1 &&
            this.treeSetData.check.chkboxType.N.indexOf('p') === -1
          ) {
            node.checked = true;
          }
          // 如果是单选的树，默认勾选上上层节点
          if (
            Helper.indexOf(selectArray, node.id) > -1 &&
            this.treeSetData.check.chkStyle === 'radio'
          ) {
            node.checked = true;
          }
        }
        return node;
      },
      /**
       * 树初始化
       */
      buildTree() {
        console.log('buildTree', this.treeSetData);
        if (this.zNodes && this.zNodes.length > 0) {
          this.zTree = jQuery.fn.zTree.init(jQuery('#' + this.id), this.treeSetData, this.zNodes);
          let setData = this.onCheckCount();
          this.$emit('treeChange', setData); // 初始化完成后向调用父组件吐一次数据
        } else if (this.zTree) {
          this.zTree.destroy();
        }
      },
      // 单击折叠
      onClick(e, treeId, treeNode) {
        console.log('onClick', treeNode);
        this.zTree.expandNode(treeNode);
        this.$emit('treeClick', treeNode);
      },
      /**
       * 勾选的回调
       */
      onCheck(e, treeId, treeNode) {
        // 如果是单选，则不能取消
        if (
          this.treeSetData.check.chkStyle === 'radio' &&
          !treeNode.checked &&
          !this.isRadioCanCancel
        ) {
          this.zTree.checkNode(treeNode, true);
          return;
        }
        if (this.relatoinView) {
          if (treeNode.checked && treeNode.name !== '查看') {
            this.relatoinViewCheck(treeNode); // 联动勾选'查看'
          } else if (!treeNode.checked && treeNode.name === '查看') {
            this.viewDeselected(treeNode); // '查看'是否可以取消
          }
        }
        // 父子不关联的树 要关联子节点
        if (
          this.isRelateChild &&
          this.treeSetData.check.chkboxType.Y === '' &&
          this.treeSetData.check.chkboxType.N === '' &&
          treeNode.checked
        ) {
          this.relChildCheck(treeNode);
          this.zTree.expandNode(treeNode, true, true, true);
        }
        let setData = this.onCheckCount();
        this.$emit('treeCheck', setData);
      },
      /**
       * 关联该节点所有子节点勾选上
       */
      relChildCheck(treeNode) {
        const child = treeNode[this.childrenKey] || [];
        child.forEach(node => {
          this.zTree.checkNode(node, true, true);
          this.relChildCheck(node);
        });
      },
      /**
       * 关联同级查看
       */
      viewDeselected(treeNode) {
        const zTree = jQuery.fn.zTree.getZTreeObj(this.id);
        let children = this.childrenKey;
        let pathNode = treeNode.getPath();
        if (pathNode.length > 1) {
          let num = 0;
          for (let i = 0; i < pathNode[pathNode.length - 2][children].length; i++) {
            if (pathNode[pathNode.length - 2][children][i].checked) {
              num = 1;
              break;
            }
          }
          if (num) {
            zTree.checkNode(treeNode, true, true);
          }
        }
      },
      relatoinViewCheck(treeNode) {
        const zTree = jQuery.fn.zTree.getZTreeObj(this.id);
        let children = this.childrenKey;
        let pathNode = treeNode.getPath();
        if (pathNode.length > 1) {
          for (let i = pathNode.length - 2; i >= 0; i--) {
            for (let j = 0; j < pathNode[i][children].length; j++) {
              if (pathNode[i][children][j].name === '查看') {
                zTree.checkNode(pathNode[i][children][j], true, true);
              }
            }
          }
        }
      },
      /**
       * 获取ztree相关数据
       */
      onCheckCount() {
        console.log('ztree onCheckCount');
        let setData = null;
        if (this.zTree) {
          const nodeAll = this.zTree.getNodes(); // 当前树的所有node（tree类型）
          // const checkDataAll = this.zTree.getCheckedNodes(true) // 当前所有选择的节点
          const checkDataAll = this.zTree.getNodesByFilter(node => {
            return node.checked;
          }); // 当前所有选择的节点（包括隐藏的节点）
          const checkDataChild = checkDataAll.filter(item => Helper.isEmpty(item.subNodes)); // 当前所有选择的子节点
          const checkDataIds = checkDataChild.map(item => item.id); // 当前所有选择的子节点id的集合数组
          const allDateWithoutHalfIds = checkDataAll
            .filter(item => {
              let flag = true;
              if (
                !Helper.isEmpty(item[this.childrenKey]) &&
                item.getCheckStatus() &&
                item.getCheckStatus().half
              ) {
                flag = false;
              }
              return flag;
            })
            .map(item => item.id);
          const noCheckData = this.zTree.getCheckedNodes(false);
          if (nodeAll.length > 0 && noCheckData.length === 0 && checkDataAll.length !== 0) {
            this.allCheckShow = true;
          } else {
            this.allCheckShow = false;
          }
          setData = {
            nodeAll, // 当前树的所有node（tree类型）
            checkDataAll, // 当前所有选择的节点
            checkDataChild, // 当前所有选择的子节点
            checkDataIds, // 当前所有选择的子节点的id的数组集合
            allDateWithoutHalfIds, // 当前所有选择的节点，去除半选的父节点
            selectAll: this.allCheckShow, // 是否已经全选
          };
        }
        return setData || {};
      },
      /**
       * 全选/取消全选 点击事件
       */
      handleCheckAll() {
        if (!this.zTree) {
          return;
        }
        if (this.allCheckShow) {
          this.zTree.checkAllNodes(false);
          this.allCheckShow = false;
        } else {
          this.zTree.checkAllNodes(true);
          this.allCheckShow = true;
        }
        let setData = this.onCheckCount();
        if (this.setData.type || this.setData.type === 0) {
          setData.type = this.setData.type;
        }
        this.$emit('treeCheck', setData);
      },
      /**
       * 搜索
       */
      handleSearch() {
        this.keywords = this.$refs.searchInput.value;
        this.searchNodeLazy(); // call lazy load
      },
      searchNodeLazy() {
        this.searchLoad = true;
        if (this.timeoutId) {
          // clear pending task
          clearTimeout(this.timeoutId);
        }
        const _this = this;
        this.timeoutId = setTimeout(() => {
          if (_this.lastKeyword === _this.keywords) {
            _this.searchLoad = false;
            return;
          }
          _this.ztreeFilter(); // lazy load ztreeFilter function
          _this.lastKeyword = this.keywords;
        }, 100);
      },
      ztreeFilter() {
        // 初始化部分数据
        this.sum = 0;
        this.isAccurateSum = 0;
        this.time = 0;
        this.showList = [];
        this.hideList = [];

        this.showNodes =
          this.keywords && this.zTree
            ? this.zTree.getNodesByParamFuzzy(this.nameKey, this.keywords, null)
            : [];

        // 如果是精准搜索并且恢复没有搜索条件，拿此时树的数据，重新构建树
        if (this.isAccurate && !this.keywords) {
          this.dealAccurateNoSearch();
        } else if (!this.keywords || this.showNodes.length) {
          this.dealSearchNodes();
        }

        // 全选/取消显示正常
        if (this.selectAll) {
          this.$emit('treeChange', this.onCheckCount());
        }

        this.searchLoad = false;
      },
      /**
       * 暂无数据页面返回按钮点击事件
       */
      searchBack() {
        this.$refs.searchInput.value = '';
        this.keywords = '';
        this.showNodes = [];
        this.searchNodeLazy();
      },
      /**
       * 如果是精准搜索并且恢复没有搜索条件，拿此时树的数据，重新构建树
       */
      dealAccurateNoSearch() {
        const nodes = this.zTree.getNodes();
        const newNodes = nodes.map(node => {
          return this.showAllTreeNodes(node);
        });
        this.zTree = jQuery.fn.zTree.init(jQuery('#' + this.id), this.treeSetData, newNodes);
      },
      /**
       * 处理树的数据用于重新构建
       */
      showAllTreeNodes(node) {
        let children = null;
        if (node[this.childrenKey]) {
          children = node[this.childrenKey].map(node1 => this.showAllTreeNodes(node1));
        }
        return {
          id: node.id,
          [this.nameKey]: node[this.nameKey],
          parentId: node.parentId,
          [this.childrenKey]: children,
          checked: node.checked,
        };
      },
      /**
       * 处理所有树节点
       */
      dealSearchNodes() {
        if (this.zTree) {
          const treeNodes = this.zTree.getNodes();
          treeNodes.forEach(node => this.dealNode(node));
          this.zTree.hideNodes(this.hideList);
          this.zTree.showNodes(this.showList);
        }
      },
      /**
       * 处理某个节点隐藏或显示
       * @param {*} node 树节点的node数据
       * @param {*} isShow 父节点是否显示
       */
      dealNode(node, isShow) {
        // 高亮还原（需先恢复节点）
        // this.zTree.updateNode(node)

        this.sum++;
        // 如果搜索为空，直接把所有节点都显示
        if (!this.keywords) {
          // if (this.isAccurate) {
          //     this.isAccurateSum++
          // }
          if (node[this.childrenKey] && node[this.childrenKey].length) {
            node[this.childrenKey].forEach(node1 => this.dealNode(node1));
          }
          if (node.isHidden) {
            this.showList.push(node);
          }
          return;
        }
        // 判断子节点 显示与否
        let childShowFlag = false;
        // 判断当前节点 显示与否
        let showFlag = false;
        // 判断当前节点是否需显示
        // 模糊搜索
        if (
          !this.isAccurate &&
          (node[this.nameKey] || '').toLowerCase().indexOf(this.keywords.toLowerCase()) > -1
        ) {
          showFlag = true;
        }
        // 精确搜索
        if (this.isAccurate && node[this.nameKey] === this.keywords) {
          this.isAccurateSum++;
          showFlag = true;
        }

        if (node[this.childrenKey] && node[this.childrenKey].length) {
          node[this.childrenKey].forEach(node1 => {
            if (this.dealNode(node1, showFlag || isShow)) {
              childShowFlag = true;
            }
          });
        }

        // 展开对应的节点
        if (showFlag) {
          const pNode = node.getParentNode();
          if (pNode && !pNode.open) {
            const start = new Date().getTime();
            this.zTree.expandNode(pNode, true, false, false);
            const end = new Date().getTime();
            this.time += end - start;
          }
        }

        // 高亮(需要在展开父节点之后，不然更新不了对应节点)
        // const highNode = this.highlightNode(node, showFlag)
        // if (highNode.oldname && highNode.oldname !== highNode[this.nameKey]) {
        //     this.zTree.updateNode(highNode)
        // }

        // 子节点需显示 / 当前节点需显示 则判断此节点显示状态 如果是隐藏，则调用show方法显示节点
        if ((showFlag || childShowFlag || isShow) && node.isHidden) {
          this.showList.push(node);
        } else if (!showFlag && !childShowFlag && !isShow && !node.isHidden) {
          // 子节点不需显示 / 当前节点不需显示 则判断此节点显示状态 如果是显示，则调用hide方法隐藏节点
          this.hideList.push(node);
        }
        return showFlag || childShowFlag;
      },
      /**
       * 搜索高亮
       */
      // highlightNode (nodeInfo, curShowFlag) {
      //     const node = _.cloneDeep(nodeInfo)
      //     if (node[this.nameKey] && this.keywords && curShowFlag) {
      //         const newKeywords = this.keywords.replace(this.rexMeta, (matchStr) =>
      //             '\\' + matchStr
      //         )
      //         node.oldname = node[this.nameKey]
      //         const rexGlobal = new RegExp(newKeywords, 'gi')
      //         node[this.nameKey] =
      //             node.oldname.replace(rexGlobal,
      //                 (originalText) => '<span style="padding-left:2px;padding-right:2px;color: whitesmoke;background-color: #4D8CFD;">' + originalText + '</span>')
      //     }
      //     return node
      // }
      init() {
        // 树数据处理
        this.dealTreeSetting();
        // 记录树的初始位置
        // if (this.checkNeedTop) {
        //     this.treeDefIds = this.setData.zNodes.map((item) => item.id)
        // }
        if (this.$refs.searchInput && this.$refs.searchInput.value) {
          this.searchBack();
        }
        this.$nextTick(() => {
          this.buildTree();
          // 树默认展开的层级
          if (this.setData.expandAll) {
            const zTree = jQuery.fn.zTree.getZTreeObj(this.id); // get the ztree object by ztree id
            if (this.setData.expandAll === 'all') {
              zTree.expandAll(true);
            } else if (this.setData.expandAll > 0) {
              for (let i = 0; i < this.setData.expandAll; i++) {
                let rootNodes = zTree.getNodesByParam('level', i); // get all root nodes
                for (let j = 0; j < rootNodes.length; j++) {
                  zTree.expandNode(rootNodes[j], true, false, false);
                }
              }
            }
          }
        });
      },
      /**
       * 屏蔽回车事件
       */
      keyDown(e) {
        if (e.keyCode === 13) {
          e.returnValue = false;
        }
      },
    },
    mounted() {
      this.init();
    },
  };
</script>

<style lang="scss">
  @import './ztree.scss';
  @import './index.scss';
</style>
