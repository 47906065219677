/*
 * @Author: Daokai Huo
 * @Date: 2021-10-21 16:15:04
 * @LastEditTime: 2021-10-21 16:17:27
 * @Description:
 */
import YkcSearch from './src/ykc-search.vue';

YkcSearch.install = function install(_vue) {
  _vue.component(YkcSearch.name, YkcSearch);
};
export default YkcSearch;
