import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    downloadDialog: false, // 下载中心弹窗
    logo: '', // logo
    loginLogo: '', // loginLogo
    denyListCurrentStationIds: [], // 用户黑名单当前电站IDs
    dictionary: JSON.parse(sessionStorage.dictionary || '{}'),
  },
  getters,
  actions,
  mutations,
});
export default store;
