<template>
  <ykc-form :model="data" ref="form" class="group-wrap" :rules="Rules" v-bind="attrs">
    <slot></slot>
  </ykc-form>
</template>

<script>
  export default {
    name: 'YkcGroupFormItem',
    props: {
      data: {
        type: Object,
      },
      rules: {
        type: Object,
        default() {
          return {};
        },
      },
      index: {
        type: Number,
      },
    },
    computed: {
      attrs() {
        return this.$attrs;
      },
      Rules() {
        return Object.fromEntries(
          Object.entries(this.rules).map(([name, rules]) => {
            return [
              name,
              rules.map(o => {
                if (o.validator) {
                  return {
                    ...o,
                    validator: (rule, value, callback) => {
                      o.validator(rule, value, callback, this.data, this.index);
                    },
                  };
                }
                return o;
              }),
            ];
          })
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .group-wrap {
    flex-grow: 1;
  }
</style>
