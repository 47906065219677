import { code } from '@/utils';
/**
 * v-rbac="'sys:edit'" || v-rbac="['sys:edit', 'sys:start']"
 */
export default function install(Vue) {
  Vue.directive('rbac', {
    // eslint-disable-next-line
    inserted: function (el, binding) {
      const { value } = binding;
      if (value) {
        const hasPermission = value instanceof Array ? value.some(key => code(key)) : code(value);
        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
    },
  });
}
