const { hasOwnProperty } = Object.prototype;

export function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}
export function hasOwn(obj, key) {
  return hasOwnProperty.call(obj, key);
}
export function isVNode(node) {
  return node !== null && typeof node === 'object' && hasOwn(node, 'componentOptions');
}
export function merge(target) {
  for (let i = 1, j = arguments.length; i < j; i++) {
    // eslint-disable-next-line prefer-rest-params
    const source = arguments[i] || {};
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in source) {
      // eslint-disable-next-line no-prototype-builtins
      if (source.hasOwnProperty(prop)) {
        const value = source[prop];
        if (value !== undefined) {
          target[prop] = value;
        }
      }
    }
  }

  return target;
}
