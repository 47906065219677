const getters = {
  /**
   * 获取数据字典
   */
  getDic(state) {
    return state.dictionary;
  },
  /**
   * 获取字典值并过滤
   * key 字典的key
   * ids Array 需要过滤的字典id
   */
  getItemByFilter(state) {
    return (key, ids) => {
      if (ids) {
        return state.dictionary[key].filter(item => !ids.includes(item.id));
      }
      return state.dictionary[key];
    };
  },
  getDownloadDialog(state) {
    return state.downloadDialog;
  },
  getLogo(state) {
    return state.logo;
  },
  getLoginLogo(state) {
    return state.loginLogo;
  },
  getDenyListCurrentStationIds(state) {
    return state.denyListCurrentStationIds;
  },
};
export default getters;
