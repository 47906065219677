<!--
 * @Descripttion:
 * @Author: 汪佳彪
 * @date: 2021-10-26 16:09
-->
<script>
  /**
   * copy from element-ui 2.15.6
   */
  import { on, off, BAR_MAP } from './util';

  /* istanbul ignore next */
  export default {
    name: 'YkcTreeBar',

    props: {
      vertical: Boolean,
      size: Number,
      minSize: {
        type: Number,
        default: 32,
      },
      move: Number,
      moveRatio: Number,
      scrollbarSize: Object,
      gutter: Object,
    },

    computed: {
      bar() {
        return BAR_MAP[this.vertical ? 'vertical' : 'horizontal'];
      },
      scrollbarStyles() {
        const {
          scrollbarSize,
          gutter: { horizontal, vertical },
        } = this;
        if (this.vertical) {
          return {
            height: `calc(100% - ${horizontal}px)`,
            width: `${scrollbarSize.vertical}px`,
            top: '0',
            right: `${vertical}px`,
          };
        }
        return {
          width: `calc(100% - ${vertical}px)`,
          height: `${scrollbarSize.horizontal}px`,
          left: '0',
          bottom: `${horizontal}px`,
        };
      },
      thumbStyles() {
        const { size, move, bar } = this;
        const { sizeProp, axis } = bar;
        const oppositeSizeProp = sizeProp === 'height' ? 'width' : 'height';
        return {
          [sizeProp]: `${size}px`,
          [oppositeSizeProp]: '100%',
          transform: `translate${axis}(${move}px)`,
        };
      },
      hackStyles() {
        const { size, minSize, moveRatio, bar } = this;
        const { sizeProp, axis } = bar;
        const oppositeSizeProp = sizeProp === 'height' ? 'width' : 'height';
        return {
          [sizeProp]: `${minSize}px`,
          [oppositeSizeProp]: '100%',
          transform: `translate${axis}(-${moveRatio * (minSize - size)}px)`,
        };
      },
    },
    created() {
      this.wrap = this.$parent.$refs.virtualWrapper;
    },

    render() {
      // eslint-disable-next-line
      const { size, minSize, bar, scrollbarStyles, thumbStyles, hackStyles } = this;
      const { key } = bar;
      return (
        <div
          class={['scrollbar', `${key}`]}
          style={scrollbarStyles}
          onMousedown={this.clickTrackHandler}>
          <div
            ref="thumb"
            class={{ thumb: true, virtual: size < minSize && size > 0 }}
            style={thumbStyles}
            onMousedown={this.clickThumbHandler}>
            {size < minSize && size > 0 ? <div class="hack" style={hackStyles}></div> : null}
          </div>
        </div>
      );
    },

    methods: {
      clickThumbHandler(e) {
        // prevent click event of right button
        if (e.ctrlKey || e.button === 2) {
          return;
        }
        this.startDrag(e);
        this[this.bar.axis] =
          e.currentTarget[this.bar.offset] -
          (e[this.bar.client] - e.currentTarget.getBoundingClientRect()[this.bar.direction]);
      },

      clickTrackHandler(e) {
        const offset = Math.abs(
          e.target.getBoundingClientRect()[this.bar.direction] - e[this.bar.client]
        );
        const thumbHalf = this.$refs.thumb[this.bar.offset] / 2;
        const thumbPositionPercentage = ((offset - thumbHalf) * 100) / this.$el[this.bar.offset];

        const scrollLength = (thumbPositionPercentage * this.wrap[this.bar.scrollSize]) / 100;
        const { scrollTop, scrollLeft } = this.wrap;
        if (this.vertical) {
          this.$parent.scrollTo(scrollLeft, scrollLength);
        } else {
          this.$parent.scrollTo(scrollLength, scrollTop);
        }
      },

      startDrag(e) {
        e.stopImmediatePropagation();
        this.cursorDown = true;
        on(document, 'mousemove', this.mouseMoveDocumentHandler);
        on(document, 'mouseup', this.mouseUpDocumentHandler);
        document.onselectstart = () => false;
      },

      mouseMoveDocumentHandler(e) {
        if (this.cursorDown === false) return;
        const prevPage = this[this.bar.axis];
        if (!prevPage) return;

        const offset =
          (this.$el.getBoundingClientRect()[this.bar.direction] - e[this.bar.client]) * -1;
        const thumbClickPosition = this.$refs.thumb[this.bar.offset] - prevPage;
        const thumbPositionPercentage =
          ((offset - thumbClickPosition) * 100) / this.$el[this.bar.offset];
        const scrollLength = (thumbPositionPercentage * this.wrap[this.bar.scrollSize]) / 100;
        const { scrollTop, scrollLeft } = this.wrap;
        if (this.vertical) {
          this.$parent.scrollTo(scrollLeft, scrollLength);
        } else {
          this.$parent.scrollTo(scrollLength, scrollTop);
        }
      },

      mouseUpDocumentHandler() {
        this.cursorDown = false;
        this[this.bar.axis] = 0;
        off(document, 'mousemove', this.mouseMoveDocumentHandler);
        document.onselectstart = null;
      },
    },

    destroyed() {
      off(document, 'mouseup', this.mouseUpDocumentHandler);
    },
  };
</script>

<style lang="scss">
  .scrollbar {
    position: absolute;
    z-index: 1;
    opacity: 0;
    border-radius: 4px;
    transition: opacity 120ms ease-out;
    .thumb {
      position: relative;
      display: block;
      width: 0;
      height: 0;
      cursor: pointer;
      border-radius: inherit;
      background-color: rgba(144, 147, 153, 0.3);
      transition: 0.3s background-color;
      &.virtual {
        background-color: rgba(144, 147, 153, 0);
        &:hover {
          background-color: rgba(144, 147, 153, 0);
          .hack {
            background-color: rgba(144, 147, 153, 0.5);
          }
        }
      }
      .hack {
        position: absolute;
        left: 0;
        background-color: rgba(144, 147, 153, 0.3);
        // opacity: 0.5;
        top: 0;
        border-radius: inherit;
      }
      &:hover {
        background-color: rgba(144, 147, 153, 0.5);
        .hack {
          background-color: rgba(144, 147, 153, 0.5);
        }
      }
    }
  }
</style>
