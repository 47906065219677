<template>
  <div class="ykc-card-page">
    <div v-if="data && data.length > 0">
      <div class="item" v-for="(item, index) in data" :key="index">
        <div class="head-box weight-syr">
          <div class="head-left" v-if="headLeft.propFun" v-html="headLeft.propFun(item)"></div>
          <div class="head-left" v-else>{{ `${headLeft.label}${item[headLeft.key]}` || '——' }}</div>
          <div class="weight-syl left" v-if="headTag">
            <div v-for="(titem, tindex) in headTag" :key="tindex" class="tag">
              <div v-if="typeof titem === 'string'" class="tag-test">
                <span>{{ item[titem] }}</span>
              </div>
              <div v-else v-html="titem.propFun(item)"></div>
            </div>
          </div>
          <div class="head-right weight-syl" v-if="Object.keys(headRight).length > 0">
            <div v-if="headRight.propFun" v-html="headRight.propFun(item)"></div>
            <div v-else>{{ headRight.label }}{{ item[headRight.key] }}</div>
          </div>
        </div>
        <div class="sub-title weight-syl" v-if="Object.keys(subTitle).length > 0">
          <div v-if="subTitle.propFun" v-html="subTitle.propFun(item)"></div>
          <div v-else>{{ subTitle.label }}{{ item[subTitle.key] }}</div>
        </div>
        <div class="info-box">
          <div class="info weight-syl" :class="{ 'info-pics': Object.keys(pics).length > 0 }">
            <div class="info-col pic-box" v-if="Object.keys(pics).length > 0">
              <!-- <img
                class="pic-img"
                :src="item[pics.url] || 'https://wx.ykccn.com/img-ui/card-no-img.png'" /> -->
              <viewer :images="getImgs(item, pics.url)">
                <img
                  class="pic-img"
                  v-for="img in getImgs(item, pics.url)"
                  :src="img.url"
                  :key="img.title" />
              </viewer>
              <div class="pic-test" v-if="pics.key">{{ `${pics.label}${item[pics.key]}` }}</div>
            </div>
            <div class="info-col-box" v-for="(col, cinx) in columns" :key="cinx">
              <div class="info-col" :class="{ 'info-label': cinx > 0 }">
                <div class="info-row" v-for="(colItem, colInx) in col" :key="colInx">
                  <div>{{ colItem.label }}</div>
                </div>
              </div>
              <div
                class="info-col"
                :style="{ width: widthArr && widthArr[cinx] ? `${widthArr[cinx]}px` : 'null' }">
                <div class="info-row info-value" v-for="(colItem, colInx) in col" :key="colInx">
                  <div v-if="colItem.propFun" v-html="colItem.propFun(item)"></div>
                  <div v-else>
                    {{ item[colItem.key] || '——' }}
                    {{ item[colItem.key] && colItem.unit ? `${colItem.unit}` : '' }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="btns" class="btn-box">
              <ykc-button
                class="bnt"
                v-for="(bntItem, bntInx) in btns"
                :class="bntItem.buttonType || []"
                :key="bntInx"
                :type="'text'"
                :btnIcon="bntItem.icon"
                :disabled="
                  bntItem.disabledFunc ? bntItem.disabledFunc(item) : item[bntItem.disabled]
                "
                @click="handleClick(item, bntItem, index, bntInx)">
                {{ bntItem.propFunc ? bntItem.propFunc(item) : bntItem.text }}
              </ykc-button>
            </div>
          </div>
        </div>
      </div>
      <ykc-pagination
        class="pagination-box"
        slot="pagination"
        align="right"
        :total="total"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"></ykc-pagination>
    </div>
    <div v-else-if="isShowNo" class="no-data">
      <div class="pic"></div>
      <div class="test">暂无数据</div>
    </div>
  </div>
</template>

<script>
  import XEUtilsArray from 'xe-utils/array';
  import YkcButton from '@/components/ykc-ui/button';
  import YkcPagination from '@/components/ykc-ui/pagination';
  import cardNoImg from '@/components/ykc-ui/assets/card-no-img.png';

  export default {
    name: 'YkcCardPage',
    components: { YkcButton, YkcPagination },
    props: {
      data: {
        // 数据源
        type: Array,
        default: () => [],
      },
      headLeft: {
        // 头部左边信息 {label:'',key:'',propFun:()}
        type: Object,
        default: () => ({}),
      },
      headRight: {
        // 头部右边信息 {label:'',key:'',propFun:()}
        type: Object,
        default: () => ({}),
      },
      headTag: {
        // 头部标签信息 [key1,key2,...] or [{propFun:()}]
        type: Array,
        default: () => [],
      },
      subTitle: {
        // 副标题信息 {label:'',key:'',propFun:()}
        type: Object,
        default: () => ({}),
      },
      rowNumber: {
        // 每一列几行
        type: Number,
        default: 3,
      },
      info: {
        // 列表列内容
        // {label:'',key:'',propFun:(),unit:''}
        type: Array,
        default: () => [],
      },
      pics: {
        // 图片列
        // {label:'',key:'',url:''}
        type: Object,
        default: () => ({}),
      },
      btns: {
        // 按钮数据源
        // {text:'',icon:''}
        type: Array,
        default: () => [],
      },
      widthArr: {
        // 每列宽度
        type: Array,
        default: () => [],
      },
      /** 每页显示条目个数 */
      pageSize: {
        type: Number,
        default: 10,
      },
      /** 当前页数 */
      currentPage: {
        type: Number,
        default: 1,
      },
      /** 总条目数 */
      total: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        columns: [], // 按照行分数组后的数据源
        isShowNo: false, // 是否显示无数据
      };
    },
    created() {
      this.columns = XEUtilsArray.chunk(this.info, this.rowNumber);
    },
    watch: {
      data: {
        handler() {
          this.isShowNo = true;
        },
      },
    },
    methods: {
      getImgs(item, url) {
        const arr = [];
        arr.push({ url: item[url] || cardNoImg });
        return arr;
      },
      /**
       * 点击事件
       * @param item 行数据
       * @param  btn 按钮数据
       * @param  rowIndex 行下标
       * @param  btnIndex 按钮的索引即操作里面的按钮第 index 个
       */
      handleClick(item, btn, rowIndex, btnIndex) {
        this.$emit('item-btn-click', item, btn, rowIndex, btnIndex);
      },
      handleSizeChange(val) {
        this.$emit('update:pageSize', val);
        this.$emit('size-change', val);
      },
      handleCurrentChange(val) {
        this.$emit('update:currentPage', val);
        this.$emit('current-change', val);
      },
    },
  };
</script>

<style lang="scss">
  @import '~@/components/ykc-ui/styles/var.scss';
  .ykc-card-page {
    position: relative;
    min-height: 180px;
  }
  .ykc-card-page .item {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 12px;
    box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
    border: none;

    .head-box {
      display: inline-block;
      width: 100%;
      font-size: 14px;
      color: $_000000;
      text-align: left;

      .head-left {
        margin: 14px 0 0 14px;
        float: left;
      }

      .tag {
        float: left;
        // margin-left: 8px;
      }

      .tag-test {
        position: relative;
        height: 20px;
        min-width: 6px;
        line-height: 20px;
        margin: 14px 0 0 8px;
        padding: 1px 10px;
        font-size: 12px;
        color: $theme_primary;
        text-align: center;
      }

      .tag-test::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: $theme_primary;
        opacity: 0.08;
        border-radius: 2px;
      }

      .head-right {
        height: 20px;
        line-height: 20px;
        float: right;
        padding: 0 10px;
        text-align: center;
        font-size: 12px;
        color: $_4A4A4A;
        background-color: rgba(245, 166, 35, 0.08);
        border-bottom-left-radius: 8px;
      }
    }

    .sub-title {
      position: relative;
      display: inline-block;
      height: 17px;
      line-height: 17px;
      margin-left: 30px;
      font-size: 12px;
      color: $theme_primary;
      text-align: left;
    }

    .sub-title::after {
      position: absolute;
      content: '·';
      top: 0;
      left: -8px;
    }

    .info-box {
      display: flex;
    }

    .info {
      display: inline-block;
      flex: 1;
      width: calc(100% - 60px);
      height: auto;
      margin: 0 30px 14px 30px;
      text-align: left;
      font-size: 12px;
      color: $_000000;
    }

    .info-pics {
      width: calc(100% - 30px);
      margin-left: 0;
    }

    .info-col-box {
      display: flex;
      float: left;
    }

    .info-col {
      float: left;
    }

    .info-label {
      margin-left: 30px;
    }

    .info-row {
      height: 17px;
      line-height: 17px;
      margin-top: 8px;
      white-space: nowrap;
    }

    .info-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .pic-img {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      cursor: pointer;
    }

    .pic-box {
      width: 112px;
      margin-top: 8px;
      text-align: center;
    }

    .pic-test {
      text-align: center;
      color: $_9B9B9B;
      transform: scale(0.8333333333);
    }

    .btn-box {
      display: flex;
      height: 100%;
      float: right;
      margin: auto 30px auto 20px;
    }

    .bnt {
      margin-top: -8px;
      margin-left: 40px;
    }
  }

  .ykc-card-page .item:hover {
    cursor: pointer;
    box-shadow: 0 4px 6px 0 #d3d6dd;
  }

  /** 分页 */
  .ykc-card-page .pagination-box {
    padding: 10px 0 60px 0;
  }

  /** 无数据 */
  .ykc-card-page .no-data {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    min-height: 120px;
    margin-top: -60px;
    .pic {
      width: 100px;
      height: 100px;
      background-image: url('~@/components/ykc-ui/assets/no-data.png');
      background-size: contain;
    }
    .test {
      font-size: 14px;
      color: #88b0ff;
      text-align: center;
    }
  }
  @media (max-width: 1366px) {
    .ykc-card-page .item {
      .tag-test {
        padding-left: 10px;
        padding-right: 10px;
      }
      .tag-test span {
        display: inline-block;
        transform: scale(0.8333333333);
      }

      .btn-box {
        margin: auto 0 auto 10px;
      }
      .bnt {
        margin-left: 20px;
      }
    }
  }

  body[ykc-ui='soui'] {
    .ykc-card-page .item {
      background: transparent
        linear-gradient(
          180deg,
          rgba(14, 95, 255, 0.14) 5%,
          rgba(21, 94, 223, 0) 56%,
          rgba(14, 95, 255, 0.14) 100%
        );
      &:hover {
        background-image: linear-gradient(
          180deg,
          rgba(14, 95, 255, 0.53) 0%,
          rgba(21, 94, 223, 0) 39%,
          rgba(21, 94, 225, 0.03) 59%,
          rgba(14, 95, 255, 0.53) 100%
        );
        box-shadow: none;
      }
    }

    .ykc-card-page {
      .item {
        .head-box {
          .head-left {
            color: $_FFFFFF;
          }
          .tag-test {
            color: #00fff4;
          }
          .tag-test:after {
            background: url('~@/components/ykc-ui/assets/soui/card-page-header-label-bg@2x.png')
              no-repeat;
            background-size: 100% 100%;
            opacity: 1;
          }
          .head-right {
            background-image: linear-gradient(
              180deg,
              rgba(73, 181, 251, 0.2) 0%,
              rgba(6, 65, 255, 0.2) 5%,
              rgba(0, 255, 247, 0.2) 100%
            );
            //border-radius: 8px;
            border-bottom-left-radius: 8px;
            border-top-left-radius: 8px;
            color: $_FFFFFF;
          }
        }
        .info-box {
          .info-col.pic-box {
            .pic-test {
              color: $_FFFFFF;
            }
          }
        }
        .info-col-box {
          .info-row {
            color: $_FFFFFF;
          }
        }
      }
    }
    // 不同类型的按钮，颜色不同
    .ykc-button.el-button--text.add {
      color: #78a5ff;
    }
    .ykc-button.el-button--text.del {
      color: #fd4849;
    }
    .ykc-button.el-button--text.detail {
      color: #f4c519;
    }
  }
</style>
