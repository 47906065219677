<!--
 * @Descripttion: theme组件
 * @Author: 汪佳彪
 * @date: 2021-10-09 10:41
-->
<script>
  // 保存全部的theme组件实例
  let instances = [];

  export function getThemeInstances() {
    return instances;
  }

  export default {
    name: 'YkcTheme',
    provide() {
      return {
        YkcTheme: this,
      };
    },
    props: {
      target: {
        required: true,
        validator(val) {
          if (!val) return false;
          const { style } = val;
          if (!style || !(style instanceof Object)) {
            return false;
          }
          return ['setProperty', 'removeProperty'].every(methodName => !!val.style[methodName]);
        },
      },
      themes: {
        type: Object,
        // 切换主题: --theme-color-primary: #B620E0；--theme-color-active: #8B07B0
        default: () => ({
          '--theme-color-primary': '#4d8cfd',
          '--theme-color-active': '#386cc8',
        }),
        // 验证css变量定义
        validator(val) {
          return Object.keys(val).every(key => key.startsWith('--'));
        },
      },
    },
    created() {
      instances.push(this);
    },
    beforeDestroy() {
      // 移除全部css变量
      if (this.target) {
        Object.keys(this.themes).forEach(prop => {
          this.target.style.removeProperty(prop);
        });
      }
    },
    destroyed() {
      instances = instances.filter(vm => vm !== this);
    },
    watch: {
      themes: {
        // 增量添加css变量
        handler(newProps) {
          this.setTheme(newProps);
        },
        immediate: true,
        deep: true,
      },
    },
    methods: {
      /**
       * @description 设置主题
       */
      setTheme(props) {
        if (this.target) {
          Object.entries(props).forEach(([prop, value]) => {
            this.target.style.setProperty(prop, value);
          });
        }
      },
    },
    render() {
      return this.$slots.default;
    },
  };
</script>
