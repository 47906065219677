<template>
  <div class="ykcGroupForm">
    <template v-if="!fixDisabled">
      <div v-for="(item, index) in value" :key="index" class="combination-wrap">
        <div class="_item">
          <div
            v-if="showReduce"
            class="reduce"
            @click="reduce(index)"
            :class="{ 'icon-disabled': disabled || value.length === 1 }">
            <ykc-icon name="reduce"></ykc-icon>
          </div>
        </div>
        <YkcGroupFormItem ref="formItem" v-bind="itemOptions" :data="item" :index="index">
          <slot :scope="item" :index="index"></slot>
        </YkcGroupFormItem>
      </div>
    </template>
    <template v-else>
      <!-- eslint-disable vue/no-use-v-if-with-v-for -->
      <div
        v-for="(item, index) in value"
        :key="`list_${index}`"
        class="combination-wrap"
        v-if="!itemDisabledFun(item)">
        <div class="_item">
          <div
            v-if="showReduce"
            class="reduce"
            @click="reduce(index)"
            :class="{ 'icon-disabled': disabled || list.length === 1 }">
            <ykc-icon name="reduce"></ykc-icon>
          </div>
        </div>
        <YkcGroupFormItem ref="formItem" v-bind="itemOptions" :data="item" :index="index">
          <slot :scope="item" :index="index"></slot>
        </YkcGroupFormItem>
      </div>
      <div
        v-for="(item, index) in disabledList"
        :key="`disabledList_${index}`"
        class="combination-wrap">
        <div class="_item">
          <div v-if="showReduce" class="reduce icon-disabled">
            <ykc-icon name="reduce"></ykc-icon>
          </div>
        </div>
        <YkcGroupFormItem ref="formItem" v-bind="itemOptions" :data="item" :index="index">
          <slot :scope="item" :index="index"></slot>
        </YkcGroupFormItem>
      </div>
    </template>
    <div v-if="canShowAdd">
      <div v-if="$slots.add" style="display: inline-block" @click="add">
        <slot name="add"></slot>
      </div>
      <div v-else @click="add">{{ addText }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'YkcGroupForm',
    props: {
      /** 添加按钮文本内容 */
      addText: {
        type: String,
        default: '添加',
      },
      /** 绑定的数据 */
      value: {
        type: Array,
        default() {
          return [];
        },
      },
      /** 是否固定禁用的数据到列表最后显示 */
      fixDisabled: {
        type: Boolean,
        default: false,
      },
      /** 列表项判断是否禁用的逻辑方法 */
      itemDisabledFun: {
        type: Function,
      },
      /** 是否不可编辑 */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** 是否显示新增按钮 */
      showAdd: {
        type: Boolean,
        default: true,
      },
      /** 是否显示删除按钮 */
      showReduce: {
        type: Boolean,
        default: true,
      },
      /** 最大可编辑数量 */
      maxCount: {
        type: Number,
      },
      /** 每一项的插槽信息(rules, index, data, baseData) */
      itemOptions: {
        type: Object,
      },
    },
    created() {
      if (this.value.length === 0) {
        this.add();
      }
    },
    computed: {
      /** 是否显示新增按钮 */
      canShowAdd() {
        if (!this.showAdd) {
          return false;
        }
        if (this.maxCount && this.value.length >= this.maxCount) {
          return false;
        }
        return true;
      },
      list() {
        return this.value.filter(
          o => !(this.itemDisabledFun ? this.itemDisabledFun(o) : o.disabled)
        );
      },
      disabledList() {
        return this.value.filter(o =>
          this.itemDisabledFun ? this.itemDisabledFun(o) : o.disabled
        );
      },
    },
    watch: {
      data: {
        handler(n_val, o_val) {
          console.log(n_val, o_val);
          if (n_val.length === 0) {
            this.add();
          }
        },
      },
    },
    methods: {
      /** 新增按钮事件 */
      add() {
        if (this.maxCount && this.value.length >= this.maxCount) {
          return;
        }
        this.value.push({ ...this.itemOptions.baseData });
      },
      /** 删除按钮事件 */
      reduce(index) {
        if (this.disabled) return;
        if (this.value.length > 1) {
          this.value.splice(index, 1);
        }
      },
      /** 数据校验方法 */
      validate() {
        return new Promise(_resolve => {
          Promise.all(
            this.$refs.formItem.map(o => {
              return new Promise(resolve => {
                o.$refs.form.validate(res => {
                  resolve(res);
                });
              });
            })
          ).then(res => {
            _resolve(res.every(o => o));
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .ykcGroupForm {
    display: inline-block;
    .combination-wrap {
      display: flex;
      ._item {
        display: flex;
        .reduce {
          display: flex;
          align-items: flex-start;
          padding-top: 8px;
          width: 24px;
          color: #4d8cfd;
          cursor: pointer;
          &.icon-disabled {
            color: #9b9b9b !important;
            cursor: not-allowed;
          }
        }
      }
    }
  }
</style>
