<!--
 *@author huhongyun
 *@date 2021/10/19
 *@component 抽屉组件
-->
<template>
  <el-drawer
    class="ykc-drawer"
    direction="rtl"
    append-to-body
    modal-append-to-body
    v-bind="$attrs"
    v-on="$listeners"
    :size="size"
    :title="title"
    :visible.sync="show"
    :show-close="showClose"
    :before-close="beforeClose"
    :close-on-press-escape="false"
    :destroy-on-close="destroyOnClose"
    :wrapper-closable="wrapperClosable"
    @opened="this.onOpened">
    <!-- title slot -->
    <template slot="title" v-if="$slots.title">
      <slot name="title"></slot>
    </template>
    <!-- default slot -->
    <template slot="default" v-if="$slots.default">
      <el-scrollbar
        noresize
        :tag="tag"
        wrap-class="ykc-drawer-scrollbar-wrapper"
        view-class="ykc-drawer-scrollbar-view-box">
        <slot name="default"></slot>
      </el-scrollbar>
    </template>
    <template v-if="$slots.footer">
      <div class="ykc-drawer-footer">
        <slot name="footer"></slot>
      </div>
    </template>
    <template v-else>
      <div class="ykc-drawer-footer">
        <ykc-button type="plain" :style="footerBtnStyle" @click="onCancel">
          {{ cancelTxt || '取消' }}
        </ykc-button>
        <ykc-button :style="footerBtnStyle" @click="onEnsure">
          {{ ensureTxt || '保存' }}
        </ykc-button>
      </div>
    </template>
  </el-drawer>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';

  export default {
    name: 'YkcDrawer',
    components: {},
    data() {
      return {
        scrollbarKey: '',
      };
    },
    props: {
      // 是否展示抽屉
      show: Boolean,
      // 标题
      title: String,
      size: {
        type: String,
        default: '620px',
      },
      // 滚动容器父级元素标签，例如 div ,ul
      tag: {
        type: String,
        default: 'div',
      },
      // 默认是否销毁
      destroyOnClose: {
        type: Boolean,
        default: true,
      },
      // 默认是否显示关闭按钮
      showClose: {
        type: Boolean,
        default: false,
      },
      // 默认点击遮罩层是否关闭抽屉
      wrapperClosable: {
        type: Boolean,
        default: false,
      },
      // 取消文本
      cancelTxt: String,
      // 确认文本
      ensureTxt: String,
      beforeCancel: Function,
      beforeEnsure: Function,
      // 点击关闭按钮，关闭之前
      onClose: Function,
      footerBtnStyle: [String, Object],
      // 当点击取消按钮时，是否显示取消的确认弹窗，默认为 false
      showDialogWhenCancel: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      show(val) {
        if (val) {
          this.$emit('update:show', true);
          this.$emit('open-drawer');
        } else {
          this.$emit('update:show', false);
          this.$emit('close-drawer');
        }
      },
    },
    methods: {
      onOpened() {
        this.$emit('opened', this.$data);
        if (document.body.getAttribute('ykc-ui')) {
          const drawer = document.querySelector('.el-drawer__open .el-drawer.rtl');
          // console.log('drawer::', drawer);
          drawer.style.setProperty(
            'background-position-x',
            `${drawer.getBoundingClientRect().x}px`
          );
          drawer.style.setProperty(
            'background-position-y',
            `${drawer.getBoundingClientRect().y}px`
          );
        }
      },
      onCancel() {
        if (typeof this.beforeCancel === 'function') {
          this.beforeCancel(this.hide);
        } else if (this.showDialogWhenCancel) {
          //  这里需要重构代码，如果用户没有传递自定义的回调函数，
          //  需要提供一个 dialog ,最后将取消、确认的事件抛出去
          // 这里最好只需要提供一个自定义监听事件，而不是在 dialog 之后的再次让用户提供回调函数的处理
          // todo 提供一个弹窗并在点击【弹窗】的取消、确认按钮之后 抛出事件和 hide 函数
          YkcDialog({
            dialogType: 'feedback',
            showTitle: false,
            showFooter: true,
            desc: '取消后操作将不被保存，确认是否取消？',
            onCancel: dialogDone => {
              // 隐藏弹窗
              dialogDone();
              // 抛出点击了【二次弹窗】取消事件，方便用户处理之后自定义的逻辑
              this.$emit('update:show', true);
              this.$emit('dialog-cancel-click');
            },
            onConfirm: dialogDone => {
              // 隐藏弹窗
              dialogDone();
              // 隐藏抽屉
              this.hide();
              this.$emit('update:show', false);
              // 抛出点击了【二次弹窗】确认事件，方便用户处理之后自定义的逻辑
              this.$emit('dialog-confirm-click');
            },
          });
        } else {
          this.hide();
        }
      },
      onEnsure() {
        if (typeof this.beforeEnsure === 'function') {
          this.beforeEnsure(this.hide);
        } else {
          this.hide();
        }
      },
      hide(cancel) {
        if (cancel !== false) {
          this.$emit('update:show', false);
          this.$emit('close-drawer');
        }
      },
      beforeClose() {
        if (typeof this.onClose === 'function') {
          this.onClose(this.hide);
        } else {
          this.hide();
        }
      },
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
