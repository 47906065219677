/**
 *@author huhongyun
 *@date 2021/10/
 *@component 告警提示组件
 */
import Vue from 'vue';
import { Message } from 'element-ui';
import { isObject, isVNode, isString } from '@/components/ykc-ui/warning-tips/src/helper';
import { frameworkHeaderHeight, frameworkHeaderHeightNormal } from './index.scss';

let instance;
const instances = [];
let seed = 1;
const RADIX = 10;
const OFFSET = 10;
const VERTICAL_OFFSET = 4;

// eslint-disable-next-line no-shadow
const insertTitleNode = (title, instance) => {
  const titleEle = document.createElement('p');
  titleEle.setAttribute('class', 'ykc-warning-tips-large-title');
  titleEle.innerHTML = title;
  const $content = instance.$el.querySelector('.el-message__content');

  $content.insertBefore(titleEle, $content.firstChild);
  const $contentTitle = $content.querySelector('.ykc-warning-tips-large-title');
  $contentTitle.style.marginBottom = '10px';
};

const YkcWarningTips = opts => {
  if (Vue.prototype.$isServer) return;
  opts = opts || {};
  if (typeof opts === 'string') {
    opts = {
      message: opts,
    };
  }
  const userOnClose = opts.onClose;
  const id = `message_${seed++}`;
  opts.onClose = () => {
    YkcWarningTips.close(id, userOnClose);
  };
  const cw = document.body.clientWidth;
  opts.customClass = `ykc-warning-tips ykc-warning-tips-${opts.type}`;
  // 判断字符串类型和长度
  if (
    opts.message &&
    isString(opts.message) &&
    opts.message.match(/[0-9a-zA-Z_]/gi) &&
    opts.message.match(/[0-9a-zA-Z_]/gi).length > 80
  ) {
    //   如果是英文数字下划线,且长单超过一行
    opts.size = 'large';
  }

  if (
    opts.message &&
    isString(opts.message) &&
    // eslint-disable-next-line no-control-regex
    opts.message.match(/[^\x00-\xff]/gi) &&
    // eslint-disable-next-line no-control-regex
    opts.message.match(/[^\x00-\xff]/gi).length > 43
  ) {
    //   如果是中文且中文的长度超过一行
    opts.size = 'large';
  }

  if (opts.size === 'large') {
    opts.customClass = `ykc-warning-tips ykc-warning-tips-large ykc-warning-tips-large-${opts.type} ykc-warning-tips-${opts.type}`;
    // eslint-disable-next-line default-case
    switch (opts.type) {
      case 'info':
        opts.iconClass = 'el-message__icon el-icon-warning-outline';
        break;
      case 'success':
        opts.iconClass = 'el-message__icon el-icon-circle-check';
        break;
      case 'error':
        opts.iconClass = 'el-message__icon el-icon-circle-close';
        break;
      case 'warning':
        opts.iconClass = 'el-message__icon el-icon-warning-outline';
        break;
    }
  }
  if (!opts.offset) {
    if (cw > 1366) {
      // eslint-disable-next-line no-param-reassign
      opts.offset = parseInt(frameworkHeaderHeight, RADIX) + OFFSET;
    } else {
      // eslint-disable-next-line no-param-reassign
      opts.offset = parseInt(frameworkHeaderHeightNormal, RADIX) + OFFSET;
    }
  }
  instance = Message(opts);
  // 增加元素的高度,原偏移高度为 16
  const warningTips = document.body.querySelectorAll('.ykc-warning-tips');
  instance.verticalOffset += VERTICAL_OFFSET * (warningTips.length - 1);
  // 如果是大尺寸,显示标题
  if (opts.size === 'large') {
    // eslint-disable-next-line default-case
    switch (opts.type) {
      case 'info':
        insertTitleNode('帮助信息', instance);
        instance.$el.style.padding = '20px';
        break;
      case 'success':
        insertTitleNode('已成功！', instance);
        instance.$el.style.padding = '20px';
        break;
      case 'error':
        insertTitleNode('出错了！', instance);
        instance.$el.style.padding = '20px';
        break;
      case 'warning':
        insertTitleNode('请注意', instance);
        instance.$el.style.padding = '20px';
        break;
    }
  }
  instances.push(instance);
  // eslint-disable-next-line consistent-return
  return instance;
};
['success', 'warning', 'info', 'error'].forEach(type => {
  YkcWarningTips[type] = options => {
    if (isObject(options) && !isVNode(options)) {
      return YkcWarningTips({
        ...options,
        type,
      });
    }
    return YkcWarningTips({
      type,
      left: true,
      message: options,
    });
  };
});
YkcWarningTips.close = (id, userOnClose) => {
  const len = instances.length;
  // const index = -1;
  let removedHeight;
  for (let i = 0; i < len; i++) {
    if (id === instances[i].id) {
      // eslint-disable-next-line no-unused-vars
      removedHeight = instances[i].$el.offsetHeight;
      if (typeof userOnClose === 'function') {
        userOnClose(instances[i]);
      }
      instances.splice(i, 1);
      break;
    }
  }
};
YkcWarningTips.closeAll = () => {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close();
  }
};
Vue.prototype.$tips = YkcWarningTips;
export default YkcWarningTips;
