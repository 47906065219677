/*
 * @Author: Daokai Huo
 * @Date: 2021-10-19 19:28:42
 * @LastEditTime: 2021-10-19 19:38:01
 * @Description:
 */
import YkcFormItem from '../form/src/ykc-form-item.vue';

YkcFormItem.install = function install(_vue) {
  _vue.component(YkcFormItem.name, YkcFormItem);
};
export default YkcFormItem;
