<!--
* @Author: Yanxia Wan
* @Date: 2021/10/25
* @Description: 详情头部组件
-->
<template>
  <div class="detail-head flex cardview-head-box">
    <!--左侧-->
    <div class="head-left flex">
      <!-- <div class="tips" v-if="tips"></div> -->
      <div class="head-left-label" v-for="(title, index) in titles" :key="'head-left' + index">
        <!--字符串-->
        <span v-if="typeof title === 'string'" :title="title" class="weight-sym">{{ title }}</span>
        <!--对象-->
        <span v-else>{{ `${title.label}：${title.value || '——'}` }}</span>
      </div>
      <div
        class="tag text-center"
        v-for="(tag, index) in tags"
        :class="tag.type"
        :key="'tag' + index">
        {{ tag.name }}
      </div>
      <!--自定义-->
      <slot name="cvHead-lt"></slot>
    </div>
    <!--右侧-->
    <div class="head-right flex">
      <!--文字-->
      <div
        class="head-right-label"
        v-for="(item, index) in rightInfo"
        :style="`${
          typeof item !== 'string' && item.right ? 'margin-right:' + item.right + 'px' : ''
        }`"
        :key="'head-right' + index">
        <!--字符串-->
        <span v-if="typeof item === 'string'">{{ item }}</span>
        <!--对象-->
        <span v-else>{{ `${item.label}${item.value.toString() ? '：' : ''}${item.value}` }}</span>
      </div>
      <!--按钮-->
      <div class="head-right-btn flex">
        <div class="btn-container" v-for="(btn, index) in btns" :key="'btn' + index">
          <!--切换按钮-->
          <ykc-switch
            v-if="btn.shape === 'switch'"
            v-model="btn.model"
            :switchStyle="btn.switchStyle"
            :text="btn.text"
            @click="mod => handleChange(mod, btn, index)"></ykc-switch>
          <!--普通按钮-->
          <ykc-button
            v-else
            :type="btn.type"
            :fontW="btn.fontW"
            :fontH="btn.fontH"
            :size="btn.size"
            :round="btn.round"
            :iconImg="btn.iconImg"
            :disabled="btn.disabled"
            @click="handleClick(btn, index)">
            {{ btn.text }}
          </ykc-button>
        </div>
      </div>
      <!--自定义-->
      <slot name="cvHead-rt"></slot>
    </div>
  </div>
</template>

<script>
  import ykcSwitch from '@/components/ykc-ui/switch';
  import ykcButton from '@/components/ykc-ui/button';

  export default {
    name: 'YkcDetailHeader',
    components: {
      ykcSwitch,
      ykcButton,
    },
    props: {
      /**
       * 标题
       * 字符串数组：['日期日期', '姓名姓名姓名']
       * 对象数组：[{label: '日期',value: '2016-05-02'}]
       */
      tips: {
        // 是否有标题旁边的小点点
        type: Boolean,
        default: true,
      },
      titles: {
        type: Array,
        default: () => [],
      },
      rightInfo: {
        // 右侧信息：type：text(文字)，
        type: Array,
        default: () => [],
      },
      /**
       * 右侧按钮
       * 参数
       * {
       *     shape：default //默认：default可不填，类型：switch（切换）
       *     type：//按钮类型，参考按钮组件
       *     switchStyle：{width:xx,height:xx}//滑块样式
       *     text：['图', '表']滑块文字
       * }
       */
      btns: {
        type: Array,
        default: () => [],
      },
      /**
       * 标签集合
       * type:标签种类；tt1,tt2,tt3,tt4,tt5
       * name:名称
       */
      tags: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      /**
       * 点击切换按钮
       * @param self：按钮参数
       * @param $index：按钮下标
       * @param mod：滑块选中值
       */
      handleChange(mod, self, $index) {
        this.$emit('change', mod, self, $index);
      },
      /**
       * 点击普通按钮
       * @param self：按钮参数
       * @param $index：按钮下标
       */
      handleClick(self, $index) {
        this.$emit('btn-click', self, $index);
      },
    },
  };
</script>
<style lang="scss" scope>
  @import './index.scss';
</style>
