/*
 * @Author: Daokai Huo
 * @Date: 2021-09-26 17:39:46
 * @LastEditTime: 2021-09-26 17:40:46
 * @Description:
 */
import YkcButton from './src/ykc-button.vue';

YkcButton.install = function install(_vue) {
  _vue.component(YkcButton.name, YkcButton);
};
export default YkcButton;
