<template>
  <el-card class="box-card">
    <div slot="header" class="detail-head weight-sym" v-if="showHeader">
      <slot name="header"></slot>
    </div>
    <el-row>
      <slot></slot>
    </el-row>
  </el-card>
</template>

<script>
  export default {
    name: 'YkcDetailPlus',
    props: {
      labelWidth: {
        type: Number,
        default: 60,
      },
      showColon: {
        type: Boolean,
        default: false,
      },
      align: {
        type: String,
      },
      showHeader: {
        type: Boolean,
        default: true,
      },
    },
    provide() {
      return {
        rootLabelWidth: this.labelWidth,
        rootShowColon: this.showColon,
        rootAlign: this.align,
      };
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .el-card__header {
    border: 0;
    padding: 10px;
    height: 54px;
    box-sizing: border-box;

    @media screen and (max-width: 1366px) {
      height: 37px;
    }
  }

  ::v-deep .el-card__body {
    padding: 0 10px 10px;
  }
  .detail-head {
    font-size: 14px;
    color: #000000;
  }
  .clearfix {
    position: relative;
    padding-left: 10px;
    &::before {
      content: ' ';
      position: absolute;
      width: 5px;
      height: 5px;
      background-color: var(--theme-color-primary);
      border-radius: 5px;
      left: 0;
      top: calc(50% - 2.5px);
    }
  }
</style>
