import YkcUI from '@/components/ykc-ui';

import ykcForm from '@/components/ykcForm.vue';
import ykcSelect from '@/components/ykcSelect.vue';
import Detail from '@/components/detail/detail.vue';
import DetailItem from '@/components/detail/detailItem.vue';
import GroupForm from '@/components/groupForm/groupForm.vue';
import GroupFormItem from '@/components/groupForm/groupFormItem.vue';

export default Vue => {
  Vue.use(YkcUI);
  Vue.component(Detail.name, Detail);
  Vue.component(ykcForm.name, ykcForm);
  Vue.component(ykcSelect.name, ykcSelect);
  Vue.component(GroupForm.name, GroupForm);
  Vue.component(DetailItem.name, DetailItem);
  Vue.component(GroupFormItem.name, GroupFormItem);
};
