/*
 * @Author: Daokai Huo
 * @Date: 2021-10-09 09:44:39
 * @LastEditTime: 2021-10-09 09:45:19
 * @Description:
 */
import YkcUpload from './src/ykc-upload.vue';

YkcUpload.install = function install(_vue) {
  _vue.component(YkcUpload.name, YkcUpload);
};
export default YkcUpload;
