<!--
 * @Descripttion: 电价配置组件
 * @Author: 汪佳彪
 * @date: 2022-01-13 18:58
-->
<template>
  <div>电价配置组件</div>
</template>

<script>
  export default {
    name: 'YkcElectrovalence',
  };
</script>

<style lang="scss"></style>
