<!--
* @Author: Yanxia Wan
* @Date: 2021-10-21
* @Description: Cascader 级联选择器
-->
<template>
  <div class="ykc-cascader">
    <el-cascader
      ref="cascader"
      v-model="model"
      :options="options"
      :placeholder="placeholder"
      :props="config"
      :clearable="clearable"
      :disabled="disabled"
      :filterable="filterable"
      :separator="separator"
      :isObject="isObject"
      :collapse-tags="collapseTags"
      @change="handleChange"></el-cascader>
  </div>
</template>

<script>
  export default {
    name: 'YkcCascader',
    props: {
      value: {
        // 默认选中
        type: Array,
        default: () => [],
      },
      options: {
        // 数据源 [{id: 'jiangsu',name: '江苏省',subNodes: [{id: 'nanjshi',name: '南京市',subNodes: [{id: 'yuhuatai',name: '雨花台区'}]}]}]
        type: Array,
        default: () => [],
      },
      placeholder: {
        // 选择框提示文字，默认"请选择"，非必传。
        type: String,
        default: '请选择',
      },
      disabled: {
        // 是否禁用，默认false，非必传。
        type: Boolean,
        default: false,
      },
      clearable: {
        // 是否可以清空 即是否出现x清除所选数据
        type: Boolean,
        default: false,
      },
      isObject: {
        // 通过change方法获取的值是object还是value单个值
        type: Boolean,
        default: false,
      },
      collapseTags: {
        // 多选模式下是否折叠Tag
        type: Boolean,
        default: false,
      },
      separator: {
        // 选择数据所展示的分隔符
        type: String,
        default: '/',
      },
      filterable: {
        // 通过change方法获取的值是object还是value单个值
        type: Boolean,
        default: false,
      },
      checkStrictly: {
        // 是否允许选择任意一级的选项
        type: Boolean,
        default: false,
      },
      configSet: {
        // 组件对应key的配置项
        type: Object,
        default: () => ({
          value: 'id',
          label: 'name',
          children: 'subNodes',
        }),
      },
    },
    data() {
      return {
        node: '', // 返回当前选中节点
        title: '',
      };
    },
    // 计算属性用于回显
    computed: {
      model: {
        get() {
          return this.value || [];
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 可自定义配置props属性
      // config() {
      //   if (this.$attrs.config) {
      //     return this.$attrs.config;
      //   }
      //   return { checkStrictly: false };
      // },
      config() {
        return { ...this.configSet, checkStrictly: this.checkStrictly };
      },
    },
    watch: {
      // 单选时，修改节点后直接关闭窗口
      value() {
        if (!this.config.multiple || this.config.multiple !== true) {
          this.$refs.cascader.toggleDropDownVisible(false); // 监听值发生变化就关闭它
        }
      },
    },
    methods: {
      handleChange(value) {
        let nodes = '';
        let res = {};
        if (this.isObject) {
          // 获取选择的node相关数据
          [nodes] = this.$refs.cascader.getCheckedNodes();
          console.log('nodes', nodes);
          // 获取选择的label的数组数据
          const labels = nodes.pathLabels;
          res = value.map((item, index) => ({
            [this.configSet.label]: labels[index],
            [this.configSet.value]: item,
          }));
        }

        // 可搜索输入无法获取节点
        if (this.$refs.cascader.getCheckedNodes()[0]) {
          this.node = this.$refs.cascader.getCheckedNodes()[0].data;
        }
        this.$emit('change', this.isObject ? res : value);
      },
    },
  };
</script>
<style lang="scss">
  @import './index.scss';
</style>
