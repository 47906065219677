import Vue from 'vue';

const requireWithCtx = require.context('./', false, /^\.\/(?!(index\.js$))(.*)\.js$/);

const keys = requireWithCtx.keys();

keys.forEach(key => {
  const plugin = requireWithCtx(key).default;
  Vue.use(plugin);
});
