<!--
 * @Author: Daokai Huo
 * @Date: 2021-09-29 11:22:54
 * @LastEditTime: 2021-10-20 13:30:08
 * @Description: switch 组件
-->
<template>
  <div class="ykc-switch" @click="handleClick">
    <span :class="active && 'active'" v-text="text[0]"></span>
    <span :class="!active && 'active'" v-text="text[1]"></span>
  </div>
</template>
<script>
  export default {
    name: 'YkcSwitch',
    props: {
      // 切换按钮文字
      text: {
        type: Array,
        default: () => ['图表', '列表'],
      },
      // 默认状态
      value: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        active: this.value,
      };
    },
    methods: {
      /**
       * 按钮点击事件，返回当前状态
       */
      handleClick() {
        this.active = !this.active;
        this.$emit('click', this.active);
      },
    },
  };
</script>
<style lang="scss">
  @import './index.scss';
</style>
