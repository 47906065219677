<!--
 * @Descripttion: aside组件
 * @Author: 汪佳彪
 * @date: 2021-10-09 10:36
-->
<template>
  <div class="ykc-aside" :style="{ width }" :class="[`ykc-aside-${type}`]">
    <el-scrollbar style="height: 100%" ref="RootMenuScrollBar" class="root-menu-scrollbar">
      <div
        v-for="menu in data"
        :key="generateKey(menu)"
        class="root-menu"
        :style="{ width }"
        :class="{ 'root-menu-active': menu === operatingRootMenu }"
        @click="handleRootMenuClick(menu)">
        <svg-icon :name="menu[iconProp] || 'chart-pie'" class="root-menu-icon"></svg-icon>
        <span>{{ menu.name }}</span>
      </div>
    </el-scrollbar>
    <transition name="poper-menu-wrapper-transition">
      <div
        v-show="subMenuVisible && operatingRootMenu"
        :style="subMenuStyle"
        :class="['poper-menu-wrapper', `poper-menu-wrapper-${type}`]">
        <el-scrollbar ref="SubMenuScrollBar" style="height: 100%" class="sub-menu-scrollbar">
          <transition-group name="poper-menu-item">
            <template v-for="menu in subMenuData">
              <div
                :key="generateKey(menu)"
                v-show="shouldShow(menu)"
                :class="{
                  'poper-menu-item': true,
                  'poper-menu-item-active': menu === activeSubMenu,
                }"
                :style="calculateStyle(menu)"
                @click="handleSubMenuClick(menu)">
                <i
                  v-if="hasChildren(menu)"
                  :class="[`el-icon-caret-${menu[expandedSymbol] ? 'bottom' : 'right'}`]"></i>
                <span>{{ menu.name }}</span>
              </div>
            </template>
          </transition-group>
        </el-scrollbar>
      </div>
    </transition>
  </div>
</template>

<script>
  import svgIcon from '@/components/Svg.vue';
  import windowResizeMixin from '@/components/ykc-ui/utils/window-resize-mixin';

  const ExpandedSymbol = Symbol('expanded');
  const DepthSymbol = Symbol('depth');

  export default {
    name: 'YkcAside',
    inheritAttrs: false,
    mixins: [windowResizeMixin],
    components: {
      svgIcon,
    },
    props: {
      // 菜单数据，树结构
      data: {
        type: Array,
        default: () => [],
      },
      // 默认激活的叶子菜单
      defaultActiveMenuId: {
        type: [String, Number],
        default: '',
      },
      // 弹出菜单后，是否默认展开全部菜单
      expandAllAfterSubMenuShow: {
        type: Boolean,
        default: true,
      },
      // 菜单弹出层的z-index
      zIndex: {
        type: Number,
        default: 5000,
      },
      // 个层级菜单的缩进（排除根菜单）
      indents: {
        type: Array,
        default: () => [16, 48, 64],
      },
      // 是否保留展开和折叠状态
      keepExpandState: {
        type: Boolean,
        default: false,
      },
      // 是否是手风琴模式
      accordion: {
        type: Boolean,
        default: false,
      },
      // 点击根菜单时，是否切换菜单弹出层的显示
      toggleable: {
        type: Boolean,
        default: false,
      },
      // 菜单项的子元素属性
      childrenProp: {
        type: String,
        default: 'subNodes',
      },
      // 菜单项的id属性
      idProp: {
        type: String,
        default: 'id',
      },
      // 菜单项的父元素id属性
      parentIdProp: {
        type: String,
        default: 'parentId',
      },
      // 根菜单的icon属性
      iconProp: {
        type: String,
        default: 'icon',
      },
      // 关闭菜单弹出层的方式
      trigger: {
        type: String,
        default: 'click',
        validator(val) {
          return ['click', 'hover'].includes(val);
        },
      },
    },
    data() {
      return {
        expandWidth: '80px',
        collapseWidth: '60px',
        width: '80px',
        store: new Map(),
        subMenuVisible: false,
        subMenuData: [],
        // 当前操作的菜单
        operatingRootMenu: null,
        activeRootMenu: null,
        activeSubMenu: null,
      };
    },
    computed: {
      expandedSymbol() {
        return ExpandedSymbol;
      },
      depthSymbol() {
        return DepthSymbol;
      },
      subMenuStyle() {
        return {
          left: this.width,
          zIndex: this.zIndex,
        };
      },
    },
    watch: {
      subMenuVisible: {
        handler(val) {
          if (!val) {
            // 每次关闭弹出层，都清空弹出层数据并折叠菜单项
            this.subMenuData = [];
            this.collapseAll();
          }
        },
      },
      defaultActiveMenuId: {
        handler: 'setDefaultActiveMenu',
      },
    },
    created() {
      this.initStore();
      this.setDefaultActiveMenu(this.defaultActiveMenuId);
    },
    updated() {
      if (this.$refs.SubMenuScrollBar) {
        this.$refs.SubMenuScrollBar.update();
      }
    },
    methods: {
      /**
       * @description 根据window resize事件计算大小
       */
      calculateSize(isExpanded) {
        this.width = isExpanded ? this.expandWidth : this.collapseWidth;
      },
      /**
       * @description 初始化数据
       */
      initStore() {
        const { childrenProp, expandedSymbol, depthSymbol } = this;
        // 深度优先遍历，给个菜单项加上 展开标识（用于展开子菜单）和深度标识（用于计算缩进）
        const dfs = (tree, depth, result) => {
          tree.forEach(menu => {
            const item = {
              ...menu,
              [expandedSymbol]: false,
              [depthSymbol]: depth,
            };
            result.push(item);
            if (menu[childrenProp]) {
              dfs(menu[childrenProp], depth + 1, result);
            }
          });
        };
        this.data.forEach(rootMenu => {
          const menuArray = [];
          const children = rootMenu[childrenProp];
          if (children && Array.isArray(children)) {
            dfs(children, 1, menuArray);
          }
          // 使用Map数据结构，将根菜单作为key，根菜单下的全部子菜单（已展平）作为value保存到store中
          this.store.set(rootMenu, menuArray);
        });
      },
      /**
       * @description 设置默认激活的叶子菜单
       */
      setDefaultActiveMenu(defaultActiveMenuId) {
        const { idProp, expandedSymbol, store } = this;
        if (String(defaultActiveMenuId)) {
          // 根据defaultActiveMenuId查找是否存在这样的根菜单
          const rootMenu = [...store.keys()].find(rootMenuItem => {
            return [...store.get(rootMenuItem), rootMenuItem].find(
              subMenuItem => String(subMenuItem[idProp]) === String(defaultActiveMenuId)
            );
          });
          // 存在根菜单，则处理相关数据
          if (rootMenu) {
            // 找到叶子菜单
            const activeSubMenu =
              store
                .get(rootMenu)
                .find(subMenuItem => String(subMenuItem[idProp]) === String(defaultActiveMenuId)) ||
              null;
            const { ancestors } = this.getAncestors(activeSubMenu);
            // 展开默认选中
            if (!this.keepExpandState) {
              ancestors.forEach(item => {
                item[expandedSymbol] = true;
              });
            }
            this.activeRootMenu = rootMenu;
            this.operatingRootMenu = rootMenu;
            this.activeSubMenu = activeSubMenu;
            this.$emit(
              'submenu-change',
              activeSubMenu ? [rootMenu, ...ancestors, activeSubMenu] : [rootMenu, ...ancestors]
            );
          }
        }
      },
      /**
       * @description 折叠全部菜单节点
       */
      collapseAll() {
        const { store, expandedSymbol, keepExpandState } = this;
        if (keepExpandState) return;
        [...store.keys()].forEach(rootMenu => {
          store.get(rootMenu).forEach(subMenu => {
            subMenu[expandedSymbol] = false;
          });
        });
      },
      /**
       * @description 有选中的叶子节点时，把祖先全部展开
       */
      expandActiveSubMenu() {
        const { activeSubMenu, expandedSymbol, keepExpandState, getAncestors } = this;
        if (keepExpandState) return;
        if (activeSubMenu) {
          const { ancestors } = getAncestors(activeSubMenu);
          ancestors.forEach(item => {
            item[expandedSymbol] = true;
          });
        }
        if (this.expandAllAfterSubMenuShow) {
          this.subMenuData
            .filter(item => this.hasChildren(item))
            .forEach(item => {
              item[expandedSymbol] = true;
            });
        }
      },
      /**
       * @description 根菜单点击处理
       * @param {Object} menu 根菜单项
       */
      handleRootMenuClick(menu) {
        const { subMenuVisible, toggleable, operatingRootMenu, store } = this;
        const currentSubMenuData = store.get(menu);
        // 没有子节点的根菜单，则抛出事件外部处理
        if (currentSubMenuData.length === 0) {
          this.subMenuVisible = false;
          this.operatingRootMenu = menu;
          /** 没有子元素的根菜单，外部处理逻辑 */
          this.$emit('empty-children-root-menu-click', menu);
        } else {
          // 切换菜单
          // eslint-disable-next-line
          if (subMenuVisible) {
            // 点击当前操作的菜单，如果是可切换的，则关闭子菜单弹出层
            if (operatingRootMenu === menu) {
              if (toggleable) {
                this.subMenuVisible = false;
              }
            } else {
              // 点击其他菜单
              this.operatingRootMenu = menu;
              this.subMenuData = store.get(menu);
              this.collapseAll();
              this.expandActiveSubMenu();
            }
          } else {
            // 弹出菜单
            this.operatingRootMenu = menu;
            this.subMenuData = store.get(menu);
            this.subMenuVisible = true;
            this.collapseAll();
            this.expandActiveSubMenu();
          }
        }
      },
      /**
       * @description 计算菜单项的key
       * @returns {String}
       */
      generateKey(menu) {
        return menu[this.idProp];
      },
      /**
       * @description 判断父菜单是否是展开状态
       * @param {Object} menu 菜单项
       * @returns {Boolean}
       */
      shouldShow(menu) {
        const { getAncestors, expandedSymbol } = this;
        const { ancestors } = getAncestors(menu);
        const { length } = ancestors;
        if (length === 0) return true;
        if (length === 1) return !!ancestors[0][expandedSymbol];
        return ancestors.every(item => !!item[expandedSymbol]);
      },
      /**
       * @description 计算子菜单项的样式，包含缩进等
       * @returns {Object}
       */
      calculateStyle(menu) {
        const { indents, depthSymbol } = this;
        const menuDepth = menu[depthSymbol];
        const indent = indents[menuDepth - 1];
        return {
          paddingLeft: indent ? `${indent}px` : `${indents.slice(-1)[0]}px`,
        };
      },
      /**
       * @description 判断菜单项是否有子元素
       * @param {Object} menu 菜单项
       * @returns {Boolean}
       */
      hasChildren(menu) {
        const { childrenProp } = this;
        const children = menu[childrenProp];
        if (children && Array.isArray(children) && children.length > 0) {
          return true;
        }
        return false;
      },
      /**
       * @description 获取祖先节点，不包含自己和根节点
       * @param {Object} currentMenu 菜单项
       * @returns {Array}
       */
      getAncestors(currentMenu) {
        const { store } = this;
        let rootMenu = null;
        let subMenus = null;
        // eslint-disable-next-line
        for (const item of [...store.keys()]) {
          if (store.get(item).includes(currentMenu)) {
            rootMenu = item;
            subMenus = store.get(item);
            break;
          }
        }
        const ancestors = [];
        if (rootMenu && subMenus) {
          const { idProp, parentIdProp } = this;
          // 递归获取
          const recur = compareMenu => {
            const parentMenu = subMenus.find(item => item[idProp] === compareMenu[parentIdProp]);
            if (parentMenu) {
              ancestors.push(parentMenu);
              recur(parentMenu);
            }
          };
          recur(currentMenu);
        }
        // console.log(ancestors.map(item => item.id));
        return {
          currentMenu,
          ancestors,
          rootMenu,
        };
      },
      /**
       * @description 关闭子菜单弹出层
       */
      closeSubMenu() {
        this.subMenuVisible = false;
        this.operatingRootMenu = this.activeRootMenu || null;
      },
      /**
       * @description 子菜单项点击处理
       * @param {Object} menu 子菜单项
       */
      handleSubMenuClick(menu) {
        const {
          expandedSymbol,
          operatingRootMenu,
          getAncestors,
          depthSymbol,
          idProp,
          parentIdProp,
        } = this;
        const hasChildren = this.hasChildren(menu);
        const menuExpanded = menu[expandedSymbol];
        // 存在children，则进行展开折叠操作
        if (hasChildren) {
          if (menuExpanded) {
            menu[expandedSymbol] = false;
            this.$forceUpdate();
          } else {
            // 展开时，如果是手风琴模式，则把其他子菜单折叠
            if (this.accordion) {
              const parent = this.subMenuData.find(item => item[idProp] === menu[parentIdProp]);
              if (parent) {
                this.subMenuData
                  .filter(
                    item =>
                      item[idProp] !== menu[idProp] && item[parentIdProp] === menu[parentIdProp]
                  )
                  .forEach(item => {
                    item[expandedSymbol] = false;
                  });
              } else {
                this.subMenuData
                  .filter(
                    item => item[depthSymbol] === menu[depthSymbol] && item[idProp] !== menu[idProp]
                  )
                  .forEach(item => {
                    item[expandedSymbol] = false;
                  });
              }
            }
            menu[expandedSymbol] = true;
            this.$forceUpdate();
          }
        } else {
          // 点击的是叶子菜单
          const { ancestors } = getAncestors(menu);
          this.$emit('menu-item-click', [operatingRootMenu, ...ancestors.reverse(), menu]);
          this.activeSubMenu = menu;
          // 点击叶子节点，则操作中的根节点变成激活的根节点
          this.activeRootMenu = this.operatingRootMenu;
          this.closeSubMenu();
        }
      },
    },
    mounted() {
      const handler = ({ target }) => {
        if (!this.subMenuVisible) return;
        if (
          [this.$refs.RootMenuScrollBar.$el, this.$refs.SubMenuScrollBar.$el].every(
            el => el !== target && !el.contains(target)
          )
        ) {
          this.closeSubMenu();
        }
      };
      const eventName = {
        click: 'click',
        hover: 'mouseover',
      };
      // 点击或hover非菜单区域则关闭子菜单弹出层
      window.document.addEventListener(eventName[this.trigger], handler);
      this.$on('hook:destroyed', () => {
        window.document.removeEventListener('click', handler);
      });
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
