<!--
* @name: 充值送券组件
* @Auther: 王辉
* @Date: 2023/02/14
-->
<template>
  <ykc-combination-item
    ref="combinationBox"
    :data="data"
    :setting="setting"
    :maxLength="maxLength"
    addMsg="添加充值梯度"
    @initData="initData"
    @clickDel="clickDel"
    @clickAdd="clickAdd"
    @initEvenSetting="initEvenSetting">
    <slot slot-scope="slotProps">
      <div class="top" :key="`coupontop${slotProps.index}`">
        <coupon-box
          :ref="`couponBox${slotProps.index}`"
          :key="`couponBox${slotProps.index}`"
          :data="settingList[slotProps.index][configSet[1]]"
          icon="yellow"
          :couponData="couponData"
          :configSet="configSet[2]"
          @verifyAll="flag => verifyCoupon(slotProps.index, flag)"></coupon-box>
      </div>
    </slot>
  </ykc-combination-item>
</template>

<script>
  import YkcCombinationItem from '@/components/ykc-ui/combination-item/src/combination-item';

  export default {
    name: 'YkcRechargeCouponBox',
    components: { YkcCombinationItem },
    props: {
      data: {
        // 组件默认值数据
        type: Array,
        default: () => [],
      },
      couponData: {
        // 优惠劵数组数据
        type: Array,
        default: () => [],
      },
      maxLength: {
        // 最大可添加数量
        type: Number,
        default: 6,
      },
      configSet: {
        // 取值的配置信息
        type: Array,
        default: () => ['payAmount', 'coupons', ['couponId', 'couponSendNum']],
      },
    },
    data() {
      return {
        setting: [
          // 默认配置
          {
            type: 'input',
            key: this.configSet[0],
            value: '',
            prepend: '充值',
            append: '元',
            maxlength: 8,
            placeholder: '请输入充值金额',
            verify: this.verify,
          },
        ],
        settingList: [],
        addType: 0, // 是否可以增加价格类型 0置灰  1可点击  2隐藏
      };
    },
    watch: {
      // 监控data
      couponData: {
        handler() {
          if (this.$refs.combinationBox) {
            this.$refs.combinationBox.initData();
          }
        },
        // 首次绑定是否执行handle
        immediate: true,
        // 是否深度监听
        deep: true,
      },
    },
    mounted() {
      if (this.data) {
        this.verify(null, 'all');
      } else {
        this.verify();
      }
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(ts, settingList) {
        this.settingList = settingList;
      },
      /**
       * 初始化每一条数据
       */
      initEvenSetting(setting, item, index, res) {
        res.couponVerify = !this._.isEmpty(item);
        if (!this._.isEmpty(item) && item[this.configSet[1]]) {
          res[this.configSet[1]] = item[this.configSet[1]];
        }
      },
      /**
       * 点击 添加
       */
      clickAdd() {},
      /**
       * 点击 删除 行
       */
      clickDel() {
        this.verify();
        this.setSettingData();
      },
      /**
       * 设置优惠劵选择
       */
      setSettingData() {
        this.settingList.map((item, index) => {
          item.setting.forEach(item1 => {
            this.$set(item1, item1.type === 'input' ? 'blur' : 'change', () =>
              this.verify(index, item1.key)
            );
          });
          return item;
        });
      },
      /**
       * 校验优惠劵结果
       * 优惠劵组件暴露的方法
       */
      verifyCoupon(index, flag) {
        this.$set(this.settingList[index], 'couponVerify', flag);
        this.verify();
      },
      /**
       * 保存时 调用方法 如果返回null则校验不通过
       */
      saveVerify() {
        let obj = null;
        // 如果所有校验通过
        if (this.verify(null, 'all')) {
          obj = this.settingList.map((item, index) => {
            const { setting } = item;
            return {
              [this.configSet[0]]: setting[0].value,
              [this.configSet[1]]: this.saveVerifyCouponBox(index),
            };
          });
        }
        return obj;
      },
      /**
       * 校验所有
       */
      verify(ind, type) {
        let flag = true;
        this.settingList.forEach((item, index) => {
          // 如果是单个校验
          if (ind === index) {
            this.verifyEven(ind, type);
          }

          if (!ind && type === 'all') {
            // 如果是保存时候的校验
            flag = this.verifyEven(index, type) && flag;
            flag = this.saveVerifyCouponBox(index) && flag;
          } else {
            // 如果是仅需要是否校验结果的（即非保存时候校验，只需要结果，不需要把所有走一遍校验）
            // 如果ind为null 且type有值，则校验所有走过校验的充值金额
            if (ind === null && type === this.configSet[0]) {
              this.settingList.forEach((item1, index1) => {
                if (item1.errorMsg !== undefined) {
                  const itemRecharge = item1.setting[0];
                  this.verifyRecharge(itemRecharge.value, itemRecharge, index1);
                }
              });
            }
            flag = this.verifyEven(index) && flag;
          }
        });

        // 如果达到最大添加数 则隐藏添加按钮
        if (this.settingList.length >= this.maxLength) {
          this.addType = 2;
        } else if (flag) {
          // 校验通过
          this.addType = 1;
        } else {
          // 校验不通过
          this.addType = 0;
        }

        if (this.$refs.combinationBox) {
          this.$refs.combinationBox.addType = this.addType;
        }

        return flag;
      },
      /**
       * 校验每一列
       */
      verifyEven(index, type) {
        const item = this.settingList[index];
        const { setting } = item;
        const itemRecharge = setting[0];
        const cou = this.$refs[`couponBox${index}`];
        // const itemHandsel = setting[1]

        let flag = true;
        // 如果传type 则为正常blur或者change后校验
        if (type) {
          if (type === itemRecharge.key) {
            this.verifyRecharge(itemRecharge.value, itemRecharge, index);
            flag = false;
          } else {
            const flag1 = this.verifyRecharge(itemRecharge.value, itemRecharge, index);
            const flag2 = cou.saveVerify();
            flag = flag1 && flag2;
          }
        } else {
          // 没有type，则只需知道其是否过了校验，不用有相对应的提示等
          flag = item.couponVerify && itemRecharge.value && !itemRecharge.errorMsg;
        }

        return flag;
      },
      /**
       * 校验充值金额
       */
      verifyRecharge(value, item, index) {
        let flag = true;
        let msg = '';
        if (!value) {
          flag = false;
          msg = '请输入充值金额';
        } else if (parseFloat(value) === 0) {
          flag = false;
          msg = '请输入正确的充值金额（范围：0.01~99999.99）';
        } else if (!this.isOnlyRecharge(value, index)) {
          flag = false;
          msg = '当前充值梯度已存在，请重新输入';
        } else {
          this.$set(item, 'value', parseFloat(value).toFixed(2).toString());
          flag = true;
          msg = '';
        }
        this.$set(item, 'errorMsg', msg);
        return flag;
      },
      /**
       * 校验是否唯一
       * true 为 唯一， false 为 不唯一
       */
      isOnlyRecharge(val, ind) {
        const rechargeArr = [];
        this.settingList.forEach((item, index) => {
          if (index !== ind) {
            rechargeArr.push(item.setting[0].value);
          }
        });
        return rechargeArr.indexOf(parseFloat(val).toFixed(2).toString()) === -1;
      },
      /**
       * 校验优惠劵
       */
      saveVerifyCouponBox(index) {
        return this.$refs[`couponBox${index}`].saveVerify();
      },
    },
  };
</script>
