<!--
* @Author: Yanxia Wan
* @Date: 2021-10-28
* @Description: 详情页面
-->
<template>
  <div class="cardview-box" :class="className">
    <!--头部-->
    <div class="cardview-head" v-if="isHead">
      <!--自定义头-->
      <slot name="cvHead">
        <ykc-detailHeader
          :titles="titles"
          :tips="tips"
          :tags="tags"
          :rightInfo="rightInfo"
          :btns="btns"
          @change="handleChange"
          @btn-click="handleClick">
          <slot name="cvHead-lt" slot="cvHead-lt"></slot>
          <slot name="cvHead-rt" slot="cvHead-rt"></slot>
        </ykc-detailHeader>
      </slot>
    </div>
    <div class="line" v-if="isLine"></div>
    <div class="cardview-body">
      <!--自定义头-->
      <slot name="cvBody"></slot>
    </div>
  </div>
</template>

<script>
  import ykcDetailHeader from '@/components/ykc-ui/detail/src/detail-hearder.vue';

  export default {
    name: 'YkcDetail',
    components: {
      ykcDetailHeader,
    },
    props: {
      isHead: {
        // 是否含头部
        type: Boolean,
        default: true,
      },
      isLine: {
        // 是否含线条
        type: Boolean,
        default: false,
      },
      tips: {
        // 是否有标题旁边的小点点
        type: Boolean,
        default: true,
      },
      /**
       * 标题
       * 字符串数组：['日期日期', '姓名姓名姓名']
       * 对象数组：[{label: '日期',value: '2016-05-02'}]
       */
      titles: {
        type: Array,
        default: () => [],
      },
      rightInfo: {
        // 右侧信息：type：text(文字)，
        type: Array,
        default: () => [],
      },
      /**
       * 右侧按钮
       * 参数
       * {
       *     shape：default //默认：default可不填，类型：switch（切换）
       *     type：//按钮类型，参考按钮组件
       *     switchStyle：{width:xx,height:xx}//滑块样式
       *     text：['图', '表']滑块文字
       * }
       */
      btns: {
        type: Array,
        default: () => [],
      },
      /**
       * 标签集合
       * type:标签种类；tt1,tt2,tt3,tt4,tt5
       * name:名称
       */
      tags: {
        type: Array,
        default: () => [],
      },
      className: {
        // 判断页面样式
        type: String,
        default: '',
      },
    },
    methods: {
      /**
       * 点击切换按钮
       * @param self：按钮参数
       * @param $index：按钮下标
       * @param mod：滑块选中值
       */
      handleChange(mod, self, $index) {
        this.$emit('change', mod, self, $index);
      },
      /**
       * 点击普通按钮
       * @param self：按钮参数
       * @param $index：按钮下标
       */
      handleClick(self, $index) {
        this.$emit('btn-click', self, $index);
      },
    },
  };
</script>
<style lang="scss">
  @import './index.scss';
</style>
