<!--
 * @Author: Yanxia Wan
 * @Date: 2021-09-26 10:29:20
 * @LastEditTime: 2021-09-26 18:23:42
 * @Description: radio组件
-->
<template>
  <div class="ykc-radio">
    <el-radio-group v-model="model" :disabled="disabled" @change="handleChange">
      <el-radio
        :style="{ width: getWidth(item, index) + 'px' }"
        v-for="(item, index) in data"
        :key="index"
        :label="getValue(item)"
        :disabled="item.disabled">
        {{ getLabel(item) }}
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
  export default {
    name: 'YkcRadio',
    // 接收父组件数据
    props: {
      value: {
        // value值
        type: [String, Number],
        default: '',
      },
      /**
       * 数据格式:
       * data：绑定数据 array
       * [{value:'xx',id:'xxx',disabled:true||false}]
       * disabled: 是否置灰
       * width: 该label显示长度（用于多列对应）
       * */
      data: {
        // 数据源
        type: Array,
        default: () => [],
      },
      widthArr: {
        // 跟数据源对应一致。需要调整样式的 如数据源[{value:'1',id:'1'},{value:'2',id:'2'}]需要调整第二个的宽度为6个字符长度，则widthArr传入[null, 6]
        type: Array,
        default: () => [],
      },
      disabled: {
        // 禁用
        type: Boolean,
        default: false,
      },
      configSet: {
        // 配置项  数据源必须存在配置项的字段
        type: Object,
        default: () => ({
          label: 'name',
          value: 'id',
        }),
      },
    },
    data() {
      return {
        width: 0, // 单个radio的label的长度
      };
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
    methods: {
      /**
       * 值改变 方法
       */
      handleChange(evt) {
        this.$emit('change', evt);
      },
      /**
       * 获取label的配置
       */
      getLabel(item) {
        return item ? item[this.configSet.label] : '';
      },
      /**
       * 获取value的配置
       */
      getValue(item) {
        return item ? item[this.configSet.value] : '';
      },
      /**
       * 获取label的长度
       */
      getWidth(item, index) {
        const ICON_WIDTH = 12; // 图标的宽度
        const ICON_TEXT_GAP_WIDTH = 8; // 图标和文字之间的宽度
        const WHITE_SPACE_WITH = 3.86; // 空格宽度
        // 检查宽度数组是否存在并具有索引，
        // 否则获取标签值的长度
        const widthLen =
          this.widthArr && this.widthArr.length && this.widthArr[index]
            ? this.widthArr[index]
            : item[this.configSet.label].length;
        // 返回总宽度
        return ICON_WIDTH + ICON_TEXT_GAP_WIDTH + WHITE_SPACE_WITH + 12 * widthLen;
      },
    },
  };
</script>

<style lang="scss">
  @import '~@/components/ykc-ui/styles/var.scss';
  .ykc-radio {
    .el-radio {
      font-weight: 400;
    }
    .el-radio__input.is-checked {
      .el-radio__inner {
        border: 1px solid var(--theme-color-primary);
        background: $_FFFFFF;
        &::after {
          width: 8px;
          height: 8px;
          background-color: var(--theme-color-primary);
        }
      }
    }

    .el-radio__label {
      padding-left: 6px;
      font-size: 12px;
    }

    //置灰样式
    .el-radio__input.is-disabled {
      .el-radio__inner {
        border-color: $_D8D8D8;
        &::after {
          background-color: $_D8D8D8;
        }
      }

      .el-radio__label {
        color: $_D8D8D8;
        padding-left: 6px;
      }
    }

    .el-radio {
      .el-radio__inner {
        background-color: $_FFFFFF;
        border: 1px solid $_D8D8D8;
      }

      .el-radio__label {
        color: $_000000;
      }
    }

    // 选择的样式
    .is-checked {
      .el-radio__inner {
        background: $_FFFFFF;
      }

      .el-radio__label {
        color: var(--theme-color-primary);
      }
    }

    .is-disabled {
      cursor: not-allowed;
    }
  }
  body[ykc-ui='soui'] {
    .ykc-radio {
      box-sizing: border-box;
      -moz-box-sizing: border-box; /* Firefox */
      -webkit-box-sizing: border-box; /* Safari */
      .el-radio {
        font-weight: Medium;
      }
      .el-radio__input.is-checked {
        .el-radio__inner {
          border: 1px solid #00fff4;
          &::after {
            width: 8px;
            height: 8px;
            background-color: #00fff4;
          }
        }
      }

      .el-radio__label {
        padding-left: 6px;
        font-size: 12px;
      }

      .el-radio {
        .el-radio__inner {
          background-color: #090a18;
          border: 1px solid $_FFFFFF;
        }

        .el-radio__label {
          color: $_FFFFFF;
        }
      }

      //置灰样式
      .is-disabled {
        .el-radio__inner {
          border-color: $_FFFFFF;
          opacity: 0.5;
          &::after {
            background: rgba(255, 255, 255, 0.3);
          }
        }

        .el-radio__label {
          color: $_FFFFFF;
          opacity: 0.6;
          padding-left: 6px;
          font-weight: Regular;
        }
      }

      // 选择的样式
      .is-checked {
        .el-radio__inner {
          background: #090a18;
        }

        .el-radio__label {
          color: #00fff4;
        }
      }

      .is-disabled {
        cursor: not-allowed;
      }
    }
  }
</style>
