/**
 *@author huhongyun
 *@date 2021/10/22
 *@component $dialog.js YkcDialog - 弹窗函数版本
 */
import Vue from 'vue';
import {
  merge,
  isObject,
  isVNode,
  hasOwn,
  isFunction,
  isString,
} from '@/components/ykc-ui/dialog/src/helpler';
import FeedBackDialog from '@/components/ykc-ui/feedback-dialog';
import OperationDialog from '@/components/ykc-ui/operation-dialog';

const FeedBackDialogConstructor = Vue.extend(FeedBackDialog);
const OperationDialogConstructor = Vue.extend(OperationDialog);

let instance;
const instances = [];
let seed = 1;
// 可以使用的弹窗类型
const allowDialogTypeList = ['operation', 'feedback'];
// 弹窗可传参数列表，这里将反馈和操作弹窗的props 进行了合并
const propsAttrList = [
  'isJSVersion',
  'desc',
  'footerBtnStyle',
  'fullscreen',
  'multipleLine',
  'size',
  'showCancelButton',
  'showFooter',
  'showSuccessIcon',
  'showWarningIcon',
  'showErrorIcon',
  'showTitle',
  'title',
  'type',
  'top',
  'modal',
  'modalAppendToBody',
  'appendToBody',
  'lockScroll',
  'customClass',
  'closeOnClickModal',
  'closeOnPressEscape',
  'showClose',
  'center',
  'destroyOnClose',
  'customerWidth',
  'cancelBtnTxt',
  'confirmBtnTxt',
  'beforeCancel',
  'beforeConfirm',
  'onClose',
];
// 弹窗 data 列表
const dataAttrList = ['widget', 'widgetData', 'descData', 'defaultFullScreen', 'closeIcon'];
// 类型名称
const TYPES_NAME_MAP = {
  success: '成功',
  error: '失败',
  warning: '警告',
  info: '信息',
};
// 声明一个部件
let widget;
// eslint-disable-next-line func-names
const YkcDialog = function (options) {
  // props 属性对象
  const propsMap = {};
  // data 属性对象
  const dataMap = {};
  if (Vue.prototype.$isServer) return;
  if (typeof options === 'string') {
    // 如果直接传递 string 类型 ， 那么 将使用最简【反馈弹框】的方式显示
    options = {
      desc: options,
      dialogType: 'feedback',
      showTitle: false,
      showFooter: false,
    };
  }
  // 如果是通过 obj 的方式传参，需要转换 对外抛出的 type 为当前对应的弹框类型和对应的图标类型
  if (isObject(options) && !isVNode(options) && isString(options.desc)) {
    if (['success', 'warning', 'error'].includes(options.type)) {
      // eslint-disable-next-line default-case
      switch (options.type) {
        case 'success':
          options = {
            dialogType: 'feedback',
            showSuccessIcon: true,
            ...options,
          };
          break;
        case 'warning':
          options = {
            dialogType: 'feedback',
            showWarningIcon: true,
            ...options,
          };
          break;
        case 'error':
          options = {
            dialogType: 'feedback',
            showErrorIcon: true,
            ...options,
          };
          break;
      }
    }
  }

  options = merge({}, options, { isJSVersion: true });
  // 特殊对待的三个钩子函数
  const userOnClose = options.onClose;
  const userOnCancel = options.onCancel;
  const userOnConfirm = options.onConfirm;
  delete options.onClose;
  delete options.onCancel;
  delete options.onConfirm;

  const id = `ykc_dialog_${seed++}`;
  options.onClose = userOnClose;
  options.beforeCancel = userOnCancel;
  options.beforeConfirm = userOnConfirm;
  Object.keys(options)
    // eslint-disable-next-line array-callback-return,consistent-return
    .filter(item => {
      if (propsAttrList.includes(item)) {
        return item;
      }
    })
    .forEach(item => {
      propsMap[item] = options[item];
    });

  Object.keys(options)
    // eslint-disable-next-line prefer-const,array-callback-return,consistent-return
    .filter(item => {
      if (dataAttrList.includes(item)) {
        return item;
      }
    })
    .forEach(item => {
      dataMap[item] = options[item];
    });

  const dialogType = options.dialogType || 'operation';
  delete options.dialogType;
  // 如果想弹出的弹出选项不是 【操作类】 或 【反馈类】 则退出
  const isInclude = allowDialogTypeList.includes(dialogType);
  if (!isInclude) {
    return;
  }
  // 如果类型是 【操作类】
  if (dialogType === 'operation') {
    if (!dataMap.widget || !isObject(dataMap.widget)) {
      throw new ReferenceError('widget is not found, please provide Vue Component ');
    }
    instance = new OperationDialogConstructor({
      data: dataMap,
      propsData: propsMap,
    });

    // 如果对象是一个 Vue Component
    if (
      !hasOwn(dataMap.widget, 'functional') &&
      hasOwn(dataMap.widget, 'render') &&
      isFunction(dataMap.widget.render)
    ) {
      widget = instance.$createElement(dataMap.widget);
      if (isVNode(widget)) {
        instance.$slots.content = [widget];
      }
    } else {
      throw new TypeError('widget is not one Vue Component !,please check your component');
    }
  }
  // 如果类型是 【反馈类】
  if (dialogType === 'feedback') {
    instance = new FeedBackDialogConstructor({
      data: dataMap,
      propsData: propsMap,
    });
    // 如果描述消息是 VNode 类型
    if (isVNode(propsMap.desc)) {
      console.log('isVNode');
      // 如果 消息内容 本身就是 VNode 类型
      instance.$slots.desc = [propsMap.desc];
    }
    // 如果描述信息是 Component 对象
    if (
      isObject(propsMap.desc) &&
      !hasOwn(propsMap.desc, 'functional') &&
      hasOwn(propsMap.desc, 'render') &&
      isFunction(propsMap.desc.render)
    ) {
      let contentSlot;
      if (dataMap.descData) {
        contentSlot = instance.$createElement(propsMap.desc, merge({}, dataMap.descData));
      } else {
        contentSlot = instance.$createElement(propsMap.desc);
      }
      if (isVNode(contentSlot)) {
        instance.$slots.desc = [contentSlot];
      }
    }
    // 如果传递了 content 的部件，那么， content slot
    if (
      isObject(dataMap.widget) &&
      !hasOwn(dataMap.widget, 'functional') &&
      hasOwn(dataMap.widget, 'render') &&
      isFunction(dataMap.widget.render)
    ) {
      if (dataMap.widgetData) {
        widget = instance.$createElement(dataMap.widget, merge({}, dataMap.widgetData));
      } else {
        widget = instance.$createElement(dataMap.widget);
      }
      if (isVNode(widget)) {
        instance.$slots.content = [widget];
      }
    }
  }

  instance.id = id;
  instance.$mount();
  document.body.appendChild(instance.$el);
  instance.dom = instance.$el;
  instances.push(instance);
  instance.show = true;
  // 补充一个css 样式属性，用来验证 firefox 的模糊背景问题
  const $dialog = instance.$el.querySelector('.ykc-dialog.el-dialog');
  if ($dialog) {
    const dialogRect = $dialog.getBoundingClientRect();
    //   设置 css 属性
    $dialog.style.setProperty('background-position-x', `${dialogRect.x}px`);
    $dialog.style.setProperty('background-position-y', `${dialogRect.y}px`);
  }
  // eslint-disable-next-line consistent-return
  return instance;
};
['success', 'warning', 'error'].forEach(type => {
  YkcDialog[type] = options => {
    if (isObject(options) && !isVNode(options)) {
      return YkcDialog({
        ...options,
        type,
      });
    }
    return YkcDialog({
      title: TYPES_NAME_MAP[type],
      showCancelButton: false, // type === 'warning',
      desc: options,
      type,
    });
  };
});
// eslint-disable-next-line func-names
YkcDialog.close = function (id, userOnClose) {
  let index = -1;
  // eslint-disable-next-line no-shadow
  const instance = instances.filter((instance, i) => {
    if (instance.id === id) {
      index = i;
      return true;
    }
    return false;
  })[0];
  if (!instance) return;

  if (typeof userOnClose === 'function') {
    userOnClose();
  }
  if (instance.$el && instance.$el.parentNode) {
    instance.$el.parentNode.removeChild(instance.$el);
  }
  // 由于组件不停的创建，所以必须要销毁，销毁的同时，会调用 destroy 的 钩子函数 完成对 modal 层的操作
  instance.$destroy();
  instances.splice(index, 1);
};

// eslint-disable-next-line func-names
YkcDialog.closeAll = function () {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close();
  }
};
Vue.prototype.$dialog = YkcDialog;

export default YkcDialog;
