/* import YkcRechargeCouponBox from './src/main.vue';

YkcRechargeCouponBox.install = function (Vue) {
  Vue.component(YkcRechargeCouponBox.name, YkcRechargeCouponBox);
};

export default YkcRechargeCouponBox; */

import Component from './src/main.vue';

Component.install = function install(_Vue) {
  _Vue.component(Component.name, Component);
};

export default Component;
