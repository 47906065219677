import Vue from 'vue';
import Viewer from 'v-viewer';

import App from './App.vue';
import store from './store';
import './plugins';
import './service';
import router from './router';
import 'viewerjs/dist/viewer.css';
import i18n from './i18n/index';
import './icons';

Vue.config.productionTip = false;
Vue.use(Viewer);

Viewer.setDefaults({
  // 需要配置的属性
  toolbar: false,
  navbar: true,
  title: false,
});
new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
