<!--
 * @Author: Yanxia Wan
 * @Date: 2021-10-09
 * @Description: 按钮样式的多选组合
-->
<template>
  <div class="ykc-checkbox-button">
    <el-checkbox-group
      @change="checkboxChangeEvent"
      v-model="model"
      :min="min"
      :max="max"
      :size="size">
      <el-checkbox-button
        v-for="(item, index) in data"
        :key="index"
        :label="getValue(item)"
        :disabled="disableList.indexOf(getValue(item)) > -1">
        {{ getLabel(item) }}
      </el-checkbox-button>
    </el-checkbox-group>
  </div>
</template>

<script>
  export default {
    name: 'YkcCheckboxButton',
    props: {
      data: {
        // 数据源
        type: Array,
        default: () => [],
      },
      disableList: {
        // 禁用集合
        type: Array,
        default: () => [],
      },
      value: [], // value值
      max: Number, // 最大选择个数
      min: Number, // 最小选择个数
      size: String, // 多选框组尺寸
      configSet: {
        // 配置项  数据源必须存在配置项的字段
        type: Object,
        default: () => ({
          label: 'name',
          value: 'id',
        }),
      },
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
    methods: {
      /**
       * 选择的值改变
       */
      checkboxChangeEvent() {
        this.$emit('change', this.model);
      },
      /**
       * 获取label的配置
       */
      getLabel(item) {
        return item ? item[this.configSet.label] : '';
      },
      /**
       * 获取value的配置
       */
      getValue(item) {
        return item ? item[this.configSet.value] : '';
      },
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
