/*
 * @Author: Daokai Huo
 * @Date: 2021-10-19 19:28:26
 * @LastEditTime: 2021-10-19 19:35:00
 * @Description:
 */
import YkcForm from './src/ykc-form.vue';

YkcForm.install = function install(_vue) {
  _vue.component(YkcForm.name, YkcForm);
};
export default YkcForm;
