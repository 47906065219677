<!--
 * @Descripttion: tree组件
 * @Author: 汪佳彪
 * @date: 2021-10-22 15:22
-->
<script>
  /**
   * copy from vxe-table
   */
  import XEUtilsRemove from 'xe-utils/remove';
  import XEUtilsBrowse from 'xe-utils/browse';
  import XEUtilsIsNumber from 'xe-utils/isNumber';
  import XEUtilsToNumber from 'xe-utils/toNumber';
  import Bar from './bar.vue';

  /**
   * 监听 resize 事件
   * 如果项目中已使用了 resize-observer-polyfill，那么只需要将方法定义全局，该组件就会自动使用
   */
  let resizeTimeout;
  const ResizeEventStore = [];
  const defaultInterval = 500;

  function eventHandle() {
    if (ResizeEventStore.length) {
      ResizeEventStore.forEach(item => {
        item.tarList.forEach(observer => {
          const { target, width, heighe } = observer;
          const { clientWidth } = target;
          const { clientHeight } = target;
          const rWidth = clientWidth && width !== clientWidth;
          const rHeight = clientHeight && heighe !== clientHeight;
          if (rWidth || rHeight) {
            observer.width = clientWidth;
            observer.heighe = clientHeight;
            requestAnimationFrame(item.callback);
          }
        });
      });
      // eslint-disable-next-line no-use-before-define
      eventListener();
    }
  }

  function eventListener(interval) {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(eventHandle, interval || defaultInterval);
  }

  class ResizeObserverPolyfill {
    constructor(callback) {
      this.tarList = [];
      this.callback = callback;
    }

    observe(target) {
      if (target) {
        if (!this.tarList.some(observer => observer.target === target)) {
          this.tarList.push({
            target,
            width: target.clientWidth,
            heighe: target.clientHeight,
          });
        }
        if (!ResizeEventStore.length) {
          eventListener();
        }
        if (!ResizeEventStore.some(item => item === this)) {
          ResizeEventStore.push(this);
        }
      }
    }

    // eslint-disable-next-line class-methods-use-this
    unobserve(target) {
      XEUtilsRemove(ResizeEventStore, item =>
        item.tarList.some(observer => observer.target === target)
      );
    }

    disconnect() {
      XEUtilsRemove(ResizeEventStore, item => item === this);
    }
  }

  const ResizeEvent = XEUtilsBrowse().isDoc
    ? window.ResizeObserver || ResizeObserverPolyfill
    : ResizeObserverPolyfill;

  const GlobalEventStore = [];

  const getWheelName = () => {
    const browse = XEUtilsBrowse();
    return browse.firefox ? 'DOMMouseScroll' : 'mousewheel';
  };

  const GlobalEvent = {
    on(comp, type, cb) {
      if (cb) {
        GlobalEventStore.push({ comp, type, cb });
      }
    },
    off(comp, type) {
      XEUtilsRemove(GlobalEventStore, item => item.comp === comp && item.type === type);
    },
    trigger(evnt) {
      const isWheel = evnt.type === getWheelName();
      GlobalEventStore.forEach(({ comp, type, cb }) => {
        if (type === evnt.type || (isWheel && type === 'mousewheel')) {
          cb.call(comp, evnt);
        }
      });
    },
    eqKeypad(evnt, keyVal) {
      const { key } = evnt;
      if (keyVal.toLowerCase() === key.toLowerCase()) {
        return true;
      }
      return false;
    },
  };

  function triggerEvent(targetElem, type) {
    let evnt;
    if (typeof Event === 'function') {
      evnt = new Event(type);
    } else {
      evnt = document.createEvent('Event');
      evnt.initEvent(type, true, true);
    }
    targetElem.dispatchEvent(evnt);
  }

  export default {
    name: 'YkcTreeList',
    components: { Bar },
    props: {
      data: Array,
      height: {
        type: [Number, String],
        required: true,
      },
      maxHeight: [Number, String],
      width: {
        type: [Number, String],
        default: '100%',
      },
      size: { type: String, default: '50' },
      autoResize: { type: Boolean, default: false },
      syncResize: [Boolean, String, Number],
      scrollY: Object,
      defaultScrollButtonSize: {
        type: Number,
        default: 18,
      },
      /** 滚动条滑块尺寸 */
      scrollbarSize: {
        type: Object,
        default: () => ({ horizontal: 6, vertical: 6 }),
      },
      /** 滚动条距离边界的距离，默认x，y方向都是1px */
      gutter: {
        type: Object,
        default: () => ({ horizontal: 1, vertical: 1 }),
      },
    },
    data() {
      return {
        scrollYLoad: false,
        bodyHeight: 0,
        topSpaceHeight: 0,
        items: [],
        sizeHeight: 0,
        moveY: 0,
        moveYRatio: 0,
        sizeWidth: 0,
        moveX: 0,
        moveXRatio: 0,
      };
    },
    computed: {
      vSize() {
        return this.size || this.$parent.size || this.$parent.vSize;
      },
      sYOpts() {
        return { enabled: true, gt: 100, ...this.scrollY };
      },
      wrapperStyles() {
        const { maxHeight } = this;
        return {
          width: `calc(100% - 2px)`,
          height: `100%`,
          maxHeight: Number.isNaN(maxHeight) ? maxHeight : `${maxHeight}px`,
        };
      },
      rootStyles() {
        const { height, width } = this;
        return {
          height: Number.isNaN(Number(height)) ? height : `${height}px`,
          width: Number.isNaN(Number(width)) ? width : `${width}px`,
        };
      },
    },
    watch: {
      data(value) {
        this.loadData(value);
      },
      syncResize(value) {
        if (value) {
          this.recalculate();
          this.$nextTick(() => setTimeout(() => this.recalculate()));
        }
      },
    },
    created() {
      Object.assign(this, {
        fullData: [],
        lastScrollLeft: 0,
        lastScrollTop: 0,
        scrollYStore: {
          startIndex: 0,
          visibleIndex: 0,
          renderSize: 0,
        },
      });
      this.loadData(this.data);
      GlobalEvent.on(this, 'resize', this.handleGlobalResizeEvent);
    },
    mounted() {
      if (this.autoResize) {
        const resizeObserver = new ResizeEvent(() => this.recalculate());
        resizeObserver.observe(this.$el);
        this.$resize = resizeObserver;
      }
    },
    beforeDestroy() {
      if (this.$resize) {
        this.$resize.disconnect();
      }
    },
    destroyed() {
      GlobalEvent.off(this, 'resize');
    },
    render(h) {
      const { $scopedSlots, bodyHeight, topSpaceHeight, items } = this;
      const {
        sizeHeight,
        moveY,
        moveYRatio,
        sizeWidth,
        moveX,
        moveXRatio,
        scrollbarSize,
        rootStyles,
        wrapperStyles,
        defaultScrollButtonSize,
      } = this;
      const clonedWrapperStyles = { ...wrapperStyles };
      const clonedRootStyles = { ...rootStyles };
      const xVisible = this.isHorizontalScrollbarVisible();
      const yVisible = this.isVerticalScrollbarVisible();
      if (!xVisible) {
        if (yVisible) {
          clonedWrapperStyles.width = `calc(100% + ${defaultScrollButtonSize / 2}px)`;
          clonedWrapperStyles.paddingRight = `${defaultScrollButtonSize / 2}px`;
        }
      } else if (!yVisible) {
        clonedWrapperStyles.height = `calc(100% + ${defaultScrollButtonSize / 2}px)`;
        clonedWrapperStyles.paddingBottom = `${defaultScrollButtonSize / 2}px`;
      } else {
        clonedWrapperStyles.height = `calc(100% + ${defaultScrollButtonSize / 2}px)`;
        clonedWrapperStyles.paddingBottom = `${defaultScrollButtonSize / 2}px`;
        clonedWrapperStyles.width = `calc(100% + ${defaultScrollButtonSize / 2}px)`;
        clonedWrapperStyles.paddingRight = `${defaultScrollButtonSize / 2}px`;
      }
      return (
        <div class={['ykc-list']} style={clonedRootStyles}>
          {this.isHorizontalScrollbarVisible() ? (
            <Bar
              gutter={this.gutter}
              scrollbarSize={scrollbarSize}
              size={sizeWidth}
              move={moveX}
              moveRatio={moveXRatio}
            />
          ) : null}
          {this.isVerticalScrollbarVisible() ? (
            <Bar
              vertical
              gutter={this.gutter}
              scrollbarSize={scrollbarSize}
              size={sizeHeight}
              move={moveY}
              moveRatio={moveYRatio}
            />
          ) : null}
          <div
            ref="virtualWrapper"
            class="ykc-list-virtual-wrapper"
            style={clonedWrapperStyles}
            onScroll={this.scrollEvent}>
            <div
              ref="ySpace"
              class="ykc-list-y-space"
              style={{ height: bodyHeight ? `${bodyHeight}px` : '' }}></div>
            <div
              ref="virtualBody"
              class="ykc-list-body"
              style={{
                marginTop: topSpaceHeight ? `${topSpaceHeight}px` : '',
                // height: '100%',
              }}>
              {$scopedSlots.default
                ? $scopedSlots.default.call(this, { items, $list: this }, h)
                : []}
            </div>
          </div>
        </div>
      );
    },
    methods: {
      isHorizontalScrollbarVisible() {
        return (
          this.$refs.virtualWrapper &&
          this.$refs.virtualWrapper.scrollWidth > this.$refs.virtualWrapper.clientWidth
        );
      },
      isVerticalScrollbarVisible() {
        return (
          this.$refs.virtualWrapper &&
          this.$refs.virtualWrapper.scrollHeight > this.$refs.virtualWrapper.clientHeight
        );
      },
      getParentElem() {
        return this.$el.parentNode;
      },
      /**
       * 加载数据
       * @param {Array} datas 数据
       */
      loadData(datas) {
        const { sYOpts, scrollYStore } = this;
        const fullData = datas || [];
        Object.assign(scrollYStore, {
          startIndex: 0,
          endIndex: 1,
          visibleSize: 0,
          visibleIndex: 0,
        });
        this.fullData = fullData;
        this.scrollYLoad = sYOpts.enabled && sYOpts.gt > -1 && fullData.length > sYOpts.gt;
        this.handleData();
        return this.computeScrollLoad().then(() => {
          this.refreshScroll();
        });
      },
      /**
       * 重新加载数据
       * @param {Array} datas 数据
       */
      reloadData(datas) {
        this.clearScroll();
        return this.loadData(datas);
      },
      handleData() {
        const { fullData, scrollYLoad, scrollYStore } = this;
        this.items = scrollYLoad
          ? fullData.slice(
              scrollYStore.startIndex,
              Math.max(scrollYStore.startIndex + scrollYStore.renderSize, 1)
            )
          : fullData.slice(0);
        return this.$nextTick();
      },
      /**
       * 重新计算列表
       */
      recalculate() {
        const { $el } = this;
        if ($el.clientWidth && $el.clientHeight) {
          return this.computeScrollLoad();
        }
        return Promise.resolve();
      },
      /**
       * 清除滚动条
       */
      clearScroll() {
        const scrollBodyElem = this.$refs.virtualWrapper;
        if (scrollBodyElem) {
          scrollBodyElem.scrollTop = 0;
        }
        return this.$nextTick();
      },
      /**
       * 刷新滚动条
       */
      refreshScroll() {
        const { lastScrollLeft, lastScrollTop } = this;
        this.clearScroll();
        return this.$nextTick().then(() => {
          if (lastScrollLeft || lastScrollTop) {
            this.lastScrollLeft = 0;
            this.lastScrollTop = 0;
            return this.scrollTo(lastScrollLeft, lastScrollTop);
          }
          return null;
        });
      },
      /**
       * 如果有滚动条，则滚动到对应的位置
       * @param {Number} scrollLeft 左距离
       * @param {Number} scrollTop 上距离
       */
      scrollTo(scrollLeft, scrollTop) {
        const scrollBodyElem = this.$refs.virtualWrapper;
        if (XEUtilsIsNumber(scrollLeft)) {
          scrollBodyElem.scrollLeft = scrollLeft;
        }
        if (XEUtilsIsNumber(scrollTop)) {
          scrollBodyElem.scrollTop = scrollTop;
        }
        triggerEvent(scrollBodyElem, 'scroll');
        if (this.scrollYLoad) {
          // eslint-disable-next-line
          return new Promise(resolve => setTimeout(() => resolve(this.$nextTick()), 50));
        }
        return this.$nextTick();
      },
      computeScrollLoad() {
        return this.$nextTick().then(() => {
          // eslint-disable-next-line
          const { $refs, sYOpts, scrollYLoad, scrollYStore } = this;
          this.calculateThumbSize();
          if (scrollYLoad) {
            let rHeight = 48;
            if (sYOpts.rHeight) {
              rHeight = sYOpts.rHeight;
            } else {
              let firstItemElem;
              if ($refs.virtualBody) {
                if (sYOpts.sItem) {
                  firstItemElem = $refs.virtualBody.querySelector(sYOpts.sItem);
                }
                if (!firstItemElem) {
                  // eslint-disable-next-line prefer-destructuring
                  firstItemElem = $refs.virtualBody.children[0];
                }
              }
              if (firstItemElem) {
                rHeight = firstItemElem.offsetHeight;
              }
            }
            const visibleYSize = XEUtilsToNumber(
              sYOpts.vSize || Math.ceil($refs.virtualWrapper.clientHeight / rHeight)
            );
            scrollYStore.visibleSize = visibleYSize;
            scrollYStore.rowHeight = rHeight;
            if (!sYOpts.oSize) {
              scrollYStore.offsetSize = visibleYSize;
            }
            if (!sYOpts.rSize) {
              scrollYStore.renderSize = Math.max(6, visibleYSize + 2);
            }
            this.updateYData();
          } else {
            this.updateYSpace();
          }
        });
      },
      calculateThumbSize() {
        const wrap = this.$refs.virtualWrapper;
        if (!wrap) return;
        this.$nextTick().then(() => {
          setTimeout(() => {
            this.sizeHeight = wrap.clientHeight ** 2 / wrap.scrollHeight;
            this.sizeWidth = wrap.clientWidth ** 2 / wrap.scrollWidth;
            const computedHeight = Number(window.getComputedStyle(wrap).height.replace('px', ''));
            const computedWidth = Number(window.getComputedStyle(wrap).width.replace('px', ''));
            const isXVisible = this.isHorizontalScrollbarVisible();
            const isYVisible = this.isVerticalScrollbarVisible();
            if (isXVisible && isYVisible) {
              this.sizeHeight = computedHeight ** 2 / wrap.scrollHeight;
              this.sizeWidth = computedWidth ** 2 / wrap.scrollWidth;
            } else if (!isXVisible && isYVisible) {
              this.sizeWidth = computedWidth ** 2 / wrap.scrollWidth;
            } else if (isXVisible && !isYVisible) {
              this.sizeHeight = computedHeight ** 2 / wrap.scrollHeight;
            }
          }, 0);
        });
      },
      calculateThumbMovement(scrollBodyElem) {
        const wrap = scrollBodyElem;
        const { sizeWidth, sizeHeight, scrollbarSize } = this;
        let trueScrollableHeight = wrap.scrollHeight - wrap.clientHeight;
        this.moveY = (wrap.scrollTop * (wrap.clientHeight - sizeHeight)) / trueScrollableHeight;
        this.moveYRatio = wrap.scrollTop / trueScrollableHeight;
        let trueScrollableWidth = wrap.scrollWidth - wrap.clientWidth;
        this.moveX = (wrap.scrollLeft * (wrap.clientWidth - sizeWidth)) / trueScrollableWidth;
        this.moveXRatio = wrap.scrollLeft / trueScrollableWidth;
        const computedHeight = Number(window.getComputedStyle(wrap).height.replace('px', ''));
        const computedWidth = Number(window.getComputedStyle(wrap).width.replace('px', ''));
        const isXVisible = this.isHorizontalScrollbarVisible();
        const isYVisible = this.isVerticalScrollbarVisible();
        if (isXVisible && isYVisible) {
          trueScrollableHeight = wrap.scrollHeight - computedHeight;
          this.moveY =
            (wrap.scrollTop * (computedHeight + scrollbarSize.horizontal - sizeHeight)) /
            trueScrollableHeight;
          this.moveYRatio = wrap.scrollTop / trueScrollableHeight;

          trueScrollableWidth = wrap.scrollWidth - computedWidth;
          this.moveX =
            (wrap.scrollLeft * (computedWidth + scrollbarSize.vertical - sizeWidth)) /
            trueScrollableWidth;
          this.moveXRatio = wrap.scrollLeft / trueScrollableWidth;
        } else if (!isXVisible && isYVisible) {
          trueScrollableWidth = wrap.scrollWidth - computedWidth;
          this.moveX =
            (wrap.scrollLeft * (computedWidth + scrollbarSize.vertical - sizeWidth)) /
            trueScrollableWidth;
          this.moveXRatio = wrap.scrollLeft / trueScrollableWidth;
        } else if (isXVisible && !isYVisible) {
          trueScrollableHeight = wrap.scrollHeight - computedHeight;
          this.moveY =
            (wrap.scrollTop * (computedHeight + scrollbarSize.horizontal - sizeHeight)) /
            trueScrollableHeight;
          this.moveYRatio = wrap.scrollTop / trueScrollableHeight;
        }
      },
      scrollEvent(evnt) {
        const scrollBodyElem = evnt.target;
        const { scrollTop } = scrollBodyElem;
        const { scrollLeft } = scrollBodyElem;
        this.calculateThumbMovement(scrollBodyElem);
        const isX = scrollLeft !== this.lastScrollLeft;
        const isY = scrollTop !== this.lastScrollTop;
        this.lastScrollTop = scrollTop;
        this.lastScrollLeft = scrollLeft;
        if (this.scrollYLoad) {
          this.loadYData(evnt);
        }
        this.$emit('scroll', { scrollLeft, scrollTop, isX, isY, $event: evnt });
      },
      loadYData(evnt) {
        const { fullData, scrollYStore, isLoadData } = this;
        const { startIndex, renderSize, offsetSize, visibleSize, rowHeight } = scrollYStore;
        const scrollBodyElem = evnt.target;
        const { scrollTop } = scrollBodyElem;
        const toVisibleIndex = Math.ceil(scrollTop / rowHeight);
        let preload = false;
        if (isLoadData || scrollYStore.visibleIndex !== toVisibleIndex) {
          const marginSize = Math.min(Math.floor((renderSize - visibleSize) / 2), visibleSize);
          if (scrollYStore.visibleIndex > toVisibleIndex) {
            preload = toVisibleIndex - offsetSize <= startIndex;
            if (preload) {
              scrollYStore.startIndex = Math.max(
                0,
                toVisibleIndex - Math.max(marginSize, renderSize - visibleSize)
              );
            }
          } else {
            preload = toVisibleIndex + visibleSize + offsetSize >= startIndex + renderSize;
            if (preload) {
              scrollYStore.startIndex = Math.max(
                0,
                Math.min(fullData.length - renderSize, toVisibleIndex - marginSize)
              );
            }
          }
          if (preload) {
            this.updateYData();
          }
          scrollYStore.visibleIndex = toVisibleIndex;
          this.isLoadData = false;
        }
      },
      updateYData() {
        this.handleData();
        this.updateYSpace();
      },
      updateYSpace() {
        const { scrollYStore, scrollYLoad, fullData } = this;
        this.bodyHeight = scrollYLoad ? fullData.length * scrollYStore.rowHeight : 0;
        this.topSpaceHeight = scrollYLoad
          ? Math.max(scrollYStore.startIndex * scrollYStore.rowHeight, 0)
          : 0;
      },
      handleGlobalResizeEvent() {
        this.recalculate();
      },
    },
  };
</script>

<style lang="scss">
  .ykc-list {
    position: relative;
    display: block;
    padding: 0;
    overflow: hidden;
    &-y-space {
      width: 0;
      float: left;
    }
    &-virtual-wrapper,
    &-body {
      padding: 0;
      margin: 0;
      border: 0;
      outline: 0;
    }
    &-virtual-wrapper {
      position: relative;
      overflow: auto;
      scrollbar-color: transparent transparent;
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-track {
        opacity: 0;
      }
    }
    &:active > .scrollbar,
    &:focus > .scrollbar,
    &:hover > .scrollbar {
      opacity: 1;
      transition: opacity 340ms ease-out;
    }
  }
  .ykc-list-virtual-wrapper {
    height: 100px;
  }
</style>
