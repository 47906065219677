<!--
 * @Author: Daokai Huo
 * @Date: 2021-10-27 10:09:19
 * @LastEditTime: 2022-05-18 15:00:28
 * @Description: 组合组件
-->
<template>
  <ykc-form :model="ruleForm" ref="combination" class="combination-wrap">
    <div class="combination-item" v-for="(item, index) in ruleForm.data" :key="index">
      <div
        class="ykc-icon-reduce"
        :class="ruleForm.data.length < 2 && 'icon-disabled'"
        @click="delBtn(index)"></div>
      <div class="combination-multiple">
        <ykc-form-item
          v-for="(subItems, subIndex) in item.subItem"
          :prop="'data.' + index + '.subItem.' + subIndex + '.value'"
          :rules="subItems.rules"
          :key="subItems.subIndex">
          <component
            :is="subItems.comName"
            v-model="subItems.value"
            v-bind="subItems"
            @focus="handleFocus($event, subItems, index)">
            <template v-if="subItems.prepend" slot="prepend">
              {{ subItems.prepend }}
            </template>
            <template v-if="subItems.append" slot="append">{{ subItems.append }}</template>
          </component>
        </ykc-form-item>
      </div>
    </div>
    <div class="add-btn" @click="addItem" v-if="max">
      <ykc-icon name="add"></ykc-icon>
      <span>{{ addText }}</span>
    </div>
  </ykc-form>
</template>

<script>
  // import AsyncValidator from 'async-validator';
  import YkcInput from '@/components/ykc-ui/input';
  import YkcDropdown from '@/components/ykc-ui/dropdown';

  export default {
    name: 'YkcCombinationItem',
    components: {
      YkcInput,
      YkcDropdown,
    },
    props: {
      // 是否必填
      required: Boolean,
      // 最大可添加数量
      maxlength: {
        type: Number,
        default: 30,
      },
      // 添加文字
      addText: {
        type: String,
        default: '添加车辆',
      },
      // 基础数据
      basicData: {
        type: Array,
        default: () => [],
      },
      data: Array,
      // 下拉框数据
      selectData: {
        type: Array,
        default: () => [],
      },
      // data: {
      //   type: Array,
      //   default: () => [
      //     {
      //       carNumber: '这是用户卡号1',
      //       dp: '1',
      //     },
      //     {
      //       carNumber: '这是用户卡号2',
      //       dp: '1',
      //     },
      //   ],
      // },
    },
    data() {
      return {
        ruleForm: {
          data: [],
        },
        rules: [],
        dropdown: this.selectData,
      };
    },
    created() {
      this.setValue();
    },
    mounted() {},
    watch: {
      data: {
        handler(val) {
          if (val) this.setValue();
        },
        // 首次绑定是否执行handle
        immediate: false,
        // 是否深度监听
        deep: true,
      },
      // 下拉数据
      selectData: {
        handler(val) {
          this.dropdown = val;
        },
        // 首次绑定是否执行handle
        immediate: false,
        // 是否深度监听
        deep: true,
      },
    },
    computed: {
      // 支持最大行数
      max() {
        return this.maxlength > this.ruleForm.data.length;
      },
    },
    methods: {
      /**
       * 设置配置数据
       */
      setValue() {
        let result = [];
        const { data } = this;
        if (data && data.length) {
          result = data.map(item => this.initData(item));
        } else {
          result = [this.initData()];
        }
        this.ruleForm.data = result;
      },
      /**
       * 初始化一条配置数据
       */
      initData(val) {
        const { basicData } = this;
        // 保存校验规则
        this.rules = basicData.map(rule => rule.rules);
        // 拷贝数据
        const subItem = JSON.parse(JSON.stringify(basicData));
        // 添加自定义属性
        subItem.forEach((item, index) => {
          item.value = val ? val[item.key] : '';
          subItem[index].rules = this.rules[index];
          // 赋值下拉数据
          if (item.comName === 'YkcDropdown' && this.selectData.length) {
            item.data = this.dropdown;
            console.log('subItem 初始化啥0', item.data);
          }
        });
        console.log('subItem 初始化啥', subItem);
        return { subItem };
      },

      /**
       * 删除操作
       */
      delBtn(index) {
        const { length } = this.ruleForm.data;
        if (length < 2) {
          return;
        }
        this.ruleForm.data.splice(index, 1);
        this.$emit('delBtn', index);
      },
      /**
       * 添加操作
       */
      async addItem() {
        const result = await this.checkForm();
        if (result) {
          this.ruleForm.data.push(this.initData());
        }
      },
      /**
       * 获取最终数据
       */
      getResult() {
        let validState = true;
        return this.checkForm().then(checkState => {
          const dealData = this.dealResult();
          if (!this.required) {
            const { length } = this.ruleForm.data;
            // 存在数据需要校验是否合法
            const state = dealData.some(item => Object.values(item)[0]);
            if (state || length > 1) {
              validState = checkState;
            } else {
              this.$refs.combination.clearValidate();
            }
          } else {
            validState = checkState;
          }
          return Promise.resolve({ data: validState ? dealData : [], validState });
        });
      },
      /**
       * form校验
       */
      checkForm() {
        let check = true;
        return new Promise(resolve => {
          this.$refs.combination.validate(valid => {
            if (!valid) {
              check = false;
            }
            resolve(check);
          });
        });
      },
      /**
       * 数据转换
       */
      dealResult() {
        const { data } = this.ruleForm;
        const arr = [];
        data.forEach((item, index) => {
          arr[index] = {};
          item.subItem.forEach(sub => {
            if (sub.value) {
              arr[index][sub.key || 'value'] = sub.value;
            }
          });
        });
        return Object.keys(arr[0]).length ? arr : [];
      },
      /**
       * 获取焦点的时候校验
       */
      handleFocus(v, row, rowIndex) {
        if (row.comName === 'YkcDropdown') {
          if (row.only) {
            const resultData = this.dealResult();
            const selectIds = [];
            const { data } = this.ruleForm;
            console.log('data', data);
            // 已选择的优惠券（排除当前行）
            resultData.forEach((item, index) => {
              if (index !== rowIndex) {
                selectIds.push(item.couponId);
              }
            });
            data.forEach((item, k) => {
              const ite = item.subItem.filter(o => o.comName === 'YkcDropdown')[0];
              // 过滤已选择的优惠券
              if (k === rowIndex) {
                console.log('过滤已经', rowIndex);
                ite.data = this.dropdown.filter(obj => !selectIds.includes(obj.id));
                console.log('过滤已经22', data);
              }
            });
          }
        }
      },
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
