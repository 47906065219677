<!--
 * @Descripttion: 电价配置demo
 * @Author: 汪佳彪
 * @date: 2022-01-13 10:31
-->
<template>
  <div class="electrovalence">
    <div class="price-wrap">
      <div v-for="item in priceItems" :key="item.id" class="price-item">
        <div
          class="icon"
          @click="changeActiveId(item.id)"
          :style="{
            backgroundImage: `url(${item.img})`,
            color: `${item.color}`,
            border: item.id === activeId ? `1px solid ${item.color}` : 'none',
          }">
          <span>{{ item.text }}</span>
        </div>
        <div class="form">
          <div class="field" @click="focusInput">
            <ykc-input v-model="item.EFee" type="number">
              <template slot="prepend">
                <span>电</span>
                <span style="opacity: 0">电</span>
                <span>费：</span>
              </template>
              <template slot="append">
                <span style="color: white">元/度</span>
              </template>
            </ykc-input>
          </div>
          <div class="field" @click="focusInput">
            <ykc-input v-model="item.SFee" type="number">
              <template slot="prepend"><span>服务费：</span></template>
              <template slot="append">
                <span style="color: white">元/度</span>
              </template>
            </ykc-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import YkcInput from '@/components/ykc-ui/input';
  import JianImg from './electrovalence-jian@2x.png';
  import FengImg from './electrovalence-feng@2x.png';
  import PingImg from './electrovalence-ping@2x.png';
  import GuImg from './electrovalence-gu@2x.png';

  export default {
    components: {
      YkcInput,
    },
    props: {
      activeId: {
        type: String,
        default: '',
      },
      value: {
        // type: Object,
        required: true,
      },
    },
    data() {
      return {
        // 需要顺序,故用数组
        priceItems: [
          { color: '#65CF9B', text: '尖', id: 'jian', EFee: '', SFee: '', img: JianImg },
          { color: '#FF895D', text: '峰', id: 'feng', EFee: '', SFee: '', img: FengImg },
          { color: '#61C9FF', text: '平', id: 'ping', EFee: '', SFee: '', img: PingImg },
          { color: '#FFD470', text: '谷', id: 'gu', EFee: '', SFee: '', img: GuImg },
        ],
      };
    },
    watch: {
      priceItems: {
        immediate: true,
        deep: true,
        handler(val) {
          this.$emit('price-change', val);
        },
      },
    },
    methods: {
      focusInput({ target }) {
        target.closest('.field').querySelector('input').focus();
      },
      changeActiveId(id) {
        this.$emit('update:activeId', id);
      },
    },
  };
</script>

<style lang="scss">
  .electrovalence {
    // background-image: linear-gradient(180deg, #002869 0%, #001432 50%, #002869 100%);
    padding: 8px 0;
    .price-wrap {
      width: 480px;
      height: 170px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .price-item {
      height: 70px;
      width: 225px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;
      > .icon {
        box-sizing: border-box;
        cursor: pointer;
        width: 48px;
        height: 48px;
        background-size: 48px 48px;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        > span {
          margin-top: -16px;
        }
      }
      > .form {
        flex: 1;
        height: 100%;
        margin-left: 14px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: relative;
        background-image: url(./electrovalence-form-bg@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        > .field {
          display: flex;
          justify-content: center;
          align-items: center;
          // height: 50%;
          width: 100%;
          .ykc-input {
            border: none;
            padding-left: 12px;
            input {
              background: transparent;
              border: none;
              color: white;
              padding: 0;
              font-size: 12px;
              position: relative;
              top: 1px;
              padding-right: 40px;
              -moz-appearance: textfield;
              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button {
                appearance: none;
              }
              &::placeholder {
                color: white;
                opacity: 0.6;
                font-size: 12px;
              }
            }
            .el-input-group__prepend {
              width: 48px;
              padding: 0;
              color: #00fff4;
              font-size: 12px;
              letter-spacing: 0;
              border: none;
              &::after {
                background: transparent;
                display: none;
              }
            }
            .el-input-group__append {
              padding: 0;
              height: 100%;
              font-size: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              top: 0;
              right: 0;
            }
          }
        }
      }
      &:nth-child(-n + 2) {
        align-self: flex-start;
      }
      &:nth-child(n + 3) {
        align-self: flex-end;
      }
    }
  }
</style>
