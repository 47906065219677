/**
 *@author huhongyun
 *@date 2021/10/
 *@component 左侧消息组件
 */
import Vue from 'vue';
import { Message } from 'element-ui';
import { isVNode, isObject } from '@/components/ykc-ui/message/src/helper';
import {
  frameworkHeaderHeight,
  frameworkHeaderHeightNormal,
  sidebarWidth,
  sidebarWidthNormal,
} from './index.scss';

let instance;
const instances = [];
let seed = 1;
const RADIX = 10;
const OFFSET = 10;
const VERTICAL_OFFSET = 4;
const YKcMessage = opts => {
  if (Vue.prototype.$isServer) return;
  opts = opts || {};
  if (typeof opts === 'string') {
    opts = {
      message: opts,
    };
  }
  const userOnClose = opts.onClose;
  const id = `message_${seed++}`;

  opts.onClose = () => {
    YKcMessage.close(id, userOnClose);
  };
  const cw = document.body.clientWidth;
  opts.customClass = `${'ykc-message ykc-message-'}${opts.type}`;
  if (!opts.offset) {
    if (cw > 1366) {
      opts.offset = parseInt(frameworkHeaderHeight, RADIX) + OFFSET;
    } else {
      opts.offset = parseInt(frameworkHeaderHeightNormal, RADIX) + OFFSET;
    }
  }
  // 修改消息盒子样式
  instance = Message(opts);
  // 增加元素的高度,原偏移高度为 16
  const warningTips = document.body.querySelectorAll('.ykc-message');
  instance.verticalOffset += VERTICAL_OFFSET * (warningTips.length - 1);

  // 修改内容样式
  // const $content = instance.$el.querySelector('.el-message__content');
  // if ($content) {
  //   // $content.style.color = `#000000`;
  // }
  // 传递的 left 的参数是数值类型 且不为0
  if (opts.left && typeof opts.left === 'number') {
    if (cw > 1366) {
      instance.$el.style.left = `${(opts.left + parseInt(sidebarWidth, RADIX)) * 2 + OFFSET}px`;
      instance.$el.style.transition = 'opacity .3s,transform 1s ease-in 1s ';
      instance.$el.style.transform = `translateX(-${opts.left + parseInt(sidebarWidth, RADIX)}px)`;
    } else {
      instance.$el.style.left = `${
        (opts.left + parseInt(sidebarWidthNormal, RADIX)) * 2 + OFFSET
      }px`;
      instance.$el.style.transition = 'opacity .3s,transform 1s ease-in 1s ';
      instance.$el.style.transform = `translateX(-${
        opts.left + parseInt(sidebarWidthNormal, RADIX)
      }px)`;
    }
  }
  // 传递的 left 是 bool 变量 且 为 true
  if (opts.left && typeof opts.left === 'boolean') {
    if (cw > 1366) {
      instance.$el.style.left = `${2 * parseInt(sidebarWidth, RADIX)}px`;
      instance.$el.style.transition = 'opacity .3s,transform 1s ease-in 1s ';
      instance.$el.style.transform = `translateX(-${parseInt(sidebarWidth, RADIX)}px)`;
    } else {
      instance.$el.style.left = `${2 * parseInt(sidebarWidthNormal, RADIX)}px`;
      instance.$el.style.transition = 'opacity .3s,transform 1s ease-in 1s ';
      instance.$el.style.transform = `translateX(-${parseInt(sidebarWidthNormal, RADIX)}px)`;
    }
  }
  instances.push(instance);
  // eslint-disable-next-line consistent-return
  return instance;
};

['success', 'warning', 'info', 'error'].forEach(type => {
  YKcMessage[type] = options => {
    if (isObject(options) && !isVNode(options)) {
      return YKcMessage({
        left: true,
        ...options,
        type,
      });
    }
    return YKcMessage({
      type,
      left: true,
      message: options,
    });
  };
});
YKcMessage.close = (id, userOnClose) => {
  const len = instances.length;
  // const index = -1;
  let removedHeight;
  for (let i = 0; i < len; i++) {
    if (id === instances[i].id) {
      removedHeight = instances[i].$el.offsetHeight;
      console.debug('removedHeight::', removedHeight);
      if (typeof userOnClose === 'function') {
        userOnClose(instances[i]);
      }
      instances.splice(i, 1);
      break;
    }
  }
};
YKcMessage.closeAll = () => {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close();
  }
};
Vue.prototype.$message = YKcMessage;
export default YKcMessage;
