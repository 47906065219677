import debounce from '@/components/ykc-ui/utils/debounce';

export default {
  data() {
    return {
      limitWidth: 1366,
      type: 'expand', // expand or collapse
      debounceWait: 100,
    };
  },
  beforeMount() {
    this.initEvent();
    this.mixinHandleWindowResize();
  },
  methods: {
    initEvent() {
      const handler = debounce(this.mixinHandleWindowResize, this.debounceWait);
      window.addEventListener('resize', handler);
      this.$on('hook:destroyed', () => {
        window.removeEventListener('resize', handler);
      });
    },
    mixinHandleWindowResize() {
      const { limitWidth } = this;
      const { innerWidth } = window;
      const isExpanded = innerWidth > limitWidth;
      this.type = isExpanded ? 'expand' : 'collapse';
      this.calculateSize(isExpanded);
    },
    calculateSize(isExpanded) {
      console.log(`windows is ${isExpanded ? 'expanded' : 'collapsed'}`);
    },
  },
};
