<template>
  <div class="ykc-electrovalence-range">
    <div class="range-selector" ref="RangeSelector">
      <el-popover
        ref="Popover"
        width="204"
        popper-class="ykc-electrovalence-range-popper"
        trigger="manual"
        :visible-arrow="false"
        v-model="visible">
        <div class="range">
          <el-input readonly size="small" :value="timeRange[0]"></el-input>
          <span class="separator"></span>
          <el-input readonly size="small" :value="timeRange[1]"></el-input>
        </div>
        <el-select
          ref="Select"
          v-model="groupValue"
          size="small"
          popper-class="ykc-electrovalence-range-select-popper"
          :placeholder="popoverSelectPlaceholder"
          @change="handleSelectChange">
          <el-option
            v-for="{ label, value } in groups"
            :key="value"
            :label="label"
            :value="value"></el-option>
        </el-select>
        <div class="tip">温馨提示：再次点击确认时段</div>
        <div class="footer">
          <el-button size="small" class="cancel" @click="handleCancel">取消</el-button>
          <el-button size="small" class="confirm" type="primary" @click="handleConfirm">
            确认
          </el-button>
        </div>
        <!-- 弹出层四个角的特定样式 -->
        <div class="corner">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <!-- 自定义箭头 -->
        <div class="custom-arrow outer" :style="arrowStyle"></div>
        <div class="custom-arrow inner" :style="arrowStyle"></div>
        <!-- 相对参考点，动态更新此元素位置即可更新弹出层位置 -->
        <span slot="reference" class="center" :style="popoverPosition"></span>
      </el-popover>
      <div class="row row-1">
        <div
          v-for="(direction, index) in lines"
          :key="`line-${direction}`"
          class="row-line"
          :class="[`row-line-${direction}`]">
          <div>
            <span>{{ splitLineTime[0][index] }}</span>
          </div>
        </div>
        <div
          v-for="item in totalItems.slice(0, totalItems.length / 2)"
          :key="`item-${item.index}`"
          class="range-item"
          :style="calculateItemStyle(item)"
          @click="handleItemClick(item)"
          @mousemove="handleItemMousemove(item)"
          @contextmenu.prevent="handleItemContextmenu(item)"></div>
      </div>
      <div class="row row-2">
        <div
          v-for="(direction, index) in lines"
          :key="`line-${direction}`"
          class="row-line"
          :class="[`row-line-${direction}`]">
          <div>
            <span>{{ splitLineTime[1][index] }}</span>
          </div>
        </div>
        <div
          v-for="item in totalItems.slice(totalItems.length / 2)"
          :key="`item-${item.index}`"
          class="range-item"
          :style="calculateItemStyle(item)"
          @click="handleItemClick(item)"
          @mousemove="handleItemMousemove(item)"
          @contextmenu.prevent="handleItemContextmenu(item)"></div>
      </div>
    </div>
    <slot name="table" v-if="$scopedSlots.table" :data="innerValue"></slot>
    <template v-else>
      <div class="table-data" v-if="tableVisible && innerValue.length > 0">
        <el-table :data="innerValue" :show-header="false" :max-height="198">
          <el-table-column width="120">
            <template slot-scope="{ row: { start, end, isRestRange, label } }">
              <span v-if="isRestRange">{{ label }}</span>
              <div v-else>
                <span>{{ start }}</span>
                <span>{{ tableTimeSeparator }}</span>
                <span>{{ end }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="60">
            <template slot-scope="{ row: { type } }">
              <span>{{ typeLabelMap[type] }}</span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="{ row: { type } }">
              <span>电费：{{ electricityFeeMap[type] }}元</span>
            </template>
          </el-table-column>
          <el-table-column prop="serviceFee">
            <template slot-scope="{ row: { type } }">
              <span>服务费：{{ serviceFeeMap[type] }}元</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    inheritAttrs: false,
    name: 'YkcElectrovalenceRange',
    mounted() {
      // 此处没有使用clickoutside指令，是因为其控制不够细（对弹出层无法兼容）
      // 点击时间范围外面则取消选择操作
      const handler = ({ target }) => {
        if (this.visible && [this.$refs.RangeSelector].every(el => !el.contains(target))) {
          this.handleCancel();
        }
      };
      window.document.addEventListener('click', handler);
      this.$on('hook:destroyed', () => {
        window.document.removeEventListener('click', handler);
      });
    },
    props: {
      value: {
        type: Array,
        required: true,
      },
      poperShowAt: {
        type: String,
        default: 'start',
        validator: val => ['start', 'end'].includes(val),
      },
      /** 电费与尖峰平谷的映射 */
      electricityFeeMap: {
        type: Object,
        required: true,
      },
      /** 服务费与尖峰平谷的映射 */
      serviceFeeMap: {
        type: Object,
        required: true,
      },
      /** 弹出层下拉框placeholder */
      popoverSelectPlaceholder: {
        type: String,
        default: '请选择单价',
      },
      /** 是否显示底部表格 */
      tableVisible: {
        type: Boolean,
        default: true,
      },
      /** 表格时间分隔符 */
      tableTimeSeparator: {
        type: String,
        default: '~',
      },
      /** 无状态时的背景色 */
      emptyBackground: {
        type: String,
        default: 'linear-gradient(180deg, #A5B2C4 0%, #6C7A92 100%)',
      },
      /** 四种状态的背景色以及弹出层下拉数据项 */
      groups: {
        type: Array,
        default: () =>
          Object.freeze([
            {
              label: '尖单价',
              value: 'jian',
              background: 'linear-gradient(180deg, #9EE9CB 0%, #65CF9B 100%)',
            },
            {
              label: '峰单价',
              value: 'feng',
              background: 'linear-gradient(180deg, #FFBE96 0%, #FF895D 100%)',
            },
            {
              label: '平单价',
              value: 'ping',
              background: 'linear-gradient(180deg, #9AE6FF 0%, #61C9FF 100%)',
            },
            {
              label: '谷单价',
              value: 'gu',
              background: 'linear-gradient(180deg, #FFECA9 0%, #FFD470 100%)',
            },
          ]),
      },
      /** 小时是否补齐成2位 */
      hourPad: {
        type: Boolean,
        default: true,
      },
      /** 分割线时间字符串 */
      splitLineTime: {
        type: Array,
        default: () => [
          ['0:00', '6:00', '12:00'],
          ['12:00', '18:00', '24:00'],
        ],
      },
      /** 无状态节点作为剩余时段的默认状态 */
      defaultEmptyStatus: {
        type: String,
        default: 'jian',
      },
      /** 状态字符串map */
      typeLabelMap: {
        type: Object,
        default: () =>
          Object.freeze({
            jian: '尖',
            feng: '峰',
            ping: '平',
            gu: '谷',
          }),
      },
      /** 是否可以取消 */
      clearable: {
        type: Boolean,
        default: false,
      },
      /** 选中的状态 */
      selectedStatus: {
        validator: val => ['', 'jian', 'feng', 'ping', 'gu', null].includes(val),
      },
    },
    watch: {
      selectedStatus(val) {
        this.groupValue = this.groups.some(g => g.value === val) ? val : '';
        this.setSelectedRangeColor();
      },
      value: {
        handler(val) {
          this.setTotalRangeColor(val);
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {
      innerValue() {
        const result = [...this.value];
        const restRange = this.getRestRange();
        if (restRange) {
          result.push(restRange);
        }
        return result;
      },
      arrowStyle() {
        if (this.arrowPosition === 'top') {
          return {
            top: '0',
            transform: 'translate(-50%, -100%)',
          };
        }
        return {
          bottom: 0,
          transform: 'translate(-50%, 100%) rotateZ(180deg)',
        };
      },
    },
    data() {
      return {
        arrowPosition: 'top',
        // 每行的线条数量
        lines: ['left', 'center', 'right'],
        // 总数据
        totalItems: Array.from({ length: 48 }).map((_, index) => ({
          // 保存下标
          index,
          // 状态
          status: '',
          // 时间区间的开始时刻
          start: [Math.floor(index / 2), (index % 2) * 30],
          // 时间区间的结束时刻
          end: [Math.floor((index + 1) / 2), ((index + 1) % 2) * 30],
        })),
        // 弹出层是否可见
        visible: false,
        // 弹出层的位置，根据鼠标滑动到哪个节点计算得出
        popoverPosition: {
          marginTop: '0',
          marginLeft: '0',
        },
        // 弹出层区间值
        timeRange: [null, null],
        // 操作时间区间时，保存的起始节点
        range: {
          start: null,
          end: null,
          lockMousemove: false,
        },
        // 操作时间区间时，先保存原始数据，方便取消等情况重置数据
        snapshot: null,
        // 弹出层下拉框的值，如果selectedStatus有值，应该与selectedStatus相同
        groupValue: null,
      };
    },
    methods: {
      /** 获取剩余时段 */
      getRestRange() {
        const emptyItems = this.totalItems.filter(item => !item.status);
        if (this.value.length > 0 && emptyItems.length > 0) {
          return {
            type: this.defaultEmptyStatus,
            // 剩余时间段
            isRestRange: true,
            label: '剩余时段',
          };
        }
        return null;
      },
      /** 全部节点着色 */
      setTotalRangeColor(value) {
        // value的格式应该为
        // {
        //     type: 'jian',
        //     start: '01:30',
        //     end: '03:00',
        // }
        // 且value每个值不相交
        /**
         * 比较字符串时间与数组时间是否相等
         * 比如："01:30" === [1, 30] 值为 true
         */
        const isEqual = (str, arr) => arr.every((val, i) => val === str.split(':').map(Number)[i]);
        const { totalItems } = this;
        value.forEach(range => {
          const { start, end, type } = range;
          const startIndex = totalItems.findIndex(item => isEqual(start, item.start));
          const endIndex = totalItems.findIndex(item => isEqual(end, item.end));
          if (startIndex > -1 && endIndex > -1) {
            const minIndex = Math.min(startIndex, endIndex);
            const maxIndex = Math.max(startIndex, endIndex);
            totalItems.slice(minIndex, maxIndex + 1).forEach(item => {
              item.status = type;
            });
          }
        });
      },
      /** 操作时间区间前，保存全部节点快照，方便取消时恢复数据 */
      saveSnapshot() {
        this.snapshot = this.totalItems.map(item => ({ ...item }));
      },
      /** 清除快照 */
      clearSnapshot() {
        this.snapshot = null;
      },
      /** 计算节点样式 */
      calculateItemStyle(item) {
        const { emptyBackground, groups } = this;
        const { status, index } = item;
        const style = {};
        if (status) {
          const group = groups.find(g => g.value === status);
          style.background = group ? group.background : emptyBackground;
        }
        if ([11, 35].includes(index)) {
          style.marginRight = '5px';
        }
        return style;
      },
      /** 计算弹出层位置 */
      calculatePosition(item) {
        const { index } = item;
        // 每个节点的宽度
        const width = 14.67;
        // 相邻节点的间距
        const gutter = 4;
        const itemWidth = width + gutter;
        // 第一行
        if (index < 24) {
          this.arrowPosition = 'bottom';
          // const marginTop = '-16px';
          const marginTop = '-240px';
          // 第一行中间点(index=11和12之间)的左侧和右侧
          const marginLeft = `${
            index < 12
              ? -(itemWidth / 2 + (11 - index) * itemWidth)
              : itemWidth / 2 + (index - 12) * itemWidth
          }px`;
          this.popoverPosition = { marginTop, marginLeft };
        } else {
          this.arrowPosition = 'top';
          // 第二行
          const marginTop = '48px';
          // 第二行中间点(index=35和36之间)的左侧和右侧
          const marginLeft = `${
            index < 36
              ? -(itemWidth / 2 + (35 - index) * itemWidth)
              : itemWidth / 2 + (index - 36) * itemWidth
          }px`;
          this.popoverPosition = { marginTop, marginLeft };
        }
        if (this.$refs.Popover && this.$refs.Popover.popperJS) {
          this.$nextTick(() => {
            // 更新弹出层位置
            this.$refs.Popover.popperJS.update();
          });
        }
      },
      /** 计算弹出层时间区间展示的值 */
      calculateRangeValue() {
        const { range, totalItems, hourPad } = this;
        const { start } = range;
        const end = range.end || start;
        if (start && end) {
          // 因为开始和结束节点时间没有排序，故此处需要先排序
          const startItemIndex = start.index;
          const endItemIndex = end.index;
          const maxIndex = Math.max(endItemIndex, startItemIndex);
          const minIndex = Math.min(endItemIndex, startItemIndex);
          this.timeRange = [
            totalItems[minIndex].start.reduce((res, val, idx) => {
              if (idx === 0) {
                res += `${hourPad && String(val).length === 1 ? `0${val}` : val}:`;
              } else {
                res += String(val).length > 1 ? val : `0${val}`;
              }
              return res;
            }, ''),
            totalItems[maxIndex].end.reduce((res, val, idx) => {
              if (idx === 0) {
                res += `${hourPad && String(val).length === 1 ? `0${val}` : val}:`;
              } else {
                res += String(val).length > 1 ? val : `0${val}`;
              }
              return res;
            }, ''),
          ];
        }
      },
      /** 更新节点状态 */
      updateItemStatus(item) {
        this.$nextTick(() => {
          item.status = this.groupValue || 'empty';
        });
      },
      /**
       * 节点点击处理
       * 没有选中开始节点，则点击的节点就是开始节点，首先进行快照操作，然后保存开始节点和结束节点信息（一个节点时，开始节点就是结束节点），并更新开始节点样式
       * 如果存在开始节点，那么再次点击时表示确认对该区间进行电价配置
       */
      handleItemClick(item) {
        const { range, saveSnapshot, updateItemStatus } = this;
        if (range.lockMousemove) {
          this.$emit('item-click-after-lock', item);
          return false;
        }
        // 未选择开始点
        if (!range.start) {
          // 保存前一次的快照，取消时恢复
          saveSnapshot();
          // 记录开始节点
          range.start = item;
          // 记录结束节点
          range.end = item;
          // 更新开始节点颜色
          updateItemStatus(item);
          if (this.poperShowAt === 'start') {
            // 打开弹出层
            this.visible = true;
          }
          // 默认选中状态
          this.groupValue = this.groups.some(g => g.value === this.selectedStatus)
            ? this.selectedStatus
            : '';
          // 更新弹出层位置
          this.calculatePosition(item);
          // 计算弹出层输入框时间区间的显示值
          this.calculateRangeValue();
        } else {
          if (this.poperShowAt === 'end') {
            // 打开弹出层
            this.visible = true;
          }
          range.end = item;
          // 高亮选中的节点
          this.setSelectedRangeColor();
          // 更新弹出层位置
          this.calculatePosition(item);
          // 计算弹出层输入框时间区间的显示值
          this.calculateRangeValue();
          // 锁定mousemove后，鼠标移动无效
          range.lockMousemove = true;
        }
        return true;
      },
      /** 鼠标移动到节点上更新选中区间 */
      handleItemMousemove(item) {
        const { range } = this;
        // 没有开始节点，即没有进行操作，鼠标移动无效
        // 有开始节点，但是是锁定状态，即通过点击确定了结束节点，鼠标再移动也无效
        if (!range.start || range.lockMousemove) {
          return false;
        }
        // 保存结束节点
        range.end = item;
        // 计算弹出层输入框时间区间的显示值
        this.calculateRangeValue();
        // 选择了开始节点，鼠标移动时弹出层位置更新
        this.calculatePosition(item);
        // 高亮选中的节点
        this.setSelectedRangeColor();
        return true;
      },
      /** 鼠标右键取消单个节点着色 */
      handleItemContextmenu(item) {
        const { range, clearable } = this;
        const { start, end, lockMousemove } = range;
        if (!clearable || start || end || lockMousemove) {
          return false;
        }
        if (item.status) {
          item.status = '';
          this.calculateValue();
        }
        return true;
      },
      /** 弹出层下拉选择单价改变 */
      handleSelectChange(val) {
        // 支持.sync修饰符
        this.$emit('update:selectedStatus', val);
        this.setSelectedRangeColor();
      },
      /** 更新选中区间内的节点状态 */
      setSelectedRangeColor() {
        const { range, totalItems, snapshot, updateItemStatus } = this;
        const { start, end } = range;
        if (start && end) {
          const startItemIndex = start.index;
          const endItemIndex = end.index;
          const maxIndex = Math.max(endItemIndex, startItemIndex);
          const minIndex = Math.min(endItemIndex, startItemIndex);
          // 首尾区间中的全部节点着色
          totalItems.slice(minIndex, maxIndex + 1).forEach(item => {
            updateItemStatus(item);
          });
          // 因为可以移动到节点上，也可以从节点移开，那么移开时需要重置成原来的状态，此处是快照的作用之一
          totalItems
            .filter(({ index }) => index < minIndex || index > maxIndex)
            .forEach(item => {
              item.status = snapshot[item.index].status;
            });
        }
      },
      /** 取消操作，重置数据 */
      handleCancel() {
        this.visible = false;
        this.totalItems = this.snapshot;
        this.timeRange = [null, null];
        this.range.start = null;
        this.range.end = null;
        this.range.lockMousemove = false;
        this.clearSnapshot();
      },
      /** 确认操作，保存本地数据并同步value值 */
      handleConfirm() {
        if (!this.groupValue) {
          // 派发事件，方便外部处理
          this.$emit('empty-group-value');
          // this.$message.warning('请选择单价');
          // this.$refs.Select.focus();
          // setTimeout(() => {
          //     this.$refs.Select.blur();
          // }, 500);
          return;
        }
        const { start, end } = this.range;
        const startItemIndex = start.index;
        const endItemIndex = end.index;
        const maxIndex = Math.max(endItemIndex, startItemIndex);
        const minIndex = Math.min(endItemIndex, startItemIndex);
        this.snapshot.slice(minIndex, maxIndex + 1).forEach(item => {
          item.status = this.groupValue;
        });
        this.totalItems = [...this.snapshot];
        this.handleCancel();
        this.calculateValue();
      },
      /** 计算value的值并更新 */
      calculateValue() {
        const ranges = [];
        const { totalItems } = this;
        const { length } = totalItems;
        for (let i = 0; i < length; ) {
          const currentItem = totalItems[i];
          const currentItemStatus = currentItem.status;
          // 无状态的节点，则跳过
          if (!currentItemStatus) {
            i += 1;
            // eslint-disable-next-line no-continue
            continue;
          }
          // 有状态的节点，则从当前往后找相同状态且相邻的节点
          let j = i;
          for (; j < length; j++) {
            const innerItem = totalItems[j];
            const innerItemStatus = innerItem.status;
            // 相同且相邻则继续查找
            if (innerItemStatus === currentItemStatus) {
              // eslint-disable-next-line no-continue
              continue;
            } else {
              // 状态不同，则停止查找
              break;
            }
          }
          // j-1是最后一个状态相同的节点，保存之
          ranges.push([i, j - 1]);
          // 跳过i到j-1之间的节点
          i = j;
        }
        const { hourPad } = this;
        const value = ranges.map(range => {
          const [startIndex, endIndex] = range;
          const items = totalItems.slice(startIndex, endIndex + 1);
          const startItem = items[0];
          const endItem = items[endIndex - startIndex];
          const startTime = startItem.start.reduce((res, val, index) => {
            if (index === 0) {
              res += `${hourPad && String(val).length === 1 ? `0${val}` : val}:`;
            } else {
              res += String(val).length > 1 ? val : `0${val}`;
            }
            return res;
          }, '');
          const endTime = endItem.end.reduce((res, val, index) => {
            if (index === 0) {
              res += `${hourPad && String(val).length === 1 ? `0${val}` : val}:`;
            } else {
              res += String(val).length > 1 ? val : `0${val}`;
            }
            return res;
          }, '');
          return {
            type: startItem.status,
            start: startTime,
            end: endTime,
          };
        });
        // // 如果只选择了一种类型，且与剩余时间段类型相同，那么整天的时间都应该是剩余时间段类型
        // if (value.length === 1 && value[0].type === this.defaultEmptyStatus) {
        //     value[0].start = '00:00';
        //     value[0].end = '24:00';
        // }
        this.$emit('input', value);
      },
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
