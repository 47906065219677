import { sessionSetItem } from '@/utils';

const mutations = {
  /**
   * 设置数据字典
   */
  setDic(state, value) {
    // sessionStorage.dictionary = JSON.stringify(value);
    state.dictionary = value;
  },
  setDicByKey(state, dicInfo) {
    state.dictionary[dicInfo.key] = dicInfo.value;
    // sessionStorage.dictionary = JSON.stringify(state.dictionary);
  },
  setDownloadDialog(state, value) {
    state.downloadDialog = value;
  },
  setLogo(state, value) {
    state.logo = value;
  },
  setLoginLogo(state, value) {
    state.loginLogo = value;
  },
  /**
   * @param {*} state
   * @param {Array} value
   * */
  setDenyListCurrentStationIds(state, value) {
    state.denyListCurrentStationIds = value;
    sessionSetItem('denyListCurrentStationIds', JSON.stringify(value));
  },
};
export default mutations;
