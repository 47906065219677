<!--
 *@author huhongyun
 *@date 2021/10/15
 *@component 弹窗温馨提示组件
-->
<template>
  <div class="ykc-warm-tip" :class="[typeClass, isMultiLine]">
    <i class="ykc-warm-tip-icon" :class="[iconClass, isBigIcon]" v-if="showIcon"></i>
    <div class="ykc-warm-tip-content">
      <span class="ykc-warm-tip-description" v-if="$slots.default && !description">
        <slot></slot>
      </span>
      <div class="ykc-warm-tip-description" v-if="description && !$slots.default">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
  const TYPE_CLASSES_MAP = {
    success: 'el-icon-success',
    warning: 'el-icon-warning',
    error: 'el-icon-error',
    // info 的图标使用 warning 的代替,因为轮廓相同但是颜色不同
    info: 'el-icon-warning',
  };
  export default {
    name: 'YkcWarmTip',
    props: {
      description: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'info',
        validator: val => ['info', 'warning', 'success', 'error'].includes(val),
      },
      showIcon: {
        type: Boolean,
        default: true,
      },
      multiLine: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      typeClass() {
        return `ykc-warm-tip-${this.type}`;
      },
      iconClass() {
        // 这里的图标暂时使用 el-icon, 因为和效果图的差不多
        return TYPE_CLASSES_MAP[this.type];
      },
      isBigIcon() {
        return this.description || this.$slots.default ? 'is-big' : '';
      },
      isMultiLine() {
        return this.multiLine ? 'multiline' : '';
      },
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
