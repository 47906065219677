/*
 * @Author: Daokai Huo
 * @Date: 2022-01-24 14:01:49
 * @LastEditTime: 2022-01-24 14:07:37
 * @Description:
 */
import Component from './src/main.vue';

Component.install = function install(_Vue) {
  _Vue.component(Component.name, Component);
};

export default Component;
