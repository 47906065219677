<!--
 * @Author: Daokai Huo
 * @Date: 2022-01-24 14:02:20
 * @LastEditTime: 2022-01-24 19:37:52
 * @Description: 
-->
<template>
  <div class="ykc-wrap">
    <div class="wrap-title">
      <i class="t-icon"></i>
      短信记录
    </div>
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: 'YkcWrap',
  };
</script>
<style lang="scss">
  @import './index.scss';
</style>
