<template>
  <div id="app">
    <ykc-theme :target="target">
      <router-view v-if="isRouterAlive"></router-view>
    </ykc-theme>
  </div>
</template>

<script>
  export default {
    name: 'App',
    provide() {
      return {
        reload: this.reload,
      };
    },
    data() {
      return {
        isRouterAlive: true,
        target: window.document.body,
      };
    },
    methods: {
      reload() {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
        });
      },
    },
  };
</script>

<style lang="scss">
  @import '@/assets/css/base.scss';
  html,
  body {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    background-color: $_F6F7F8;
  }

  #app {
    height: 100%;
    width: 100vw;
    box-sizing: border-box;
  }

  .motorcade-main-account {
    height: 100%;
  }

  .table-header-button {
    .el-button {
      margin-left: 8px !important;
    }
  }

  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 8px !important;
    }
  }
</style>
