<!--
 * @Author: Yanxia Wan
 * @Date: 2021-11-2
 * @Description: tabs
-->
<template>
  <div class="ykc-tabs">
    <el-tabs class="tabs" v-model="activeValue" :type="type" @tab-click="tabClick">
      <el-tab-pane v-for="item in tabList" :key="item.name" :label="item.label" :name="item.name">
        <!-- 这里使用具名插槽处理 -->
        <slot :name="item.name"></slot>
      </el-tab-pane>
    </el-tabs>
    <div
      v-if="headerButtonStatus && headerButtonText !== ''"
      :class="rightHeaderNoMarginTop ? 'header-btn-right-no-top' : 'header-btn-right'">
      <ykc-button type="plain" @click="btnClick">
        {{ headerButtonText }}
      </ykc-button>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'YkcTabs',
    props: {
      active: {
        type: String,
        default: 'first',
      },
      type: {
        type: String,
        default: 'border-card',
      },
      headerButtonText: {
        type: String,
        default: '',
      },
      tabList: {
        type: Array,
        default: () => [],
      },
      headerButtons: {
        type: Array,
        default: () => [],
      },
      headerButtonStatus: {
        type: Boolean,
        default: true,
      },
      rightHeaderNoMarginTop: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        activeValue: this.active,
      };
    },
    methods: {
      tabClick() {
        this.$emit('tabClick', this.activeValue);
      },
      btnClick() {
        this.$emit('btnClick', this.activeValue);
      },
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
